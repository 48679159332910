import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import getSectionEditableValues from "@/helpers/getSectionEditableValues";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { isRequired } from "@/helpers/validations";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VForm, VTextField } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import TenantSelect from "../TenantSelect";

const Fields = {
  name: "name",
  idTenant: "idTenant",
};

const GrammarOverviewEditForm = Vue.extend({
  data: () => ({
    fields: {},
    isFormValid: true,
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_GRAMMAR]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.GRAMMAR_OVERVIEW_FETCH]),

    isEditable(name: string): boolean {
      return this.INSPECTED_GRAMMAR.basic.editableFields.includes(name);
    },

    async handleSubmit(): Promise<void> {
      const refs: any = this.$refs;
      const submitedFields = getSectionEditableValues(
        this.fields,
        this.INSPECTED_GRAMMAR.basic
      );

      const { isFormSubmited } = await submitForm({
        actionId: Actions.GRAMMAR_OVERVIEW_EDIT,
        ref: refs.form,
        values: submitedFields,
        url: ApiEndpoints.GRAMMAR_BASIC,
        params: { idGrammar: this.$route.params.idGrammar },
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.GRAMMAR_EDIT_BASIC, {});
        pushNotify(Notify.SUCCESS, "Grammar edited successfully");

        this.GRAMMAR_OVERVIEW_FETCH({
          idGrammar: this.$route.params.idGrammar,
        });
      }
    },
  },

  created() {
    this.fields = { ...this.INSPECTED_GRAMMAR.basic.content };
  },

  render(): VNode {
    return (
      <VForm vModel={this.isFormValid} ref="form">
        <VTextField
          vModel={this.fields[Fields.name]}
          rules={[isRequired]}
          label="Name"
          disabled={!this.isEditable(Fields.name)}
        />
        <TenantSelect vModel={this.fields[Fields.idTenant]} />
      </VForm>
    );
  },
});

export default GrammarOverviewEditForm;
