import Vue, { VNode } from "vue";
import DetailWithTabs from "@/components/DetailWithTabs";
import Routes from "@/constants/routes";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import LanguageRemoveDialog from "../LanguageRemoveDialog";
import useLanguageActions from "@/hooks/useLanguageActions";

const LanguageDetail = Vue.extend({
  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_LANGUAGE]),

    instanceData(): any {
      const content = this.INSPECTED_LANGUAGE?.basic?.content;
      const idLanguage = this.$route.params.idLanguage;

      return { idLanguage, ...content };
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.LANGUAGE_OVERVIEW_FETCH]),
  },

  render(): VNode {
    return (
      <div>
        <DetailWithTabs
          title={`Language - ${this.instanceData?.name || ""}`}
          defaultReturnRoute={Routes.LANGUAGES_OVERVIEW}
          initDataFetch={() =>
            this.LANGUAGE_OVERVIEW_FETCH({
              idLanguage: this.instanceData.idLanguage,
            })
          }
          actions={useLanguageActions(this.instanceData)}
          tabs={[
            {
              title: "General",
              path: Routes.LANGUAGE_DETAIL_BASIC,
            },
          ]}
        />
        <LanguageRemoveDialog />
      </div>
    );
  },
});

export default LanguageDetail;
