import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import lineIdentificatorTypes from "@/constants/lineIdetificatorTypes";
import getSectionEditableValues from "@/helpers/getSectionEditableValues";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { isRequired } from "@/helpers/validations";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VForm, VSelect, VTextField } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import TenantSelect from "../TenantSelect";
import FlowSelect from "../FlowSelect";
import ModulesSelect from "../ModulesSelect";

const Fields = {
  name: "name",
  identificator: "identificator",
  identificatorType: "identificatorType",
  idTenant: "idTenant",
  callScriptCode: "callScriptCode",
  idDialModule: "idDialModule",
};

const LineBasicEditForm = Vue.extend({
  data: () => ({
    fields: {},
    isFormValid: true,
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_LINE]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.LINE_OVERVIEW_FETCH]),

    isEditable(name: string): boolean {
      return this.INSPECTED_LINE.basic.editableFields.includes(name);
    },

    async handleSubmit(): Promise<void> {
      const refs: any = this.$refs;
      const submitedFields = getSectionEditableValues(
        this.fields,
        this.INSPECTED_LINE.basic
      );

      const { isFormSubmited } = await submitForm({
        actionId: Actions.LINE_OVERVIEW_EDIT,
        ref: refs.form,
        values: submitedFields,
        url: ApiEndpoints.LINE_BASIC,
        params: { idDialModuleLine: this.$route.params.idDialModuleLine },
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.LINE_EDIT_BASIC, {});
        pushNotify(Notify.SUCCESS, "Line edited successfully");

        this.LINE_OVERVIEW_FETCH({
          idDialModuleLine: this.$route.params.idDialModuleLine,
        });
      }
    },
  },

  created() {
    this.fields = { ...this.INSPECTED_LINE.basic.content };
  },

  render(): VNode {
    return (
      <VForm ref="form" vModel={this.isFormValid}>
        <VTextField
          vModel={this.fields[Fields.name]}
          label="Name"
          rules={[isRequired]}
          disabled={!this.isEditable(Fields.name)}
        />
        <ModulesSelect vModel={this.fields[Fields.idDialModule]} />
        <VTextField
          vModel={this.fields[Fields.identificator]}
          label="Identificator"
          disabled={!this.isEditable(Fields.identificator)}
        />
        <VSelect
          vModel={this.fields[Fields.identificatorType]}
          items={lineIdentificatorTypes}
          label="Identificator type"
          rules={[isRequired]}
          disabled={!this.isEditable(Fields.identificatorType)}
        />
        <TenantSelect vModel={this.fields[Fields.idTenant]} />
        <FlowSelect vModel={this.fields[Fields.callScriptCode]} />
      </VForm>
    );
  },
});

export default LineBasicEditForm;
