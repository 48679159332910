import { Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import DetailWithTabs from "@/components/DetailWithTabs";
import Routes from "@/constants/routes";
import { VCol, VRow } from "vuetify/lib";
import { mapGetters } from "vuex";

const BotDeploy = Vue.extend({
  computed: {
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },
  render(): VNode {
    return (
      <VRow>
        <VCol cols={12} md={10} offsetMd={1}>
          <DetailWithTabs
            title={"Bot Deploy"}
            defaultReturnRoute={Routes.ADMINISTRATION}
            tabs={[
              {
                title: "Modules",
                path: Routes.BOTDEPLOY_MODULES,
              },
              {
                title: "Servers",
                path: Routes.BOTDEPLOY_SERVERS,
              },
              {
                title: "Configurations",
                path: Routes.BOTDEPLOY_CONFIGS,
              },
            ]}
          />
        </VCol>
      </VRow>
    );
  },
});

export default BotDeploy;
