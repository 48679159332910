import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";

const LineDeleteCallsDialog = Vue.extend({
  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    instanceData(): any {
      return this.GET_DIALOG_DATA(Dialogs.LINE_DELETE_CALL);
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.LINE_REMOVE_CALL]),
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.LINE_DELETE_CALL}
        title="Confirmation"
        closeOnEsc
        actions={[
          {
            action: () =>
              this.LINE_REMOVE_CALL({
                id: this.instanceData?.params?.id,
                idDialModuleLine: this.instanceData?.params?.idDialModuleLine,
                isDetailActive: false,
              }),
            id: Actions.LINE_REMOVE_CALL,
            title: "Remove",
            icon: "mdi-delete",
            isIconButton: false,
          },
        ]}
      >
        {this.instanceData?.params?.id === undefined
          ? "Are you sure you want to remove all planned calls?"
          : `Are you sure you want to remove this call?`}
      </Dialog>
    );
  },
});

export default LineDeleteCallsDialog;
