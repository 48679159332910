import Vue, { VNode } from "vue";
import DetailWithTabs from "@/components/DetailWithTabs";
import Routes from "@/constants/routes";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import usePermissionGroupActions from "@/hooks/usePermissionGroupActions";
import PermissionGroupRemoveDialog from "../PermissionGroupRemoveDialog";

const PermissionGroupDetail = Vue.extend({
  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_PERMISSION_GROUP]),

    instanceData(): any {
      const content = this.INSPECTED_PERMISSION_GROUP?.basic?.content;
      const idRole = this.$route.params.idRole;

      return { idRole, ...content };
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.PERMISSION_GROUP_OVERVIEW_FETCH]),
  },

  render(): VNode {
    return (
      <div>
        <DetailWithTabs
          title={`Permission group - ${this.instanceData.name || ""}`}
          defaultReturnRoute={Routes.PERMISSION_GROUPS_OVERVIEW}
          initDataFetch={() =>
            this.PERMISSION_GROUP_OVERVIEW_FETCH({
              idRole: this.instanceData.idRole,
            })
          }
          actions={usePermissionGroupActions(this.instanceData)}
          tabs={[
            {
              title: "General",
              path: Routes.PERMISSION_GROUP_DETAIL_BASIC,
            },
          ]}
        />
        <PermissionGroupRemoveDialog />
      </div>
    );
  },
});

export default PermissionGroupDetail;
