import Dialogs from "@/constants/dialogs";
import Vue, { VNode } from "vue";
import Dialog from "../Dialog";
import { mapGetters } from "vuex";
import { Modules, Getters } from "@/models/store";
import { VList, VChip, VIcon } from "vuetify/lib";

const CampaignErrorContactsDialog = Vue.extend({
  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    instanceData(): any {
      return this.GET_DIALOG_DATA(Dialogs.CAMPAIGN_ERROR_CONTACTS);
    },
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.CAMPAIGN_ERROR_CONTACTS}
        title={`Invalid contacts (${
          this.instanceData?.errors && this.instanceData?.errors.length
        }):`}
        closeOnEsc
        noCancelBtn
      >
        <VList>
          {this.instanceData?.errors?.map((message: string) => (
            <VChip
              class="ml-2 mr-6 mb-4 elevation-5 text-body-1"
              outlined
              color="error"
              textColor="black"
            >
              <VIcon color="error">{"mdi-close"}</VIcon>
              {` ${message}`}
            </VChip>
          ))}
        </VList>
      </Dialog>
    );
  },
});

export default CampaignErrorContactsDialog;
