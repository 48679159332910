import { Module } from "vuex";

import DialogsState from "@/models/store/DialogsState";
import state from "./state";
import getters from "./getters";
import mutations from "./mutations";

const dialogs: Module<DialogsState, []> = {
  namespaced: true,
  state,
  getters,
  mutations,
};

export default dialogs;
