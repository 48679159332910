import { Modules, Actions, Getters } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import StatisticsDashboard from "../StatisticsDashboard";

const LineStatistics = Vue.extend({
  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_LINE]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.LINE_STATISTICS_FETCH]),
  },

  render(): VNode {
    return (
      <StatisticsDashboard
        data={this.INSPECTED_LINE?.statistics ?? {}}
        fetchData={this.LINE_STATISTICS_FETCH}
        fetchParams={{ idDialModuleLine: this.$route.params.idDialModuleLine }}
        fetchAction={Actions.LINE_STATISTICS_FETCH}
      />
    );
  },
});

export default LineStatistics;
