const countryCodes = {
  cs: "cz",
  en: "gb",
  sr: "rs",
  he: "il",
  sv: "se",
  da: "dk",
  fa: "ir",
  uk: "ua",
  ca: "es-ca",
};

const getCountryCode = (code: string): string => countryCodes[code] || code;

export default getCountryCode;
