import { GetterTree } from "vuex";
import { Getters } from "@/models/store";
import DataGridsState from "@/models/store/DataGridsState";

const getters: GetterTree<DataGridsState, []> = {
  [Getters.GET_DATAGRID_DATA]: (state) => (dataGrid: string) => state[dataGrid],

  [Getters.GET_DATAGRID_FILTER]: (state) => (dataGrid: string) =>
    state[dataGrid].filter,

  [Getters.GET_FAVORITE_FLOWS]: (state) => state.favoriteFlows,
};

export default getters;
