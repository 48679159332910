import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import getSectionEditableValues from "@/helpers/getSectionEditableValues";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { isRequired } from "@/helpers/validations";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VForm, VSwitch, VTextField } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import TenantSelect from "../TenantSelect";

const Fields = {
  name: "name",
  idTenant: "idTenant",
  isPublic: "isPublic",
};

const FlowOverviewEditForm = Vue.extend({
  data: () => ({
    fields: {},
    isValid: true,
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_FLOW]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.FLOW_OVERVIEW_FETCH]),

    isEditable(name: string): boolean {
      return this.INSPECTED_FLOW.basic.editableFields.includes(name);
    },

    async handleSubmit(): Promise<void> {
      const refs: any = this.$refs;
      const submitedFields = getSectionEditableValues(
        this.fields,
        this.INSPECTED_FLOW.basic
      );

      const { isFormSubmited } = await submitForm({
        actionId: Actions.FLOW_OVERVIEW_EDIT,
        ref: refs.form,
        values: submitedFields,
        url: ApiEndpoints.FLOW_EDIT_BASIC,
        params: { idCallScript: this.$route.params.idCallScript },
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.FLOW_EDIT_BASIC, {});
        pushNotify(Notify.SUCCESS, "Flow edited successfully");

        this.FLOW_OVERVIEW_FETCH({
          idCallScript: this.$route.params.idCallScript,
        });
      }
    },
  },

  created() {
    this.fields = { ...this.INSPECTED_FLOW.basic.content };
  },

  render(): VNode {
    const fields: any = this.fields;

    return (
      <VForm vModel={this.isValid} ref="form">
        <VTextField
          vModel={fields[Fields.name]}
          label="Name"
          disabled={!this.isEditable(Fields.name)}
          rules={[isRequired]}
        />
        <TenantSelect
          vModel={fields[Fields.idTenant]}
          disabled={!this.isEditable(Fields.idTenant)}
        />
        <VSwitch
          vModel={fields[Fields.isPublic]}
          label="Public access"
          disabled={!this.isEditable(Fields.isPublic)}
        />
      </VForm>
    );
  },
});

export default FlowOverviewEditForm;
