import { ActionTree } from "vuex";
import DataGridsState from "@/models/store/DataGridsState";
import handleActions from "./handleActions";
import fetchDataActions from "./fetchDataActions";

const actions: ActionTree<DataGridsState, []> = {
  ...handleActions,
  ...fetchDataActions,
};

export default actions;
