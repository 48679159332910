import Vue, { VNode } from "vue";
import { VCard, VCardText, VIcon } from "vuetify/lib";

const CampaignDataCard = Vue.extend({
  props: {
    value: [Number, String],
    valueColor: {
      type: String,
      default: "primary",
    },
    title: String,
    icon: String,
    iconColor: {
      type: String,
      default: "primary",
    },
    noRightMargin: {
      default: false,
      type: Boolean,
    },
  },

  render(): VNode {
    return (
      <VCard
        class={`${this.noRightMargin ? "" : "mr-5"} align-self-start`}
        style="flex: 1"
      >
        <VCardText class="d-flex align-center justify-space-between">
          <div>
            <div class={`text-h5 ${this.valueColor}--text font-weight-bold`}>
              {this.value}
            </div>
            <div>{this.title}</div>
          </div>
          <VIcon class="mr-2" color={this.iconColor} xLarge>
            {this.icon}
          </VIcon>
        </VCardText>
      </VCard>
    );
  },
});

export default CampaignDataCard;
