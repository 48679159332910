import Dialogs from "@/constants/dialogs";
import { Actions } from "@/models/store";
import Vue, { VNode } from "vue";
import ChangePasswordForm from "../ChangePasswordForm";
import Dialog from "../Dialog";

const ChangePasswordDialog = Vue.extend({
  render(): VNode {
    const refs: any = this.$refs;

    return (
      <Dialog
        id={Dialogs.CHANGE_PASSWORD}
        title="Change password"
        actions={[
          {
            title: "confirm",
            icon: "mdi-check",
            action: () => refs.form.handleSubmit(),
            id: Actions.USER_PASSWORD_CHANGE,
          },
        ]}
      >
        <ChangePasswordForm ref="form" />
      </Dialog>
    );
  },
});

export default ChangePasswordDialog;
