import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VDivider } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import EditableOverviewSection from "../EditableOverviewSection";
import ModuleBasicEditForm from "../ModuleBasicEditForm";
import ModuleCallbotConfigurationForm from "../ModuleCallbotConfigurationForm";
import { formatDate } from "@/helpers/dateAndTimeUtils";

const ModuleOverview = Vue.extend({
  data: () => ({
    basic: {
      title: "Overview",
      modificators: {
        lastActivity: ({ lastActivity }: any) => formatDate(lastActivity),
        lastSuccessfulCall: ({ lastSuccessfulCall }: any) =>
          formatDate(lastSuccessfulCall),
      },
      columns: [
        { name: "name", title: "Name" },
        { name: "mode", title: "Mode" },
        { name: "tenant", title: "Tenant" },
        { name: "lastActivity", title: "Last activity at" },
        { name: "lastSuccessfulCall", title: "Last successful call" },
      ],
    },

    callbotConfiguration: {
      title: "Advanced configuration",
      columns: [
        {
          name: "BaseSettings.MaxInboundCalls",
          title: "Max Inbound Calls",
        },
        {
          name: "BaseSettings.MaxOutboundCalls",
          title: "Max Outbound Calls",
        },
      ],
    },
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_MODULE]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [
      Actions.MODULE_OVERVIEW_FETCH,
      Actions.MODULE_CALLBOT_CONFIG_FETCH,
    ]),
  },

  render(): VNode {
    const idDialModule = this.$route.params.idDialModule;

    return (
      <div>
        <EditableOverviewSection
          configuration={this.basic}
          data={this.INSPECTED_MODULE?.basic}
          editDialog={Dialogs.MODULE_EDIT_BASIC}
          action={Actions.MODULE_OVERVIEW_EDIT}
          scopedSlots={{ form: () => <ModuleBasicEditForm ref="form" /> }}
          isDataFetching={this.IS_ACTION_FETCHING(
            Actions.MODULE_OVERVIEW_FETCH
          )}
        />

        <VDivider class="mb-5" />

        <EditableOverviewSection
          configuration={this.callbotConfiguration}
          data={this.INSPECTED_MODULE?.callbotSettings}
          fetchData={() => this.MODULE_CALLBOT_CONFIG_FETCH({ idDialModule })}
          editDialog={Dialogs.MODULE_CALLBOT_CONFIGURATION}
          scopedSlots={{
            form: () => <ModuleCallbotConfigurationForm ref="form" />,
          }}
          action={Actions.MODULE_CALLBOT_SETTINGS_EDIT}
          isDataFetching={this.IS_ACTION_FETCHING(
            Actions.MODULE_CALLBOT_CONFIG_FETCH
          )}
        />
      </div>
    );
  },
});

export default ModuleOverview;
