import Vue, { VNode } from "vue";
import DataGrid from "../DataGrid";
import DataGridConfig from "@/models/dataGrid/DataGridConfig";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import DataGrids from "@/constants/dataGrids";
import { formatDate } from "@/helpers/dateAndTimeUtils";
import { AUDIT_TYPE_ICONS } from "@/constants/valueIcons";
import { VIcon } from "vuetify/lib";

const AuditDataGrid = Vue.extend({
  data: () => ({
    dataGridConfig: {},
  }),

  computed: {
    ...mapGetters(Modules.DATA_GRIDS, [Getters.GET_DATAGRID_DATA]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.DATA_GRIDS, [Actions.AUDIT_DATAGRID_FETCH]),
  },

  created() {
    const dataGridConfig: DataGridConfig = {
      title: "Audit log",
      isHeaderOutside: true,
      columnModificators: {
        "item.timestamp": ({ item }) => formatDate(item.timestamp),
        "item.type": ({ item }) => {
          const type = AUDIT_TYPE_ICONS[item.type];

          return (
            <span class="d-flex align-center">
              <VIcon small class="pr-2" color={type?.color}>
                {type?.icon}
              </VIcon>
              {type?.name}
            </span>
          );
        },
      },
      headers: [
        { text: "Date", value: "timestamp" },
        { text: "Type", value: "type" },
        { text: "Message", value: "message" },
        { text: "URL", value: "url" },
      ],
      filters: [{ name: "message" }, { name: "url" }, { name: "type" }],
    };

    this.dataGridConfig = dataGridConfig;
  },

  render(): VNode {
    return (
      <div>
        <DataGrid
          fetchData={this.AUDIT_DATAGRID_FETCH}
          data={this.GET_DATAGRID_DATA(DataGrids.AUDIT)}
          configuration={this.dataGridConfig}
          isFetching={this.IS_ACTION_FETCHING(Actions.AUDIT_DATAGRID_FETCH)}
        />
      </div>
    );
  },
});

export default AuditDataGrid;
