import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import EditableOverviewSection from "../EditableOverviewSection";
import FlowOverviewEditForm from "../FlowOverviewEditForm";
import { formatDate } from "@/helpers/dateAndTimeUtils";
import { FLOW_TYPE_ICONS } from "@/constants/valueIcons";
import Routes from "@/constants/routes";

const FlowOverview = Vue.extend({
  data: () => ({
    basic: {},
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_FLOW]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.FLOW_OVERVIEW_FETCH]),

    getPublishLink(type: number, title: string, id: number): VNode {
      switch (type) {
        case 0:
          return (
            <router-link
              class="tertiary--text"
              to={{
                name: Routes.CAMPAIGN_DETAIL,
                params: { idCampaign: String(id) },
              }}
            >
              {title}
            </router-link>
          );
        default:
          return (
            <router-link
              class="tertiary--text"
              to={{
                name: Routes.LINE_DETAIL_BASIC,
                params: { idDialModuleLine: id },
              }}
            >
              {title}
            </router-link>
          );
      }
    },
  },

  created() {
    this.basic = {
      title: "Overview",
      modificators: {
        created: ({ created }: any) => formatDate(created),
        changed: ({ changed }: any) => formatDate(changed),
        type: ({ type }: any) => FLOW_TYPE_ICONS[type].name,
        published: ({ published }: any) =>
          published?.length === 0
            ? "-"
            : published.map(({ type, title, id }: any) => (
                <div>{this.getPublishLink(type, title, id)}</div>
              )),
      },
      columns: [
        { name: "name", title: "Name" },
        { name: "author", title: "Author" },
        { name: "created", title: "Created at" },
        { name: "changed", title: "Last changed at" },
        { name: "tenant", title: "Tenant" },
        { name: "type", title: "Type" },
        { name: "published", title: "Published at" },
        { name: "guid", title: "Guid" },
      ],
    };
  },

  render(): VNode {
    return (
      <div>
        <EditableOverviewSection
          configuration={this.basic}
          data={this.INSPECTED_FLOW?.basic}
          editDialog={Dialogs.FLOW_EDIT_BASIC}
          action={Actions.FLOW_OVERVIEW_EDIT}
          scopedSlots={{ form: () => <FlowOverviewEditForm ref="form" /> }}
          isDataFetching={this.IS_ACTION_FETCHING(Actions.FLOW_OVERVIEW_FETCH)}
        />
      </div>
    );
  },
});

export default FlowOverview;
