import Vue, { VNode, PropType } from "vue";
import DetailTab from "@/models/DetailTab";
import {
  VBtn,
  VCard,
  VCardText,
  VIcon,
  VSpacer,
  VTab,
  VTabs,
} from "vuetify/lib";
import ActionHandler from "@/models/actions/ActionHandler";
import hasUserPermissions from "@/helpers/hasUserPermissions";

const DetailWithTabs = Vue.extend({
  props: {
    title: String,
    titlePrefix: String,
    actions: Array as PropType<ActionHandler[]>,
    tabs: Array as PropType<DetailTab[]>,
    initDataFetch: Function,
    defaultReturnRoute: String,
  },

  methods: {
    isActionAllowed(isAllowed: boolean): boolean {
      if (isAllowed === undefined) {
        return true;
      }

      return isAllowed || false;
    },
  },

  created() {
    this.initDataFetch ? this.initDataFetch() : null;
  },

  render(): VNode {
    const { tabs, actions, title, defaultReturnRoute } = this;

    return (
      <div>
        {title && (
          <h2 class="text-h4 font-weight-bold primary--text mb-5 d-flex align-center">
            {title}
          </h2>
        )}
        <div class="mb-5 d-md-flex align-center">
          <VBtn
            rounded
            class="mr-3"
            color="secondary primary--text"
            onClick={() => this.$router.push({ name: defaultReturnRoute })}
          >
            <VIcon left>mdi-arrow-left</VIcon>
            Back
          </VBtn>
          <VSpacer />
          {actions?.map(
            ({ title, icon, action, route, href, isAllowed }: any) =>
              this.isActionAllowed(isAllowed) && (
                <VBtn
                  rounded
                  class="ml-3"
                  color="secondary primary--text"
                  onClick={action ? action : () => null}
                  {...(route
                    ? {
                        props: {
                          to: route,
                        },
                      }
                    : {})}
                  {...(href
                    ? {
                        props: {
                          href,
                        },
                      }
                    : {})}
                >
                  <VIcon left>{icon}</VIcon>
                  {title}
                </VBtn>
              )
          )}
        </div>
        <VCard>
          <VCard rounded="b-0">
            <VTabs dark backgroundColor="primary" grow>
              {tabs.map(
                ({ title, path, permissions = [null, null] }) =>
                  hasUserPermissions(permissions[0], permissions[1]) && (
                    <VTab to={{ name: path }}>{title}</VTab>
                  )
              )}
            </VTabs>
          </VCard>
          <VCardText>
            <keep-alive>
              <router-view></router-view>
            </keep-alive>
          </VCardText>
        </VCard>
      </div>
    );
  },
});

export default DetailWithTabs;
