import { Getters, Modules } from "@/models/store";
import store from "@/store";

const generateErrorMsg = (actionId: string): string => {
  const { data, status } =
    store.getters[`${Modules.API_CALLS}/${Getters.GET_ACTION_META}`](actionId);

  return `Error${status ? ` (${status})` : ""} - ${
    data ? data : "see console for more informations"
  }`;
};

export default generateErrorMsg;
