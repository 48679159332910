import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import VueJsonEditor from "vue-json-editor";

const ModuleCallbotConfigurationForm = Vue.extend({
  data: () => ({
    configuration: {},
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_MODULE]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.MODULE_CALLBOT_CONFIG_FETCH]),

    async handleSubmit(): Promise<void> {
      const { isFormSubmited } = await submitForm({
        actionId: Actions.MODULE_CALLBOT_SETTINGS_EDIT,
        values: { content: JSON.stringify(this.configuration) },
        url: ApiEndpoints.MODULE_CALLBOT_SETTINGS,
        params: { idDialModule: this.$route.params.idDialModule },
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.MODULE_CALLBOT_CONFIGURATION, {});
        pushNotify(Notify.SUCCESS, "Module configuration edited successfully");

        this.MODULE_CALLBOT_CONFIG_FETCH({
          idDialModule: this.$route.params.idDialModule,
        });
      }
    },
  },

  created() {
    this.configuration = {
      ...this.INSPECTED_MODULE.callbotSettings.content,
    };
  },

  render(): VNode {
    return <VueJsonEditor vModel={this.configuration} mode="code" />;
  },
});

export default ModuleCallbotConfigurationForm;
