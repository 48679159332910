import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapGetters } from "vuex";
import EditableOverviewSection from "../EditableOverviewSection";
import BotDeployServerEditForm from "../BotDeployServerEditForm";

const BotDeployServerOverview = Vue.extend({
  data: () => ({
    basic: {
      title: "Overview",
      columns: [
        { name: "name", title: "Name" },
        { name: "system", title: "System" },
        { name: "note", title: "Note" },
      ],
    },
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_BOTDEPLOY_SERVER]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  render(): VNode {
    return (
      <div>
        <EditableOverviewSection
          configuration={this.basic}
          data={this.INSPECTED_BOTDEPLOY_SERVER?.basic}
          editDialog={Dialogs.BOTDEPLOY_SERVER_EDIT_BASIC}
          action={Actions.BOTDEPLOY_SERVER_OVERVIEW_EDIT}
          scopedSlots={{
            form: () => <BotDeployServerEditForm ref="form" />,
          }}
          isDataFetching={this.IS_ACTION_FETCHING(
            Actions.BOTDEPLOY_SERVER_OVERVIEW_FETCH
          )}
        />
      </div>
    );
  },
});

export default BotDeployServerOverview;
