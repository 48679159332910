import Vue, { VNode } from "vue";
import DetailWithTabs from "@/components/DetailWithTabs";
import Routes from "@/constants/routes";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import useBotDeployServerActions from "@/hooks/useBotDeployServerActions";
import BotDeployServerRemoveDialog from "../BotDeployServerRemoveDialog";

const BotDeployServerDetail = Vue.extend({
  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_BOTDEPLOY_SERVER]),

    instanceData(): any {
      const content = this.INSPECTED_BOTDEPLOY_SERVER?.basic;
      const name = this.$route.params.name;
      return { name, ...content };
    },
  },
  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.BOTDEPLOY_SERVER_OVERVIEW_FETCH]),
  },

  render(): VNode {
    return (
      <div>
        <DetailWithTabs
          title={`Server - ${this.instanceData.name || ""}`}
          defaultReturnRoute={Routes.BOTDEPLOY_SERVERS}
          initDataFetch={() =>
            this.BOTDEPLOY_SERVER_OVERVIEW_FETCH({
              name: this.$route.params.name,
            })
          }
          actions={useBotDeployServerActions(this.instanceData)}
          tabs={[
            {
              title: "General",
              path: Routes.BOTDEPLOY_SERVER_DETAIL_BASIC,
            },
          ]}
        />

        <BotDeployServerRemoveDialog />
      </div>
    );
  },
});

export default BotDeployServerDetail;
