import { ActionTree } from "vuex";
import { Actions, Mutations } from "@/models/store";
import InstancesState from "@/models/store/InstancesState";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Routes from "@/constants/routes";
import DataGrids from "@/constants/dataGrids";

const grammarActions: ActionTree<InstancesState, []> = {
  [Actions.GRAMMAR_OVERVIEW_FETCH]: ({ commit, dispatch }, { idGrammar }) => {
    dispatch(Actions.SECTION_DETAIL_FETCH, {
      url: ApiEndpoints.GRAMMAR_BASIC,
      action: Actions.GRAMMAR_OVERVIEW_FETCH,
      params: { idGrammar },
      withTitleChange: true,
      modify: (data: any) =>
        commit(Mutations.SET_INSPECTED_GRAMMAR, {
          basic: data,
        }),
      error: () => commit(Mutations.SET_INSPECTED_GRAMMAR, {}),
    });
  },

  [Actions.GRAMMAR_DUPLICATE]: ({ dispatch }, idGrammar) =>
    dispatch(Actions.INSTANCE_DUPLICATE, {
      action: Actions.GRAMMAR_DUPLICATE,
      param: { id: "idGrammar", value: idGrammar },
      prefix: "Grammar",
      dialog: Dialogs.GRAMMAR_DUPLICATE,
      redirectRoute: Routes.GRAMMARS_DETAIL_BASIC,
      endpoint: ApiEndpoints.GRAMMAR_DUPLICATE,
    }),

  [Actions.GRAMMAR_REMOVE]: ({ dispatch }, { idGrammar, isDetailActive }) =>
    dispatch(Actions.INSTANCE_REMOVE, {
      params: { idGrammar },
      isDetailActive,
      endpoint: ApiEndpoints.GRAMMAR_REMOVE,
      action: Actions.GRAMMAR_REMOVE,
      redirectRoute: Routes.GRAMMARS_OVERVIEW,
      dataGrid: DataGrids.GRAMMARS,
      dataGridFetch: Actions.GRAMMARS_DATAGRID_FETCH,
      dialog: Dialogs.GRAMMAR_REMOVE,
      prefix: "Grammar",
    }),
};

export default grammarActions;
