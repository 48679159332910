import { GetterTree } from "vuex";
import { Getters } from "@/models/store";
import UserState from "@/models/store/UserState";

const getters: GetterTree<UserState, []> = {
  [Getters.USER_INFO]: (state) => state.properties,

  [Getters.USER_ACCOUNTS]: (state) => state.connectedAcounts,

  [Getters.HAS_PERMISSIONS]: (state) => (requiredPermissions: string[]) =>
    requiredPermissions.every((permission) =>
      state.properties.permissions.includes(permission)
    ),

  [Getters.USER_TOKEN]: (state) => state.token,

  [Getters.IS_USER_SIGNED_IN]: (state) => state.isUserSignedIn,
};

export default getters;
