import Vue, { VNode } from "vue";
import DetailWithTabs from "@/components/DetailWithTabs";
import Routes from "@/constants/routes";
import useFlowActions from "@/hooks/useFlowActions";
import ExternalLinks from "@/constants/externalLinks";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import CsvExportDialog from "@/components/CsvExportDialog";
import FlowDuplicateDialog from "@/components/FlowDuplicateDialog";
import FlowRemoveDialog from "@/components/FlowRemoveDialog";
import Dialogs from "@/constants/dialogs";
import ApiEndpoints from "@/constants/apiEndpoints";
import { Namespaces, Permissions } from "@/constants/permissions";
import hasUserPermissions from "@/helpers/hasUserPermissions";

const FlowDetail = Vue.extend({
  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_FLOW]),

    flowData(): any {
      const name = this.INSPECTED_FLOW?.basic?.content?.name;
      const id = this.$route.params.idCallScript;

      return { name, id };
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.FLOW_OVERVIEW_FETCH]),
  },

  render(): VNode {
    return (
      <div>
        <DetailWithTabs
          title={`Flow - ${this.flowData.name || ""}`}
          defaultReturnRoute={Routes.FLOW_OVERVIEW}
          initDataFetch={() =>
            this.FLOW_OVERVIEW_FETCH({
              idCallScript: this.flowData.id,
            })
          }
          actions={[
            {
              icon: "mdi-file-tree",
              title: "Design",
              isAllowed: hasUserPermissions(Namespaces.CALLSCRIPT, [
                Permissions.VIEW_CALLSCRIPT,
              ]),
              href: `${ExternalLinks.DESIGNER}?scenarioId=${this.flowData.id}`,
            },
            ...useFlowActions(this.flowData),
          ]}
          tabs={[
            {
              title: "General",
              path: Routes.FLOW_DETAIL_BASIC,
            },
            {
              title: "Conversation log",
              path: Routes.FLOW_DETAIL_DIAL_LOGS,
            },
            {
              title: "Version history",
              path: Routes.FLOW_DETAIL_VERSION_HISTORY,
            },
          ]}
        />
        <CsvExportDialog
          name={Dialogs.FLOW_CSV_EXPORT}
          apiEndpoint={ApiEndpoints.CSV_REPORTS_FLOW}
          prefix="flow"
        />
        <FlowDuplicateDialog />
        <FlowRemoveDialog />
      </div>
    );
  },
});

export default FlowDetail;
