import { ActionTree } from "vuex";
import { Actions, Mutations } from "@/models/store";
import InstancesState from "@/models/store/InstancesState";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Routes from "@/constants/routes";
import DataGrids from "@/constants/dataGrids";
import createRequest from "@/helpers/createRequest";
import parseResponse from "@/helpers/parseResponse";
import toggleDialog from "@/helpers/toggleDialog";
import pushNotify from "@/helpers/pushNotify";
import Notify from "@/constants/notifications";

const permissionGroupActions: ActionTree<InstancesState, []> = {
  [Actions.PERMISSION_GROUP_OVERVIEW_FETCH]: (
    { state, commit, dispatch },
    { idRole }
  ) => {
    dispatch(Actions.SECTION_DETAIL_FETCH, {
      url: ApiEndpoints.PERMISSION_GROUP_BASIC,
      action: Actions.PERMISSION_GROUP_OVERVIEW_FETCH,
      params: { idRole },
      withTitleChange: true,
      modify: (data: any) =>
        commit(Mutations.SET_INSPECTED_PERMISSION_GROUP, {
          ...state.inspectedPermissionGroup,
          basic: data,
        }),
      error: () => commit(Mutations.SET_INSPECTED_PERMISSION_GROUP, {}),
    });
  },

  [Actions.PERMISSION_GROUP_REMOVE]: (
    { dispatch },
    { idRole, isDetailActive }
  ) =>
    dispatch(Actions.INSTANCE_REMOVE, {
      params: { idRole },
      isDetailActive,
      endpoint: ApiEndpoints.PERMISSION_GROUP_REMOVE,
      action: Actions.PERMISSION_GROUP_REMOVE,
      redirectRoute: Routes.PERMISSION_GROUPS_OVERVIEW,
      dataGrid: DataGrids.PERMISSION_GROUPS,
      dataGridFetch: Actions.PERMISSION_GROUPS_DATAGRID_FETCH,
      dialog: Dialogs.PERMISSION_GROUP_REMOVE,
      prefix: "Group",
    }),

  [Actions.PERMISSIONS_GROUPS_FETCH]: async () => {
    const permissionGroupsRes = await createRequest({
      action: Actions.PERMISSIONS_GROUPS_FETCH,
      method: "GET",
      url: ApiEndpoints.PERMISSIONS_GROUPS_LIST,
    });

    const { data, errors } = parseResponse(permissionGroupsRes);

    if (errors.length === 0) {
      return data;
    } else {
      return null;
    }
  },

  [Actions.PERMISSIONS_FETCH]: async (_, { idRole }) => {
    const permissionsRes = await createRequest({
      action: Actions.PERMISSIONS_FETCH,
      method: "GET",
      url: ApiEndpoints.PERMISSION_GROUP_PERMISSIONS,
      params: { idRole },
    });

    const { data, errors } = parseResponse(permissionsRes);

    if (errors.length === 0) {
      return {
        content: data.content,
        editableFields: data.editableFields,
      };
    } else {
      return null;
    }
  },

  [Actions.PERMISSIONS_WITH_GROUPS_FETCH]: async (
    { commit, dispatch, state },
    { idRole }
  ) => {
    const groups = await dispatch(Actions.PERMISSIONS_GROUPS_FETCH);
    const permissions = await dispatch(Actions.PERMISSIONS_FETCH, { idRole });

    if (groups && permissions) {
      groups.sort((a: string, b: string) => (a < b ? -1 : a > b ? 1 : 0));

      const permissionWithGroups = groups.map((group: string) => {
        const groupPermissions = permissions.content.filter((permission: any) =>
          permission.name.startsWith(`${group}.`)
        );
        return { name: group, permissions: groupPermissions };
      });

      commit(Mutations.SET_INSPECTED_PERMISSION_GROUP, {
        ...state.inspectedPermissionGroup,
        permissions: {
          content: permissionWithGroups,
          editableFields: permissions.editableFields,
        },
      });
    }
  },

  [Actions.PERMISSION_GROUP_PERMISSIONS_EDIT]: async (
    _,
    { permissions, idRole }
  ) => {
    const res = await createRequest({
      action: Actions.PERMISSION_GROUP_PERMISSIONS_EDIT,
      method: "POST",
      url: ApiEndpoints.PERMISSION_GROUP_PERMISSIONS,
      data: permissions,
      params: { idRole, mask: "" },
    });

    const { errors } = parseResponse(res);

    if (errors.length === 0) {
      pushNotify(Notify.SUCCESS, "Permissions changed successfully");
      toggleDialog(Dialogs.PERMISSION_GROUP_PERMISSIONS_EDIT, {});
    }
  },
};

export default permissionGroupActions;
