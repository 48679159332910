import { MutationTree } from "vuex";
import { Mutations } from "@/models/store";
import NotificationState from "@/models/store/NotificationState";
import Vue from "vue";

const mutations: MutationTree<NotificationState> = {
  [Mutations.SET_NOTIFICATION]: (state, { type, text }) => {
    state.type = type;
    state.text = text;
  },

  [Mutations.TOGGLE_NOTIFICATION]: (state) => {
    Vue.set(state, "isActive", !state.isActive);
  },
};

export default mutations;
