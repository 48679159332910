import Vue, { VNode } from "vue";
import DetailWithTabs from "@/components/DetailWithTabs";
import Routes from "@/constants/routes";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import useBotDeployModuleActions from "@/hooks/useBotDeployModuleActions";
import BotDeployModuleRemoveDialog from "../BotDeployModuleRemoveDialog";
import BotDeployModuleToggleDialog from "../BotDeployModuleToggleDialog";
import BotDeployModuleRestartDialog from "../BotDeployModuleRestartDialog";

const BotDeployModuleDetail = Vue.extend({
  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_BOTDEPLOY_MODULE]),

    instanceData(): any {
      const content = this.INSPECTED_BOTDEPLOY_MODULE?.basic?.content;
      const name = this.$route.params.moduleName;
      const server = this.$route.params.serverName;
      const enabled = content?.count == 0 ? false : true;
      return { name, server, enabled, ...content };
    },
  },
  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.BOTDEPLOY_MODULE_OVERVIEW_FETCH]),
  },

  render(): VNode {
    return (
      <div>
        <DetailWithTabs
          title={`Module - ${this.instanceData.name || ""} on ${
            this.instanceData.server || ""
          }`}
          defaultReturnRoute={Routes.BOTDEPLOY_MODULES}
          initDataFetch={() =>
            this.BOTDEPLOY_MODULE_OVERVIEW_FETCH({
              name: this.$route.params.moduleName,
              server: this.$route.params.serverName,
            })
          }
          actions={useBotDeployModuleActions(this.instanceData)}
          tabs={[
            {
              title: "General",
              path: Routes.BOTDEPLOY_MODULE_DETAIL_BASIC,
            },
          ]}
        />
        <BotDeployModuleRemoveDialog />
        <BotDeployModuleToggleDialog />
        <BotDeployModuleRestartDialog />
      </div>
    );
  },
});

export default BotDeployModuleDetail;
