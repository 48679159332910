import ApiEndpoints from "@/constants/apiEndpoints";
import Dropdowns from "@/constants/dropdowns";
import Vue, { VNode } from "vue";
import DynamicSelect from "../DynamicSelect";

const TenantSelect = Vue.extend({
  props: {
    value: Number,
    options: Object,
  },

  render(): VNode {
    return (
      <DynamicSelect
        name={Dropdowns.TENANTS}
        label="Tenant"
        options={this.options}
        fetchUrl={ApiEndpoints.TENANT_LIST}
        onInput={(val: number) => this.$emit("input", val)}
        value={this.value}
        isValueNumber
      />
    );
  },
});

export default TenantSelect;
