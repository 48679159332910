import { GetterTree } from "vuex";
import { Getters } from "@/models/store";
import DialogsState from "@/models/store/DialogsState";

const getters: GetterTree<DialogsState, []> = {
  [Getters.IS_DIALOG_OPEN]: (state) => (dialog: string) =>
    state[dialog].isActive,

  [Getters.GET_DIALOG_DATA]: (state) => (dialog: string) => state[dialog].data,
};

export default getters;
