import { Module } from "vuex";

import ApiCallsState from "@/models/store/ApiCallsState";
import state from "./state";
import getters from "./getters";
import mutations from "./mutations";

const apiCalls: Module<ApiCallsState, []> = {
  namespaced: true,
  state,
  getters,
  mutations,
};

export default apiCalls;
