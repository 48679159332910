import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import toggleDialog from "@/helpers/toggleDialog";
import { Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import {
  VBtn,
  VDatePicker,
  VForm,
  VIcon,
  VMenu,
  VTextField,
} from "vuetify/lib";
import { mapGetters } from "vuex";
import CsvExportDialog from "../CsvExportDialog";
import TenantSelect from "../TenantSelect";

const StatisticsControlPanel = Vue.extend({
  props: {
    fetchStatistics: Function,
    fetchCsvDataRoute: Function,
    fetchCsvReportsRoute: Function,
    fetchParams: Object,
    defaultFilters: Object,
    withTenantSelection: Boolean,
  },

  data: () => ({
    filters: {
      from: "",
      to: "",
      roundInterval: "",
      idTenant: "",
    },
    menus: {
      from: false,
      to: false,
    },
  }),

  computed: {
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    getTenantFilter(): string {
      return this.filters.idTenant;
    },
  },

  created() {
    this.filters = {
      ...this.defaultFilters,
      ...(this.defaultFilters.idTenant && {
        idTenant: parseInt(this.defaultFilters.idTenant),
      }),
    };
  },

  render(): VNode {
    return (
      <div>
        <VForm class="d-flex">
          {this.withTenantSelection && (
            <TenantSelect
              vModel={this.filters.idTenant}
              options={{ outlined: true, dense: true }}
            />
          )}
          <VMenu
            vModel={this.menus.from}
            offsetY
            transition="scale-transition"
            maxWidth="290"
            scopedSlots={{
              activator: ({ on, attrs }: any) => (
                <VTextField
                  class={`mr-4 ${this.withTenantSelection ? "ml-4" : ""}`}
                  outlined
                  dense
                  vModel={this.filters.from}
                  label="Date from"
                  readonly
                  prependIcon="mdi-calendar"
                  {...{ on, attrs }}
                />
              ),
            }}
          >
            <VDatePicker vModel={this.filters.from} firstDayOfWeek={1} />
          </VMenu>

          <VMenu
            vModel={this.menus.to}
            offsetY
            transition="scale-transition"
            maxWidth="290"
            scopedSlots={{
              activator: ({ on, attrs }: any) => (
                <VTextField
                  class="mr-4"
                  outlined
                  dense
                  vModel={this.filters.to}
                  label="Date to"
                  readonly
                  prependIcon="mdi-calendar"
                  {...{ on, attrs }}
                />
              ),
            }}
          >
            <VDatePicker vModel={this.filters.to} firstDayOfWeek={1} />
          </VMenu>

          <VTextField
            type="number"
            dense
            outlined
            label="Round interval"
            vModel={this.filters.roundInterval}
          />
        </VForm>
        <div class="d-flex justify-end">
          {this.fetchCsvDataRoute && (
            <div class="d-flex justify-end">
              <VBtn
                disabled={
                  this.filters.idTenant === "" && this.withTenantSelection
                }
                onClick={() => {
                  const idTenant = this.withTenantSelection
                    ? this.filters.idTenant
                    : this.fetchParams.idTenant;

                  toggleDialog(Dialogs.TENANT_STATISTICS_OVERVIEW_EXPORT, {
                    range: [this.filters.from, this.filters.to],
                    idProperty: idTenant,
                    fetchParams: {
                      idTenant,
                      roundInterval: this.filters.roundInterval,
                    },
                  });
                }}
                rounded
                class="secondary primary--text mr-3"
              >
                <VIcon left>mdi-download</VIcon>
                Download CSV summary
              </VBtn>
              <VBtn
                disabled={
                  this.filters.idTenant === "" && this.withTenantSelection
                }
                onClick={() =>
                  toggleDialog(Dialogs.TENANT_STATISTICS_REPORT_EXPORT, {
                    range: [this.filters.from, this.filters.to],
                    idProperty: this.filters.idTenant,
                    fetchParams: {
                      ...(this.withTenantSelection && {
                        idTenant: this.filters.idTenant,
                      }),
                      ...this.fetchParams,
                    },
                  })
                }
                rounded
                class="secondary primary--text mr-3"
              >
                <VIcon left>mdi-microsoft-excel</VIcon>Download report
              </VBtn>
            </div>
          )}

          <VBtn
            disabled={this.filters.idTenant === "" && this.withTenantSelection}
            onClick={() => this.fetchStatistics(this.filters)}
            rounded
            class="tertiary white--text"
          >
            <VIcon left>mdi-check</VIcon>Apply
          </VBtn>
        </div>

        <CsvExportDialog
          name={Dialogs.TENANT_STATISTICS_REPORT_EXPORT}
          apiEndpoint={this.fetchCsvReportsRoute}
          prefix="statistics"
        />

        <CsvExportDialog
          name={Dialogs.TENANT_STATISTICS_OVERVIEW_EXPORT}
          apiEndpoint={this.fetchCsvDataRoute}
          prefix="statistics-overview"
        />
      </div>
    );
  },
});

export default StatisticsControlPanel;
