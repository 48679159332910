import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { isRequired } from "@/helpers/validations";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VBtn, VForm, VSwitch, VTextField } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import BotDeployConfigSelect from "../BotDeployConfigSelect";

const BotDeployModuleEditForm = Vue.extend({
  data: () => ({
    fields: {
      configuration: "",
      version: "",
      enabled: false,
      ipA: "",
      ipB: "",
    },
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_BOTDEPLOY_MODULE]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.BOTDEPLOY_MODULE_OVERVIEW_FETCH]),

    async handleSubmit() {
      const { isFormSubmited } = await submitForm({
        ref: this.$refs.form,
        url: ApiEndpoints.BOTDEPLOY_MODULE_BASIC,
        values: { ...this.fields, count: this.fields.enabled ? 1 : 0 },
        params: {
          moduleName: this.$route.params.moduleName,
          serverName: this.$route.params.serverName,
        },
        actionId: Actions.BOTDEPLOY_MODULE_OVERVIEW_EDIT,
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.BOTDEPLOY_MODULE_EDIT_BASIC, {});
        pushNotify(Notify.SUCCESS, "Module edited successfully");

        this.BOTDEPLOY_MODULE_OVERVIEW_FETCH({
          name: this.$route.params.moduleName,
          server: this.$route.params.serverName,
        });
      }
    },
  },

  created() {
    this.fields = {
      ...this.INSPECTED_BOTDEPLOY_MODULE?.basic?.content,
      enabled:
        this.INSPECTED_BOTDEPLOY_MODULE?.basic?.content.count == 0
          ? false
          : true,
    };
  },
  render(): VNode {
    return (
      <VForm
        ref="form"
        onSubmit={(e: Event) => {
          e.preventDefault();
          this.handleSubmit();
        }}
      >
        <VTextField
          vModel={this.fields.version}
          rules={[isRequired]}
          label="Version"
        />

        <BotDeployConfigSelect vModel={this.fields.configuration} />

        <VTextField vModel={this.fields.ipA} label="IP A" />

        <VTextField vModel={this.fields.ipB} label="IP B" />

        <VSwitch vModel={this.fields.enabled} label="Enabled" />

        <VBtn type="submit" class="d-none"></VBtn>
      </VForm>
    );
  },
});

export default BotDeployModuleEditForm;
