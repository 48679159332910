import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import { Namespaces, Permissions } from "@/constants/permissions";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import {
  VBtn,
  VCard,
  VCardText,
  VIcon,
  VSimpleTable,
  VSkeletonLoader,
} from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import SectionToolbar from "../SectionToolbar";
import UserApiSecretRemoveDialog from "../UserApiSecretRemoveDialog";

const UserApiSecrets = Vue.extend({
  props: {
    idUser: String,
  },

  data: () => ({
    isUserSecretHidden: true,
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_USER]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [
      Actions.USER_API_SECRET_FETCH,
      Actions.USER_API_SECRET_GENERATE,
    ]),
  },

  created() {
    this.USER_API_SECRET_FETCH({ idUser: this.idUser });
  },

  render(): VNode {
    return (
      <div>
        <SectionToolbar
          title="User API secrets"
          actions={
            this.INSPECTED_USER.apiSecret ||
            this.IS_ACTION_FETCHING(Actions.USER_API_SECRET_GENERATE) ||
            !hasUserPermissions(Namespaces.ACCESS_MANAGEMENT, [
              Permissions.EDIT_API_SECRET,
            ])
              ? null
              : [
                  {
                    icon: "mdi-plus",
                    action: () =>
                      this.USER_API_SECRET_GENERATE({ idUser: this.idUser }),
                  },
                ]
          }
        />
        <VCard flat>
          <VCardText class="pt-3">
            {this.IS_ACTION_FETCHING(Actions.USER_API_SECRET_FETCH) ||
            this.IS_ACTION_FETCHING(Actions.USER_API_SECRET_GENERATE) ? (
              <VSkeletonLoader type="list-item" />
            ) : (
              <div>
                {this.INSPECTED_USER.apiSecret ? (
                  <VSimpleTable>
                    <thead>
                      <tr>
                        <th class="text-left">Client ID</th>
                        <th class="text-left" style="width: 55%">
                          Client secret
                        </th>
                        <th class="text-left">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {this.idUser}{" "}
                          <VBtn
                            text
                            fab
                            small
                            class="ml-2"
                            color="tertiary"
                            onClick={() => {
                              navigator.clipboard.writeText(this.idUser);
                              pushNotify(
                                Notify.INFO,
                                "Client ID copied to clipboard"
                              );
                            }}
                          >
                            <VIcon>mdi-content-copy</VIcon>
                          </VBtn>
                        </td>
                        <td>
                          {this.isUserSecretHidden
                            ? "*".repeat(this.INSPECTED_USER.apiSecret.length)
                            : this.INSPECTED_USER.apiSecret}

                          <VBtn
                            text
                            fab
                            small
                            class="ml-2"
                            color="tertiary"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                this.INSPECTED_USER.apiSecret
                              );
                              pushNotify(
                                Notify.INFO,
                                "Client secret ID copied to clipboard"
                              );
                            }}
                          >
                            <VIcon>mdi-content-copy</VIcon>
                          </VBtn>
                        </td>
                        <td>
                          <VBtn
                            class="tertiary white--text mr-2"
                            fab
                            xSmall
                            onClick={() =>
                              (this.isUserSecretHidden =
                                !this.isUserSecretHidden)
                            }
                          >
                            <VIcon>
                              {this.isUserSecretHidden
                                ? "mdi-eye"
                                : "mdi-eye-off"}
                            </VIcon>
                          </VBtn>
                          <VBtn
                            class="error white--text"
                            fab
                            xSmall
                            onClick={() =>
                              toggleDialog(Dialogs.USER_API_SECRET_REMOVE, {
                                idUser: this.idUser,
                              })
                            }
                          >
                            <VIcon>mdi-delete</VIcon>
                          </VBtn>
                        </td>
                      </tr>
                    </tbody>
                  </VSimpleTable>
                ) : (
                  <span>User has not any generated API keys.</span>
                )}
              </div>
            )}
          </VCardText>
        </VCard>
        <UserApiSecretRemoveDialog />
      </div>
    );
  },
});

export default UserApiSecrets;
