import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import Routes from "@/constants/routes";
import lineIdentificatorTypes from "@/constants/lineIdetificatorTypes";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { isRequired } from "@/helpers/validations";
import { Actions } from "@/models/store";
import Vue, { PropType, VNode } from "vue";
import { VForm, VSelect, VTextField } from "vuetify/lib";
import TenantSelect from "../TenantSelect";
import FlowSelect from "../FlowSelect";
import ModulesSelect from "../ModulesSelect";

const LineCreateForm = Vue.extend({
  props: {
    idDialModule: [String, Number] as PropType<any>,
  },

  data: () => ({
    fields: {
      name: "",
      identificator: "",
      identificatorType: 0,
      idTenant: null,
      callScriptCode: null,
      idDialModule: null,
    },
    isFormValid: true,
  }),

  methods: {
    async handleSubmit(): Promise<void> {
      const refs: any = this.$refs;
      const fields: any = this.fields;

      const { idDialModule, ...submitedFields } = fields;

      const { isFormSubmited, data } = await submitForm({
        actionId: Actions.LINE_CREATE,
        ref: refs.form,
        values: submitedFields,
        url: ApiEndpoints.MODULE_LINE_CREATE,
        params: { idDialModule },
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.LINE_CREATE, {});

        this.$router.push({
          name: Routes.LINE_DETAIL_BASIC,
          params: { idDialModuleLine: data.id },
        });

        pushNotify(Notify.SUCCESS, "Line created successfully");
      }
    },
  },

  created() {
    this.idDialModule ? (this.fields.idDialModule = this.idDialModule) : null;
  },

  render(): VNode {
    return (
      <VForm ref="form" vModel={this.isFormValid}>
        <VTextField
          vModel={this.fields.name}
          label="Name"
          rules={[isRequired]}
        />
        <ModulesSelect vModel={this.fields.idDialModule} />
        <VTextField vModel={this.fields.identificator} label="Identificator" />
        <VSelect
          vModel={this.fields.identificatorType}
          items={lineIdentificatorTypes}
          label="Identificator type"
          rules={[isRequired]}
        />
        <TenantSelect vModel={this.fields.idTenant} />
        <FlowSelect vModel={this.fields.callScriptCode} />
      </VForm>
    );
  },
});

export default LineCreateForm;
