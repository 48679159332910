import Dialogs from "@/constants/dialogs";
import { Namespaces, Permissions } from "@/constants/permissions";
import Routes from "@/constants/routes";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import toggleDialog from "@/helpers/toggleDialog";
import ActionHandler from "@/models/actions/ActionHandler";
import FlowActionsParams from "@/models/actions/FlowActionsParams";

const useFlowActions = ({
  isComponent,
  id,
  name,
}: FlowActionsParams): ActionHandler[] => {
  const permissionsNs = isComponent
    ? Namespaces.COMPONENT
    : Namespaces.CALLSCRIPT;

  return [
    ...(!isComponent
      ? [
          {
            icon: "mdi-chart-bar",
            title: "Reporting",
            route: {
              name: Routes.REPORTING,
              query: { callscriptId: id.toString() },
            },
          },
          {
            title: "CSV Reports",
            icon: "mdi-microsoft-excel",
            action: () => toggleDialog(Dialogs.FLOW_CSV_EXPORT, { id }),
            isAllowed: hasUserPermissions(permissionsNs, [Permissions.VIEW]),
          },
        ]
      : []),
    {
      icon: "mdi-content-copy",
      title: "Duplicate",
      action: () => toggleDialog(Dialogs.FLOW_DUPLICATE, { id, name }),
      isAllowed: hasUserPermissions(permissionsNs, [Permissions.CREATE]),
    },
    {
      icon: "mdi-delete",
      title: "Remove",
      action: () => toggleDialog(Dialogs.FLOW_REMOVE, { id, name }),
      isAllowed: hasUserPermissions(permissionsNs, [Permissions.DELETE]),
    },
  ];
};

export default useFlowActions;
