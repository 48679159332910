import Dialogs from "@/constants/dialogs";
import toggleDialog from "@/helpers/toggleDialog";
import ActionHandler from "@/models/actions/ActionHandler";
import BotDeployServerActionsParams from "@/models/actions/BotDeployServerActionsParams";

const useBotDeployServerActions = ({
  name,
}: BotDeployServerActionsParams): ActionHandler[] => [
  {
    icon: "mdi-delete",
    title: "Remove",
    action: () => toggleDialog(Dialogs.BOTDEPLOY_SERVER_REMOVE, { name }),
  },
];

export default useBotDeployServerActions;
