import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import getSectionEditableValues from "@/helpers/getSectionEditableValues";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VBtn, VForm, VTextField } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";

const Fields = {
  system: "system",
  note: "note",
};

const BotDeployServerEditForm = Vue.extend({
  data: () => ({
    fields: {},
    isFormValid: true,
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_BOTDEPLOY_SERVER]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.BOTDEPLOY_SERVER_OVERVIEW_FETCH]),

    isEditable(name: string): boolean {
      return this.INSPECTED_BOTDEPLOY_SERVER.basic.editableFields.includes(
        name
      );
    },

    async handleSubmit(): Promise<void> {
      const refs: any = this.$refs;
      const submitedFields = getSectionEditableValues(
        this.fields,
        this.INSPECTED_BOTDEPLOY_SERVER.basic
      );

      const { isFormSubmited } = await submitForm({
        actionId: Actions.BOTDEPLOY_MODULE_OVERVIEW_EDIT,
        ref: refs.form,
        values: submitedFields,
        url: ApiEndpoints.BOTDEPLOY_SERVER_BASIC,
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.BOTDEPLOY_SERVER_EDIT_BASIC, {});
        pushNotify(Notify.SUCCESS, "Server edited successfully");

        this.BOTDEPLOY_SERVER_OVERVIEW_FETCH({
          name: this.$route.params.name,
        });
      }
    },
  },

  created() {
    this.fields = { ...this.INSPECTED_BOTDEPLOY_SERVER.basic.content };
  },

  render(): VNode {
    return (
      <VForm
        vModel={this.isFormValid}
        ref="form"
        onSubmit={(e: Event) => {
          e.preventDefault();
          this.handleSubmit();
        }}
      >
        <VTextField
          vModel={this.fields[Fields.system]}
          label="System"
          disabled={!this.isEditable(Fields.system)}
        />
        <VTextField
          vModel={this.fields[Fields.note]}
          label="Note"
          disabled={!this.isEditable(Fields.note)}
        />
        <VBtn type="submit" class="d-none"></VBtn>
      </VForm>
    );
  },
});

export default BotDeployServerEditForm;
