const STORAGE_NAME = "user_settings";

export const setStorageItem = (item: string, value: any): void => {
  const userSettings = localStorage.getItem(STORAGE_NAME);

  if (!userSettings) {
    localStorage.setItem(STORAGE_NAME, JSON.stringify({ [item]: value }));
    return;
  }

  const parsedStorage = JSON.parse(userSettings);
  localStorage.setItem(
    STORAGE_NAME,
    JSON.stringify({ ...parsedStorage, [item]: value })
  );
};

export const getStorageItem = (item: string): any => {
  const userSettings = localStorage.getItem(STORAGE_NAME);

  if (!userSettings) {
    return null;
  }

  return JSON.parse(userSettings)[item];
};
