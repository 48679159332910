import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import getSectionEditableValues from "@/helpers/getSectionEditableValues";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { isRequired } from "@/helpers/validations";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VForm, VSelect, VTextField } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import TenantSelect from "../TenantSelect";

const Fields = {
  mode: "mode",
  name: "name",
  idTenant: "idTenant",
};

const ModuleBasicEditForm = Vue.extend({
  data: () => ({
    fields: {},
    isValid: true,
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_MODULE]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.MODULE_OVERVIEW_FETCH]),

    isEditable(name: string): boolean {
      return this.INSPECTED_MODULE.basic.editableFields.includes(name);
    },

    async handleSubmit(): Promise<void> {
      const refs: any = this.$refs;
      const submitedFields = getSectionEditableValues(
        this.fields,
        this.INSPECTED_MODULE.basic
      );

      const { isFormSubmited } = await submitForm({
        actionId: Actions.MODULE_OVERVIEW_EDIT,
        ref: refs.form,
        values: submitedFields,
        url: ApiEndpoints.MODULE_BASIC,
        params: { idDialModule: this.$route.params.idDialModule },
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.MODULE_EDIT_BASIC, {});
        pushNotify(Notify.SUCCESS, "Module edited successfully");

        this.MODULE_OVERVIEW_FETCH({
          idDialModule: this.$route.params.idDialModule,
        });
      }
    },
  },

  created() {
    this.fields = { ...this.INSPECTED_MODULE.basic.content };
  },

  render(): VNode {
    return (
      <VForm ref="form" vModel={this.isValid}>
        <VTextField
          vModel={this.fields[Fields.name]}
          label="Name"
          disabled={!this.isEditable(Fields.name)}
          rules={[isRequired]}
        />
        <VSelect
          vModel={this.fields[Fields.mode]}
          items={["SIPClient"]}
          label="Mode"
          disabled={!this.isEditable(Fields.mode)}
        />
        <TenantSelect vModel={this.fields[Fields.idTenant]} />
      </VForm>
    );
  },
});

export default ModuleBasicEditForm;
