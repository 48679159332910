import { MutationTree } from "vuex";
import { Mutations } from "@/models/store";
import DataGridsState from "@/models/store/DataGridsState";

const mutations: MutationTree<DataGridsState> = {
  [Mutations.SET_DATAGRID_DATA]: (state, { dataGrid, data }) => {
    state[dataGrid] = data;
  },

  [Mutations.TOGGLE_FAVORITE_FLOWS]: (state) =>
    (state.favoriteFlows = !state.favoriteFlows),
};

export default mutations;
