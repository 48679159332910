import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VDivider } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import EditableOverviewSection from "../EditableOverviewSection";
import TenantOverviewEditForm from "../TenantOverviewEditForm";
import TenantParametersEditForm from "../TenantParametersEditForm";

const TenantOverview = Vue.extend({
  data: () => ({
    idTenant: "",
    basic: {
      title: "Overview",
      columns: [
        { name: "invoicingName", title: "Invoicing name" },
        { name: "address", title: "Address" },
        { name: "companyNumber", title: "Company number" },
        { name: "vatNumber", title: "VAT number" },
      ],
    },
    parameters: {
      title: "Parameters",
      columns: [],
    },
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_TENANT]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.TENANT_PARAMS_FETCH]),
  },

  created() {
    this.idTenant = this.$route.params.idTenant;
  },

  render(): VNode {
    return (
      <div>
        <EditableOverviewSection
          configuration={this.basic}
          data={this.INSPECTED_TENANT?.basic}
          editDialog={Dialogs.TENANT_EDIT_BASIC}
          action={Actions.TENANT_OVERVIEW_EDIT}
          scopedSlots={{ form: () => <TenantOverviewEditForm ref="form" /> }}
          isDataFetching={this.IS_ACTION_FETCHING(
            Actions.TENANT_OVERVIEW_FETCH
          )}
        />

        <VDivider class="mb-5" />

        <EditableOverviewSection
          configuration={this.parameters}
          fetchData={() =>
            this.TENANT_PARAMS_FETCH({ idTenant: this.idTenant })
          }
          data={this.INSPECTED_TENANT?.parameters}
          editDialog={Dialogs.TENANT_EDIT_PARAMS}
          action={Actions.TENANT_PARAMS_EDIT}
          scopedSlots={{ form: () => <TenantParametersEditForm ref="form" /> }}
          isDataFetching={this.IS_ACTION_FETCHING(Actions.TENANT_PARAMS_FETCH)}
          overviewComponent={
            <div>{this.INSPECTED_TENANT?.parameters?.content}</div>
          }
        />
      </div>
    );
  },
});

export default TenantOverview;
