import Routes from "@/constants/routes";
import Vue, { VNode } from "vue";
import {
  VBtn,
  VCard,
  VCardText,
  VCardTitle,
  VDataTable,
  VIcon,
} from "vuetify/lib";

const StatisticsLineDataGrid = Vue.extend({
  props: {
    moduleName: String,
    lines: Array,
  },

  render(): VNode {
    return (
      <VCard class="mx-2 mb-5">
        <VCardTitle class="text-h6">{this.moduleName}</VCardTitle>
        <VCardText>
          {this.lines && (
            <div>
              <VDataTable
                headers={[
                  {
                    text: "Name",
                    value: "name",
                  },
                  {
                    text: "Total calls",
                    value: "callsCount",
                  },
                  {
                    text: "Calls duration (min)",
                    value: "totalDuration",
                  },
                  {
                    text: "Inbound (min)",
                    value: "inboundDuration",
                  },
                  {
                    text: "Outbound (min)",
                    value: "outboundDuration",
                  },
                  {
                    text: "Actions",
                    value: "actions",
                  },
                ]}
                items={this.lines}
                items-per-page={-1}
                hideDefaultFooter
                scopedSlots={{
                  "item.callsCount": ({ item }: any) =>
                    item.callsCount.toLocaleString("cs"),

                  "item.totalDuration": ({ item }: any) =>
                    ((item.totalDuration ?? 0) / 60).toLocaleString("cs"),

                  "item.inboundDuration": ({ item }: any) => (
                    <span>
                      <VIcon color="success" small class="mr-2">
                        mdi-phone-incoming
                      </VIcon>
                      {((item.inboundDuration ?? 0) / 60).toLocaleString("cs")}
                    </span>
                  ),

                  "item.outboundDuration": ({ item }: any) => (
                    <span>
                      <VIcon color="warning" small class="mr-2">
                        mdi-phone-outgoing
                      </VIcon>
                      {((item.outboundDuration ?? 0) / 60).toLocaleString("cs")}
                    </span>
                  ),

                  "item.actions": ({ item }: any) => (
                    <VBtn
                      class="tertiary white--text"
                      fab
                      xSmall
                      disabled={!item.id}
                      to={{
                        name: Routes.LINE_DETAIL_STATISTICS,
                        params: { idDialModuleLine: item.id },
                      }}
                    >
                      <VIcon>mdi-eye</VIcon>
                    </VBtn>
                  ),
                }}
              />
            </div>
          )}
        </VCardText>
      </VCard>
    );
  },
});

export default StatisticsLineDataGrid;
