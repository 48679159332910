import ActionHandler from "@/models/actions/ActionHandler";
import Vue, { PropType, VNode } from "vue";
import { VBtn, VIcon, VSpacer, VToolbar, VToolbarTitle } from "vuetify/lib";

const SectionToolbar = Vue.extend({
  props: {
    title: String,
    actions: Array as PropType<ActionHandler[]>,
  },

  render(): VNode {
    return (
      <VToolbar flat class="mb-2">
        <VToolbarTitle
          class="primary--text font-weight-bold text-h5"
          inset
          vertical
        >
          {this.title}
        </VToolbarTitle>
        <VSpacer />
        {this.actions?.map(
          (action) =>
            action && (
              <VBtn
                color={action.color ?? "tertiary"}
                onClick={action.action}
                class="ml-2 secondary--text"
                rounded
                fab={!action.title}
                small={!action.title}
                disabled={action.disabled}
              >
                <VIcon left={!!action.title}>{action.icon}</VIcon>
                {action.title}
              </VBtn>
            )
        )}
      </VToolbar>
    );
  },
});

export default SectionToolbar;
