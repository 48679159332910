import Vue, { VNode } from "vue";
import { VDivider, VIcon, VList, VListItem } from "vuetify/lib";
import Routes from "@/constants/routes";

const DialLogOverview = Vue.extend({
  props: {
    dialLogData: Object,
  },

  computed: {
    groupedVariables(): any {
      const flowVariables: any[] = [];
      const systemVariables: any[] = [];

      if (!this.dialLogData.detail?.variables) {
        return {};
      }

      Object.keys(this.dialLogData.detail.variables)?.map(
        (variable: string) => {
          const value = this.dialLogData.detail.variables[variable];
          variable.startsWith("_")
            ? systemVariables.push({ name: variable, value })
            : flowVariables.push({ name: variable, value });
        }
      );

      return { flowVariables, systemVariables };
    },
  },

  render(): VNode {
    const dialLogData: any = this.dialLogData;

    return (
      <div>
        <VList dense class="pt-0">
          <h3 class="text-h6 primary--text mb-2">Overview</h3>
          <VListItem>
            <VIcon small color="primary" class="mr-2">
              mdi-phone-incoming-outgoing
            </VIcon>
            <strong class="primary--text">Direction</strong>:&nbsp;
            {dialLogData.direction ? "Outgoing" : "Incoming"}
          </VListItem>

          <VListItem>
            <VIcon small color="primary" class="mr-2">
              mdi-phone
            </VIcon>
            <strong class="primary--text">Phone</strong>: {dialLogData.phone}
          </VListItem>

          {dialLogData.idCallscript && dialLogData.callscript && (
            <VListItem>
              <VIcon small color="primary" class="mr-2">
                mdi-file-tree
              </VIcon>
              <strong class="primary--text">Flow</strong>:&nbsp;
              <router-link
                to={{
                  name: Routes.FLOW_DETAIL_BASIC,
                  params: { idCallScript: dialLogData.idCallscript },
                }}
                target="_blank"
              >
                {dialLogData.callscript}
              </router-link>
            </VListItem>
          )}

          {dialLogData.idLine && dialLogData.line && (
            <VListItem>
              <VIcon small color="primary" class="mr-2">
                mdi-deskphone
              </VIcon>
              <strong class="primary--text">Line</strong>:&nbsp;
              <router-link
                to={{
                  name: Routes.LINE_DETAIL_BASIC,
                  params: { idDialModuleLine: dialLogData.idLine },
                }}
                target="_blank"
              >
                {dialLogData.line}
              </router-link>
            </VListItem>
          )}

          <VListItem>
            <VIcon small color="primary" class="mr-2">
              mdi-cog
            </VIcon>
            <strong class="primary--text">Guid</strong>:&nbsp;
            {dialLogData.guid}
          </VListItem>
          <VListItem>
            <VIcon small color="primary" class="mr-2">
              mdi-identifier
            </VIcon>
            <strong class="primary--text">Call-ID</strong>:&nbsp;
            {dialLogData.callId}
          </VListItem>
          <VListItem>
            <VIcon small color="primary" class="mr-2">
              mdi-phone-clock
            </VIcon>
            <strong class="primary--text">Duration</strong>:&nbsp;
            {dialLogData.duration}s
          </VListItem>

          {dialLogData.stateName && (
            <VListItem>
              <VIcon small color="primary" class="mr-2">
                mdi-phone-hangup
              </VIcon>
              <strong class="primary--text">Result</strong>:&nbsp;
              {dialLogData.stateName}
            </VListItem>
          )}

          {dialLogData.detail.variables?._parentGuid && (
            <VListItem>
              <VIcon small color="primary" class="mr-2">
                mdi-chevron-double-left
              </VIcon>
              <strong class="primary--text">Previous Conversation</strong>
              :&nbsp;
              <router-link
                to={{
                  name: Routes.CONVERSATION_DETAILS,
                  query: { guid: dialLogData.detail.variables._parentGuid },
                }}
                target="_blank"
              >
                {dialLogData.detail.variables._parentGuid}
              </router-link>
            </VListItem>
          )}
        </VList>

        {dialLogData.detail?.variables &&
          Object.keys(dialLogData.detail?.variables).length > 0 && (
            <div>
              <VDivider />
              {this.groupedVariables.flowVariables.length > 0 && (
                <div>
                  <h3 class="text-h6 primary--text mb-1 mt-3">
                    Flow variables
                  </h3>
                  <VList dense>
                    {this.groupedVariables.flowVariables?.map(
                      ({ name, value }: any) => (
                        <VListItem>
                          <strong class="primary--text">{name}</strong>: {value}
                        </VListItem>
                      )
                    )}
                  </VList>
                  <VDivider />
                </div>
              )}
              {this.groupedVariables.systemVariables.length > 0 && (
                <div>
                  <h3 class="text-h6 primary--text mb-1 mt-3">
                    System variables
                  </h3>
                  <VList dense>
                    {this.groupedVariables.systemVariables?.map(
                      ({ name, value }: any) => (
                        <VListItem>
                          <strong class="primary--text">{name}</strong>: {value}
                        </VListItem>
                      )
                    )}
                  </VList>
                </div>
              )}
            </div>
          )}
      </div>
    );
  },
});

export default DialLogOverview;
