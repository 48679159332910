import { MutationTree } from "vuex";
import { Mutations } from "@/models/store";
import DropdownsState from "@/models/store/DropdownsState";

const mutations: MutationTree<DropdownsState> = {
  [Mutations.SET_DROPDOWN_DATA]: (state, { dropdown, data }) => {
    state[dropdown] = data;
  },
};

export default mutations;
