import { ActionTree } from "vuex";
import { Actions, Modules, Mutations } from "@/models/store";
import InstancesState from "@/models/store/InstancesState";
import ApiEndpoints from "@/constants/apiEndpoints";
import Routes from "@/constants/routes";
import DataGrids from "@/constants/dataGrids";
import Dialogs from "@/constants/dialogs";
import createRequest from "@/helpers/createRequest";
import parseResponse from "@/helpers/parseResponse";
import toggleDialog from "@/helpers/toggleDialog";
import pushNotify from "@/helpers/pushNotify";
import Notify from "@/constants/notifications";
import parseJsonOrNull from "@/helpers/parseJsonOrNull";

const lineActions: ActionTree<InstancesState, []> = {
  [Actions.LINE_OVERVIEW_FETCH]: (
    { commit, dispatch, state },
    { idDialModuleLine }
  ) => {
    dispatch(Actions.SECTION_DETAIL_FETCH, {
      url: ApiEndpoints.LINE_BASIC,
      action: Actions.LINE_OVERVIEW_FETCH,
      params: { idDialModuleLine },
      withTitleChange: true,
      modify: (data: any) =>
        commit(Mutations.SET_INSPECTED_LINE, {
          ...state.inspectedLine,
          basic: data,
        }),
      error: () =>
        commit(Mutations.SET_INSPECTED_LINE, {
          ...state.inspectedLine,
          basic: {},
        }),
    });
  },

  [Actions.LINE_CALLBOT_CONFIG_FETCH]: (
    { commit, dispatch, state },
    { idDialModuleLine }
  ) => {
    dispatch(Actions.SECTION_DETAIL_FETCH, {
      url: ApiEndpoints.LINE_CALLBOT_SETTINGS,
      action: Actions.LINE_CALLBOT_CONFIG_FETCH,
      params: { idDialModuleLine },
      modify: ({ editableFields, content }: any) =>
        commit(Mutations.SET_INSPECTED_LINE, {
          ...state.inspectedLine,
          callbotSettings: {
            editableFields,
            content: parseJsonOrNull(content),
          },
        }),
      error: () =>
        commit(Mutations.SET_INSPECTED_LINE, {
          ...state.inspectedLine,
          callbotSettings: {},
        }),
    });
  },

  [Actions.LINE_CLEANING_SETTINGS_FETCH]: (
    { commit, dispatch, state },
    { idDialModuleLine }
  ) => {
    dispatch(Actions.SECTION_DETAIL_FETCH, {
      url: ApiEndpoints.LINE_CLEANING_SETTINGS,
      action: Actions.LINE_CLEANING_SETTINGS_FETCH,
      params: { idDialModuleLine },
      modify: (data: any) =>
        commit(Mutations.SET_INSPECTED_LINE, {
          ...state.inspectedLine,
          cleaningSettings: data,
        }),
      error: () =>
        commit(Mutations.SET_INSPECTED_LINE, {
          ...state.inspectedLine,
          cleaningSettings: {},
        }),
    });
  },

  [Actions.LINE_REMOVE]: ({ dispatch }, { idDialModuleLine, isDetailActive }) =>
    dispatch(Actions.INSTANCE_REMOVE, {
      params: { idDialModuleLine },
      isDetailActive,
      endpoint: ApiEndpoints.LINE_REMOVE,
      action: Actions.LINE_REMOVE,
      redirectRoute: Routes.LINES_OVERVIEW,
      dataGrid: DataGrids.LINES,
      dataGridFetch: Actions.LINES_DATAGRID_FETCH,
      dialog: Dialogs.LINE_REMOVE,
      prefix: "Line",
    }),

  [Actions.LINE_TOGGLE]: async (
    { dispatch },
    { idDialModuleLine, isEnabled, isDetailActive }
  ) => {
    const request = await createRequest({
      action: Actions.LINE_TOGGLE,
      method: "POST",
      url: ApiEndpoints.LINE_TOGGLE,
      params: { idDialModuleLine },
      data: {
        enabled: !isEnabled,
      },
    });

    const { errors } = parseResponse(request);

    if (errors.length > 0) {
      return;
    }

    toggleDialog(Dialogs.LINE_ENABLE, {});
    pushNotify(
      Notify.SUCCESS,
      `Line ${isEnabled ? "disabled" : "enabled"} successfully`
    );

    if (isDetailActive) {
      dispatch(Actions.LINE_OVERVIEW_FETCH, { idDialModuleLine });
    } else {
      dispatch(
        `${Modules.DATA_GRIDS}/${Actions.DATAGRID_FETCH_WITH_CURRENT_PARAMS}`,
        {
          dataGrid: DataGrids.LINES,
          fetchActionId: Actions.LINES_DATAGRID_FETCH,
        },
        { root: true }
      );
    }
  },

  [Actions.LINE_REMOVE_CALL]: (
    { dispatch },
    { id, idDialModuleLine, isDetailActive }
  ) => {
    dispatch(Actions.INSTANCE_REMOVE, {
      params: { id, idDialModuleLine },
      isDetailActive,
      endpoint: ApiEndpoints.LINE_QUEUE,
      action: Actions.LINE_REMOVE_CALL,
      redirectRoute: Routes.LINE_DETAIL_LINE_QUEUE,
      dataGrid: DataGrids.LINE_QUEUE,
      dataGridFetch: Actions.LINE_QUEUE_DATAGRID_FETCH,
      dialog: Dialogs.LINE_DELETE_CALL,
      prefix: "Calls",
    });
  },

  [Actions.LINE_SET_MODULE]: async (_, { idDialModule, idDialModuleLine }) => {
    const reqResponse = await createRequest({
      action: Actions.LINE_SET_MODULE,
      method: "POST",
      url: ApiEndpoints.LINE_SET_MODULE,
      params: { idDialModuleLine },
      data: {
        idDialModule,
      },
    });

    const { errors } = parseResponse(reqResponse);

    if (errors.length !== 0) {
      return;
    }

    toggleDialog(Dialogs.LINE_CHANGE_MODULE, {});
    pushNotify(Notify.SUCCESS, "Module changed successfully");
  },

  [Actions.LINE_MONITORING_FETCH]: async (
    { state, commit },
    { idDialModuleLine }
  ) => {
    const monitoringRes = await createRequest({
      action: Actions.LINE_MONITORING_FETCH,
      method: "GET",
      url: ApiEndpoints.LINE_MONITORING,
      params: { idDialModuleLine },
    });

    const { data, errors } = parseResponse(monitoringRes);

    if (errors.length === 0) {
      commit(Mutations.SET_INSPECTED_LINE, {
        ...state.inspectedLine,
        monitoring: data,
      });
    }
  },

  [Actions.LINE_STATISTICS_FETCH]: async (
    { state, commit },
    { idDialModuleLine, ...params }
  ) => {
    const statisticsRes = await createRequest({
      action: Actions.LINE_STATISTICS_FETCH,
      url: `${ApiEndpoints.LINE_STATISTICS}/${idDialModuleLine}`,
      method: "GET",
      params,
    });

    const { data, errors } = parseResponse(statisticsRes);

    if (errors.length === 0) {
      commit(Mutations.SET_INSPECTED_LINE, {
        ...state.inspectedLine,
        statistics: data,
      });
    }
  },

  [Actions.LINE_RECOMMENDED_MONITORING_FETCH]: async (_, { ...params }) => {
    const statisticsRes = await createRequest({
      action: Actions.LINE_RECOMMENDED_MONITORING_FETCH,
      url: `${ApiEndpoints.LINE_RECOMMENDED_MONITORING}`,
      method: "GET",
      params,
    });

    const { data, errors } = parseResponse(statisticsRes);

    if (errors.length !== 0) {
      pushNotify(Notify.ERROR, "Failed getting data!!!");
      return false;
    }
    return data;
  },

  [Actions.LINE_TARIFF_FETCH]: async (_, { idDialModuleLine, ...params }) => {
    const statisticsRes = await createRequest({
      action: Actions.LINE_TARIFF_FETCH,
      url: `${ApiEndpoints.LINE_TARIFF}`,
      method: "GET",
      params: { idDialModuleLine, ...params },
    });

    const { data, errors } = parseResponse(statisticsRes);

    if (errors.length !== 0) {
      pushNotify(Notify.ERROR, "Failed getting data!!!");
      return false;
    }
    return data;
  },
};

export default lineActions;
