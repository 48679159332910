import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";

const BotDeployServerRemoveDialog = Vue.extend({
  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    instanceData(): any {
      return this.GET_DIALOG_DATA(Dialogs.BOTDEPLOY_SERVER_REMOVE);
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.BOTDEPLOY_SERVER_REMOVE]),
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.BOTDEPLOY_SERVER_REMOVE}
        title="Confirmation"
        closeOnEsc
        actions={[
          {
            title: "Remove",
            icon: "mdi-delete",
            id: Actions.BOTDEPLOY_SERVER_REMOVE,
            action: () =>
              this.BOTDEPLOY_SERVER_REMOVE({
                name: this.instanceData.name,
                isDetailActive: !!this.$route.params.name,
              }),
          },
        ]}
      >
        Do you really want to remove "
        <strong class="primary--text">{this.instanceData.name}</strong>" server
        ?
      </Dialog>
    );
  },
});

export default BotDeployServerRemoveDialog;
