import Dialogs from "@/constants/dialogs";
import { Actions } from "@/models/store";
import Vue, { VNode } from "vue";
import BotDeployModuleCreateForm from "../BotDeployModuleCreateForm";
import Dialog from "../Dialog";

const BotDeployModuleCreateDialog = Vue.extend({
  render(): VNode {
    return (
      <Dialog
        id={Dialogs.BOTDEPLOY_MODULE_CREATE}
        title="New Module"
        actions={[
          {
            title: "Create",
            icon: "mdi-plus",
            action: () => {
              const refs: any = this.$refs;
              refs.form.handleSubmit();
            },
            id: Actions.BOTDEPLOY_MODULE_CREATE,
          },
        ]}
      >
        <BotDeployModuleCreateForm ref="form" />
      </Dialog>
    );
  },
});

export default BotDeployModuleCreateDialog;
