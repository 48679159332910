import RequestState from "@/models/api/RequestState";
import StringMap from "@/models/StringMap";

interface RequestResponse {
  result: string;
  data: StringMap;
}

interface ParsedResponse {
  errors: { [key: string]: string | number }[];
  data: any;
}

const parseResponse = (
  res: Promise<RequestResponse | RequestResponse[]>
): ParsedResponse => {
  const errors: { [key: string]: string | number }[] = [];
  const fetchedData: StringMap[] = [];

  if (Array.isArray(res)) {
    res.map(({ result, data }, index) => {
      if (result === RequestState.ERROR) {
        errors.push({ index, ...data });
        return;
      }

      if (result === RequestState.SUCCESS) {
        data ? fetchedData.push(data) : fetchedData.push({});
      }
    });

    return { errors, data: fetchedData };
  }

  const response: any = res;

  return response.result === RequestState.ERROR
    ? { errors: [response.data], data: {} }
    : { errors: [], data: response.data || {} };
};

export default parseResponse;
