import ApiEndpoints from "@/constants/apiEndpoints";
import Dropdowns from "@/constants/dropdowns";
import Vue, { VNode } from "vue";
import DynamicSelect from "../DynamicSelect";

const BotDeployConfigSelect = Vue.extend({
  props: {
    value: String,
  },

  render(): VNode {
    return (
      <DynamicSelect
        name={Dropdowns.BOTDEPLOY_CONFIGS}
        label="Configuration"
        fetchUrl={ApiEndpoints.BOTDEPLOY_CONFIGS_LIST}
        onInput={(val: string) => this.$emit("input", val)}
        value={this.value}
      />
    );
  },
});

export default BotDeployConfigSelect;
