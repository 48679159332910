import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import VueJsonEditor from "vue-json-editor";
import ApiEndpoints from "@/constants/apiEndpoints";
import Notify from "@/constants/notifications";
import pushNotify from "@/helpers/pushNotify";
import submitForm from "@/helpers/submitForm";
import toggleDialog from "@/helpers/toggleDialog";
import Dialogs from "@/constants/dialogs";

const TenantParametersEditForm = Vue.extend({
  data: () => ({
    parameters: {},
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_TENANT]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.TENANT_PARAMS_FETCH]),

    async handleSubmit(): Promise<void> {
      const { isFormSubmited } = await submitForm({
        actionId: Actions.TENANT_PARAMS_EDIT,
        values: { content: JSON.stringify(this.parameters) },
        url: ApiEndpoints.TENANT_PARAMETERS,
        params: { idTenant: this.$route.params.idTenant },
      });

      if (isFormSubmited) {
        pushNotify(Notify.SUCCESS, "Tenant parameters edited successfully.");
        toggleDialog(Dialogs.TENANT_EDIT_PARAMS, {});

        this.TENANT_PARAMS_FETCH({ idTenant: this.$route.params.idTenant });
      }
    },
  },

  created() {
    this.parameters = JSON.parse(this.INSPECTED_TENANT?.parameters?.content);
  },

  render(): VNode {
    return <VueJsonEditor vModel={this.parameters} mode="code" />;
  },
});

export default TenantParametersEditForm;
