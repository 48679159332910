import axios from "axios";
import store from "../store";
import { Modules, Mutations } from "@/models/store";
import RequestState from "@/models/api/RequestState";
import ApiRequestId from "@/models/api/ApiRequestId";
import ApiRequest from "@/models/api/ApiRequest";
import { getUser } from "@vocalls/sso-auth-service";

interface RequestResponse {
  result: string;
  data: never | { [key: string]: string };
}

const setRequestState = (
  action: string,
  actionState: string,
  id: ApiRequestId,
  meta?: any
) =>
  store.commit(`${Modules.API_CALLS}/${Mutations.SET_API_CALL_STATE}`, {
    action,
    actionState,
    id,
    meta,
  });

const createRequest = async ({
  action,
  id = null,
  url,
  headers,
  withoutAuthorization = false,
  ...requestSettings
}: ApiRequest): Promise<any> => {
  action ? setRequestState(action, RequestState.FETCHING, id) : null;

  const user = await getUser();

  return axios({
    ...requestSettings,
    url,
    headers: {
      ...(!withoutAuthorization && {
        Authorization: `Bearer ${user?.access_token}`,
      }),
      ...headers,
    },
  })
    .then(({ data }: never): RequestResponse => {
      action ? setRequestState(action, RequestState.SUCCESS, id) : null;
      return { result: RequestState.SUCCESS, data };
    })
    .catch((err: any): RequestResponse => {
      const errorDesc = {
        data: err.response?.data?.error,
        status: err.response?.status,
      };

      action
        ? setRequestState(action, RequestState.ERROR, id, errorDesc)
        : null;
      console.error(err);
      return { result: RequestState.ERROR, data: errorDesc };
    });
};

export default createRequest;
