import { Modules, Actions, Getters } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import BillingDashboard from "../BillingDashboard";

const TenantBilling = Vue.extend({
  props: {
    withTenantSelection: Boolean,
  },

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_TENANT]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.TENANT_BILLING_FETCH]),
  },

  render(): VNode {
    return (
      <BillingDashboard
        data={this.INSPECTED_TENANT?.billing ?? {}}
        fetchData={this.TENANT_BILLING_FETCH}
        fetchParams={{ idTenant: this.$route.params.idTenant }}
        fetchAction={Actions.TENANT_BILLING_FETCH}
        withTenantSelection={this.withTenantSelection}
      />
    );
  },
});

export default TenantBilling;
