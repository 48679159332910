import Vue, { VNode } from "vue";

const CampaignLink = Vue.extend({
  props: {
    title: String,
    routeName: String,
    routeParamName: String,
    routeParamValue: [Number, String],
  },

  computed: {
    formattedTitle(): string {
      return this.title.length > 38
        ? `${this.title.slice(0, 38)}...`
        : this.title;
    },
  },

  render(): VNode {
    return (
      <span>
        {this.title && this.routeParamValue && (
          <router-link
            class="tertiary--text"
            to={{
              name: this.routeName,
              params: {
                [this.routeParamName]: this.routeParamValue,
              },
            }}
          >
            {this.formattedTitle}
          </router-link>
        )}
        {this.title && !this.routeParamValue && (
          <span>{this.formattedTitle}</span>
        )}
        {!this.title && !this.routeParamValue && <span>-</span>}
      </span>
    );
  },
});

export default CampaignLink;
