import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { isRequired } from "@/helpers/validations";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VCombobox, VForm, VSelect, VTextField } from "vuetify/lib";
import Dialog from "../Dialog";
import { mapGetters, mapActions } from "vuex";

const NOTIFICATION_TYPES = [
  { text: "Email", value: 0 },
  { text: "Discord", value: 1 },
];

const TenantNotificationGroupDialog = Vue.extend({
  data: () => ({
    fields: {
      name: "",
      type: 0,
      options: [],
    },
    isFormValid: true,
    isEdit: true,
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_TENANT]),
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),
  },
  methods: {
    ...mapActions(Modules.INSTANCES, [
      Actions.TENANT_FETCH_NOTIFICATION_GROUPS,
    ]),

    async handleSubmit() {
      const { options, ...fields } = this.fields;

      const { isFormSubmited } = await submitForm({
        ref: this.$refs.form,
        url: ApiEndpoints.TENANT_NOTIFICATION_GROUP,
        values: {
          ...fields,
          options,
        },
        params: { idTenant: this.$route.params.idTenant },
        actionId: Actions.TENANT_CREATE_NOTIFICATION_GROUP,
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.TENANT_NOTIFICATION_GROUP, {});
        this.TENANT_FETCH_NOTIFICATION_GROUPS({
          idTenant: this.$route.params.idTenant,
        });
        pushNotify(Notify.SUCCESS, "Notification group added successfully");
      }
    },

    getInitData() {
      const notifyGroupIndex = this.GET_DIALOG_DATA(
        Dialogs.TENANT_NOTIFICATION_GROUP
      )?.index;
      this.isEdit = true;

      if (notifyGroupIndex === undefined) {
        this.isEdit = false;
        this.fields = {
          ...this.fields,
          name: "",
          type: 0,
          options: [],
        };
        return;
      }

      const { name, type, options } =
        this.INSPECTED_TENANT?.notifyGroups?.content?.[notifyGroupIndex];

      this.fields = {
        ...this.fields,
        name: name || "",
        type: type || 0,
        options: options || [],
      };
    },

    toggleHandler(opened: boolean) {
      opened ? this.getInitData() : null;
    },
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.TENANT_NOTIFICATION_GROUP}
        title="Notification group"
        toggleHandler={this.toggleHandler}
        actions={
          this.isEdit
            ? [
                {
                  title: "Apply",
                  icon: "mdi-check",
                  action: () => this.handleSubmit(),
                  id: Actions.TENANT_CREATE_NOTIFICATION_GROUP,
                },
              ]
            : [
                {
                  title: "Add",
                  icon: "mdi-plus",
                  action: () => this.handleSubmit(),
                  id: Actions.TENANT_CREATE_NOTIFICATION_GROUP,
                },
              ]
        }
      >
        <VForm ref="form" vModel={this.isFormValid}>
          <VTextField
            vModel={this.fields.name}
            label="Name"
            rules={[isRequired]}
          />
          <VSelect
            vModel={this.fields.type}
            label="Type"
            items={NOTIFICATION_TYPES}
          />
          <VCombobox
            vModel={this.fields.options}
            label="Contacts"
            smallChips
            deletableChips
            multiple
            delimiters={[";", " ", ","]}
            rules={[isRequired]}
          />
        </VForm>
      </Dialog>
    );
  },
});

export default TenantNotificationGroupDialog;
