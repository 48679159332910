import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";

const TenantNotificationGroupRemoveDialog = Vue.extend({
  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    instanceData(): any {
      return this.GET_DIALOG_DATA(Dialogs.TENANT_NOTIFICATION_GROUP_REMOVE);
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [
      Actions.TENANT_FETCH_NOTIFICATION_GROUPS,
      Actions.TENANT_REMOVE_NOTIFICATION_GROUP,
    ]),

    async fetchData() {
      this.TENANT_FETCH_NOTIFICATION_GROUPS({
        idTenant: this.$route.params.idTenant,
      });
    },

    async removeNotificationGroup() {
      await this.TENANT_REMOVE_NOTIFICATION_GROUP({
        idTenant: this.$route.params.idTenant,
        name: this.instanceData.name,
      });
      this.fetchData();
    },
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.TENANT_NOTIFICATION_GROUP_REMOVE}
        title="Confirmation"
        closeOnEsc
        actions={[
          {
            title: "Confirm",
            icon: "mdi-check",
            id: Actions.TENANT_REMOVE_NOTIFICATION_GROUP,
            action: () => this.removeNotificationGroup(),
          },
        ]}
      >
        Are you sure to remove notification group "
        <strong class="primary--text">{this.instanceData.name}</strong>" ?
      </Dialog>
    );
  },
});

export default TenantNotificationGroupRemoveDialog;
