const getSectionEditableValues = (values: any, source: any) => {
  const submitedFields = {};

  Object.keys(values).map((field) => {
    source.editableFields.includes(field)
      ? (submitedFields[field] = values[field])
      : null;
  });

  return submitedFields;
};

export default getSectionEditableValues;
