import Vue, { VNode } from "vue";
import DataGrid from "../DataGrid";
import DataGridConfig from "@/models/dataGrid/DataGridConfig";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import DataGrids from "@/constants/dataGrids";
import Routes from "@/constants/routes";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import { Namespaces, Permissions } from "@/constants/permissions";
import PermissionGroupCreateDialog from "../PermissionGroupCreateDialog";
import toggleDialog from "@/helpers/toggleDialog";
import Dialogs from "@/constants/dialogs";
import PermissionGroupRemoveDialog from "../PermissionGroupRemoveDialog";
import usePermissionGroupActions from "@/hooks/usePermissionGroupActions";

const PermissionGroupsDataGrid = Vue.extend({
  data: () => ({
    dataGridConfig: {},
  }),

  computed: {
    ...mapGetters(Modules.DATA_GRIDS, [Getters.GET_DATAGRID_DATA]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.DATA_GRIDS, [
      Actions.PERMISSION_GROUPS_DATAGRID_FETCH,
    ]),
  },

  created() {
    const dataGridConfig: DataGridConfig = {
      title: "Permission groups",
      isHeaderOutside: true,
      buttons: [
        {
          role: "primary",
          title: "New group",
          icon: "mdi-plus",
          action: () => toggleDialog(Dialogs.PERMISSION_GROUP_CREATE, {}),
          isAllowed: hasUserPermissions(Namespaces.ACCESS_MANAGEMENT, [
            Permissions.CREATE_ROLE,
          ]),
        },
      ],
      rowActions: (row) => ({
        primary: {
          route: {
            name: Routes.PERMISSION_GROUP_DETAIL_BASIC,
            params: { idRole: row.id },
          },
          icon: "mdi-cog",
          title: "Settings",
          isAllowed: hasUserPermissions(Namespaces.ACCESS_MANAGEMENT, [
            Permissions.VIEW_ROLE,
          ]),
        },
        secondary: usePermissionGroupActions(row),
      }),
      headers: [{ text: "Name", value: "name" }],
      filters: [{ name: "name" }, { name: "language" }],
    };

    this.dataGridConfig = dataGridConfig;
  },

  render(): VNode {
    return (
      <div>
        <DataGrid
          fetchData={this.PERMISSION_GROUPS_DATAGRID_FETCH}
          data={this.GET_DATAGRID_DATA(DataGrids.PERMISSION_GROUPS)}
          configuration={this.dataGridConfig}
          isFetching={this.IS_ACTION_FETCHING(
            Actions.PERMISSION_GROUPS_DATAGRID_FETCH
          )}
        />
        <PermissionGroupCreateDialog />
        <PermissionGroupRemoveDialog />
      </div>
    );
  },
});

export default PermissionGroupsDataGrid;
