import Vue, { VNode } from "vue";
import { Actions, Modules } from "@/models/store";
import { mapActions } from "vuex";
import Dialogs from "@/constants/dialogs";
import Dialog from "../Dialog";
import {
  VCardTitle,
  VChip,
  VDivider,
  VList,
  VListItem,
  VSkeletonLoader,
} from "vuetify/lib";

const CampaignValidationDialog = Vue.extend({
  props: {
    idCampaign: String,
  },

  data: () => ({
    validationData: {
      callScriptVariables: [],
      errors: [],
      isValid: false,
      warnings: [],
    },
    isLoading: false,
  }),

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.CAMPAIGN_VALIDATION_FETCH]),

    async fetchValidationData(idCampaign: string): Promise<any> {
      this.isLoading = true;
      const data = await this.CAMPAIGN_VALIDATION_FETCH({ idCampaign });

      if (data) {
        this.validationData = data;
        this.isLoading = false;
      }
    },
    toggleHandler(opened: boolean) {
      opened ? this.fetchValidationData(this.idCampaign) : null;
    },
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.CAMPAIGN_VALIDATION_DIALOG}
        title="Campaign Validation:"
        class="mb-8 v-scroll-y-transition"
        toggleHandler={this.toggleHandler}
        closOnEsc
        noCancelBtn={true}
      >
        <VCardTitle>Variables:</VCardTitle>
        <VList class="d-flex">
          {this.validationData.callScriptVariables.length !== 0 ? (
            this.validationData.callScriptVariables.map((callScript) => (
              <VChip class="ml-2 mr-2 mb-4 elevation-5">{callScript}</VChip>
            ))
          ) : this.isLoading ? (
            <VSkeletonLoader class="ml-2 mr-2 mb-4" type="chip" />
          ) : (
            <VListItem>No variables available !!!</VListItem>
          )}
        </VList>
        <VDivider />
        <VCardTitle>Errors:</VCardTitle>
        <VList class="d-flex flex-column overflow-y-auto">
          {this.validationData.errors.length !== 0 ? (
            this.validationData.errors.map((error) => (
              <VChip
                class="ml-2 mr-6 mb-4 elevation-5"
                style="border: solid 2px red; width: fit-content; background: white;"
              >
                {"❌ " + error}
              </VChip>
            ))
          ) : this.isLoading ? (
            <VSkeletonLoader class="ml-2 mr-2 mb-4" type="chip" />
          ) : (
            <VListItem>No errors available !!!</VListItem>
          )}
        </VList>
        <VDivider />
        <VCardTitle>Warnings:</VCardTitle>
        <VList class="d-flex flex-column overflow-y-auto">
          {this.validationData.warnings.length !== 0 ? (
            this.validationData.warnings.map((warning) => (
              <VChip
                class="ml-2 mr-6 mb-4 elevation-5"
                style="border: solid 2px orange; width: fit-content; background: white;"
              >
                {"⚠️ " + warning}
              </VChip>
            ))
          ) : this.isLoading ? (
            <VSkeletonLoader class="ml-2 mr-2 mb-4" type="chip" />
          ) : (
            <VListItem>No warnings available !!!</VListItem>
          )}
        </VList>
        <VDivider />
        <VCardTitle>
          Validation:{" "}
          {this.validationData.isValid ? (
            <VChip class="green white--text ml-2 elevation-5">SUCCESSFUL</VChip>
          ) : (
            <VChip class="red white--text ml-2 elevation-5">FAILED</VChip>
          )}
        </VCardTitle>
        <VDivider />
      </Dialog>
    );
  },
});

export default CampaignValidationDialog;
