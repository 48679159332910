import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import pushNotify from "@/helpers/pushNotify";
import submitForm from "@/helpers/submitForm";
import toggleDialog from "@/helpers/toggleDialog";
import { isRequired } from "@/helpers/validations";
import { Actions, Modules } from "@/models/store";
import Vue, { PropType, VNode } from "vue";
import {
  VForm,
  VCard,
  VCardText,
  VSelect,
  VTextField,
  VBtn,
  VIcon,
} from "vuetify/lib";
import { mapActions } from "vuex";

const EMPTY_VOICE = { code: "", tts: "" };

const LanguageVoiceGroupsForm = Vue.extend({
  data: () => ({
    voiceEngines: ["Google", "Microsoft"],
    voiceGroups: [{ engine: "Google", voices: [{ ...EMPTY_VOICE }] }],
  }),

  props: {
    data: Array as PropType<any[]>,
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.LANGUAGE_OVERVIEW_FETCH]),

    addVoiceGroup(): void {
      this.voiceGroups.push({ engine: "", voices: [{ ...EMPTY_VOICE }] });
    },

    addVoice(groupIndex: number): void {
      this.voiceGroups[groupIndex].voices.push({ ...EMPTY_VOICE });
    },

    removeVoice(groupIndex: number, voiceIndex: number): void {
      this.voiceGroups[groupIndex].voices.splice(voiceIndex, 1);

      if (this.voiceGroups[groupIndex].voices.length === 0) {
        this.voiceGroups.splice(groupIndex, 1);
      }
    },

    getValues(): any {
      return this.voiceGroups;
    },

    validate(): boolean {
      const refs: any = this.$refs;
      return refs.form.validate();
    },

    async handleSubmit(): Promise<void> {
      const refs: any = this.$refs;

      const { isFormSubmited } = await submitForm({
        actionId: Actions.LANGUAGE_OVERVIEW_EDIT,
        ref: refs.form,
        values: { settings: this.voiceGroups },
        url: ApiEndpoints.LANGUAGE_OVERVIEW,
        params: { idLanguage: this.$route.params.idLanguage },
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.LANGUAGE_EDIT_BASIC, {});
        pushNotify(Notify.SUCCESS, "Language edited successfully");

        this.LANGUAGE_OVERVIEW_FETCH({
          idLanguage: this.$route.params.idLanguage,
        });
      }
    },
  },

  created() {
    this.data ? (this.voiceGroups = this.data) : null;
  },

  render(): VNode {
    return (
      <VForm ref="form">
        {this.voiceGroups.map(({ voices }, groupIndex) => (
          <VCard class="mb-5">
            <VCardText>
              <VSelect
                vModel={this.voiceGroups[groupIndex].engine}
                rules={[isRequired]}
                label="Engine"
                items={this.voiceEngines}
              />
              {voices.map((_voice, voiceIndex) => (
                <div class="d-flex align-center">
                  <VTextField
                    vModel={
                      this.voiceGroups[groupIndex].voices[voiceIndex].code
                    }
                    rules={[isRequired]}
                    label="Language code"
                    class="mr-5"
                  />
                  <VTextField
                    vModel={this.voiceGroups[groupIndex].voices[voiceIndex].tts}
                    rules={[isRequired]}
                    label="TTS voice name"
                    class="mr-5"
                  />
                  <VBtn
                    onClick={() => this.removeVoice(groupIndex, voiceIndex)}
                    xSmall
                    fab
                    class="error"
                  >
                    <VIcon>mdi-delete</VIcon>
                  </VBtn>
                </div>
              ))}

              <VBtn
                onClick={() => this.addVoice(groupIndex)}
                small
                class="secondary primary--text"
              >
                <VIcon left>mdi-plus</VIcon> Add voice
              </VBtn>
            </VCardText>
          </VCard>
        ))}
        {this.voiceGroups.length < this.voiceEngines.length && (
          <VBtn onClick={this.addVoiceGroup} class="tertiary white--text mt-5">
            <VIcon left>mdi-plus</VIcon>Add voice group
          </VBtn>
        )}
      </VForm>
    );
  },
});

export default LanguageVoiceGroupsForm;
