import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";

const BotDeployModuleRemoveDialog = Vue.extend({
  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    instanceData(): any {
      return this.GET_DIALOG_DATA(Dialogs.BOTDEPLOY_MODULE_REMOVE);
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.BOTDEPLOY_MODULE_REMOVE]),
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.BOTDEPLOY_MODULE_REMOVE}
        title="Confirmation"
        closeOnEsc
        actions={[
          {
            title: "Remove",
            icon: "mdi-delete",
            id: Actions.BOTDEPLOY_MODULE_REMOVE,
            action: () =>
              this.BOTDEPLOY_MODULE_REMOVE({
                name: this.instanceData.name,
                server: this.instanceData.server,
                isDetailActive: !!this.$route.params.moduleName,
              }),
          },
        ]}
      >
        Do you really want to remove module "
        <strong class="primary--text">{this.instanceData.name}</strong>" from
        server "
        <strong class="primary--text">{this.instanceData.server}</strong>" ?
      </Dialog>
    );
  },
});

export default BotDeployModuleRemoveDialog;
