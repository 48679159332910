import {
  formatDateForDatePicker,
  getFirstDayCurrentMonth,
  getLastDayCurrentMonth,
} from "@/helpers/dateAndTimeUtils";
import { Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import {
  VCard,
  VCardText,
  VCardTitle,
  VDivider,
  VSkeletonLoader,
  VSpacer,
} from "vuetify/lib";
import { mapGetters } from "vuex";
import BillingControlPanel from "./BillingControlPanel";
import BillingLineDataGrid from "./BillingLineDataGrid";
import BillingSummaryDataGrid from "./BillingSummaryDataGrid";

const BillingDashboard = Vue.extend({
  props: {
    data: Object,
    fetchData: Function,
    fetchParams: Object,
    fetchAction: String,
    withTenantSelection: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    defaultFilters: {
      from: formatDateForDatePicker(getFirstDayCurrentMonth()),
      to: formatDateForDatePicker(getLastDayCurrentMonth()),
      roundInterval: "60",
      idTenant: "",
    },
    menus: {
      from: false,
      to: false,
    },
  }),

  computed: {
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    async fetchStatistics(filters?: any) {
      const refs: any = this.$refs;
      this.$router.replace({ query: filters }).catch(() => null);

      const fetchParams = this.withTenantSelection
        ? { ...this.fetchParams, ...filters }
        : { ...filters, ...this.fetchParams };

      await this.fetchData({
        ...fetchParams,
        ...(this.withTenantSelection &&
          !filters.idTenant && {
            idTenant: refs?.controlPanel?.getTenantFilter(),
          }),
      });
    },
  },

  created() {
    this.defaultFilters = { ...this.defaultFilters, ...this.$route.query };
    this.withTenantSelection && !this.defaultFilters.idTenant
      ? null
      : this.fetchStatistics(this.defaultFilters);
  },

  render(): VNode {
    return (
      <div class="tenant-billing">
        <VCard elevation={0}>
          {!this.withTenantSelection && (
            <VCardTitle class="v-toolbar__title primary--text font-weight-bold text-h5 mb-5">
              Billing
              <VSpacer />
            </VCardTitle>
          )}

          <VCardText>
            <BillingControlPanel
              ref="controlPanel"
              fetchStatistics={this.fetchStatistics}
              fetchParams={this.fetchParams}
              defaultFilters={this.defaultFilters}
              withTenantSelection={this.withTenantSelection}
            />
          </VCardText>
        </VCard>

        {this.IS_ACTION_FETCHING(this.fetchAction) ? (
          <VSkeletonLoader type="table" />
        ) : (
          <div>
            {JSON.stringify(this.data) !== "{}" && (
              <div>
                <VCard elevation={0}>
                  <VCardTitle class="primary--text text-h5 font-weight-bold mb-4 mt-8">
                    Summary
                  </VCardTitle>
                  <VCardText>
                    <BillingSummaryDataGrid summary={this.data?.summary} />
                  </VCardText>
                </VCard>
                {this.data?.modules?.length > 0 && (
                  <div>
                    <VDivider class="mt-10 mb-3" />
                    <VCard elevation={0}>
                      <VCardTitle class="primary--text text-h5 font-weight-bold mb-4 mt-8">
                        Lines Statistics
                      </VCardTitle>
                      <VCardText>
                        {this.data?.modules.map(({ module, lines }: any) => (
                          <BillingLineDataGrid
                            moduleName={module}
                            lines={lines}
                          />
                        ))}
                      </VCardText>
                    </VCard>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  },
});

export default BillingDashboard;
