import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import {
  VCard,
  VCardText,
  VDivider,
  VSpacer,
  VToolbar,
  VToolbarTitle,
} from "vuetify/lib";
import { mapGetters } from "vuex";
import VueJsonEditor from "vue-json-editor";
import EditableOverviewSection from "../EditableOverviewSection";
import BotDeployConfigEditForm from "../BotDeployConfigEditForm";

const BotDeployConfigOverview = Vue.extend({
  data: () => ({
    basic: {
      title: "Overview",
      columns: [{ name: "name", title: "Name" }],
    },
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_BOTDEPLOY_CONFIG]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),

    jsonConfig(): string[] {
      return (
        this.INSPECTED_BOTDEPLOY_CONFIG?.basic?.content?.configuration || {}
      );
    },
  },

  render(): VNode {
    return (
      <div>
        <EditableOverviewSection
          configuration={this.basic}
          data={this.INSPECTED_BOTDEPLOY_CONFIG?.basic}
          editDialog={Dialogs.BOTDEPLOY_CONFIG_EDIT_BASIC}
          editDialogWidth={1000}
          action={Actions.BOTDEPLOY_CONFIG_OVERVIEW_EDIT}
          scopedSlots={{
            form: () => <BotDeployConfigEditForm ref="form" />,
          }}
          isDataFetching={this.IS_ACTION_FETCHING(
            Actions.BOTDEPLOY_CONFIG_OVERVIEW_FETCH
          )}
        />
        <VDivider />
        <VCard flat>
          <VToolbar flat>
            <VToolbarTitle
              class="primary--text font-weight-bold text-h5"
              inset
              vertical
            >
              Configuration JSON
            </VToolbarTitle>
            <VSpacer />
          </VToolbar>
          <VCardText class="pt-0">
            <VueJsonEditor
              vModel={this.jsonConfig}
              mode="view"
              expandedOnStart={true}
            />
          </VCardText>
        </VCard>
      </div>
    );
  },
});

export default BotDeployConfigOverview;
