import store from "@/store";
import { Modules, Actions } from "@/models/store";

const pushNotify = (type: string, text: string): void => {
  store.dispatch(`${Modules.NOTIFICATION}/${Actions.PUSH_NOTIFICATION}`, {
    text,
    type,
  });
};

export default pushNotify;
