const TARIFF_TYPES = [
  {
    text: "per Call",
    value: 0,
  },
  {
    text: "per Time",
    value: 1,
  },
];

export default TARIFF_TYPES;
