import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import Routes from "@/constants/routes";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { isRequired } from "@/helpers/validations";
import { Actions } from "@/models/store";
import Vue, { VNode } from "vue";
import { VBtn, VForm, VSwitch, VTextField } from "vuetify/lib";
import BotDeployServerSelect from "../BotDeployServerSelect";
import BotDeployConfigSelect from "../BotDeployConfigSelect";

const BotDeployModuleCreateForm = Vue.extend({
  data: () => ({
    fields: {
      name: "",
      server: "",
      version: "",
      enabled: true,
      configuration: "",
      ipA: "",
      ipB: "",
    },
    isFormValid: true,
  }),

  methods: {
    async handleSubmit() {
      const { isFormSubmited } = await submitForm({
        ref: this.$refs.form,
        url: ApiEndpoints.BOTDEPLOY_MODULE_BASIC,
        values: { ...this.fields, count: this.fields.enabled ? 1 : 0 },
        actionId: Actions.BOTDEPLOY_MODULE_CREATE,
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.BOTDEPLOY_MODULE_CREATE, {});

        this.$router.push({
          name: Routes.BOTDEPLOY_MODULE_DETAIL_BASIC,
          params: {
            moduleName: this.fields.name,
            serverName: this.fields.server,
          },
        });

        pushNotify(Notify.SUCCESS, "Module created successfully");
      }
    },
  },

  render(): VNode {
    return (
      <VForm
        vModel={this.isFormValid}
        ref="form"
        onSubmit={(e: Event) => {
          e.preventDefault();
          this.handleSubmit();
        }}
      >
        <VTextField
          vModel={this.fields.name}
          rules={[isRequired]}
          label="Name"
        />
        <VTextField
          vModel={this.fields.version}
          rules={[isRequired]}
          label="Version"
        />

        <BotDeployServerSelect vModel={this.fields.server} />
        <BotDeployConfigSelect vModel={this.fields.configuration} />

        <VTextField vModel={this.fields.ipA} label="IP A" />

        <VTextField vModel={this.fields.ipB} label="IP B" />

        <VSwitch vModel={this.fields.enabled} label="Enabled" />
        <VBtn type="submit" class="d-none"></VBtn>
      </VForm>
    );
  },
});

export default BotDeployModuleCreateForm;
