import Notify from "@/constants/notifications";
import { Getters, Modules, Mutations } from "@/models/store";
import Vue, { VNode } from "vue";
import { VSnackbar, VIcon, VBtn } from "vuetify/lib";
import { mapGetters, mapMutations } from "vuex";

const Notification = Vue.extend({
  computed: {
    ...mapGetters(Modules.NOTIFICATION, [Getters.NOTIFICATION_OPTIONS]),

    isActive: {
      get(): boolean {
        return this.NOTIFICATION_OPTIONS.isActive;
      },

      set() {
        this.TOGGLE_NOTIFICATION();
      },
    },

    iconType() {
      const type = this.NOTIFICATION_OPTIONS.type;

      switch (type) {
        case Notify.SUCCESS:
          return "mdi-check-circle";
        case Notify.ERROR:
          return "mdi-alert-octagon";
        case Notify.WARNING:
          return "mdi-alert";
        default:
          return "mdi-information";
      }
    },
  },

  methods: {
    ...mapMutations(Modules.NOTIFICATION, [Mutations.TOGGLE_NOTIFICATION]),
  },

  render(): VNode {
    const notification = this.NOTIFICATION_OPTIONS;

    return (
      <VSnackbar
        vModel={this.isActive}
        multiLine
        top
        right
        color={notification.type}
        rounded
        timeout="5000"
        scopedSlots={{
          action: ({ attrs }: any) => (
            <VBtn
              onClick={this.TOGGLE_NOTIFICATION}
              color="tertiary"
              class="white"
              text
              fab
              xSmall
              {...attrs}
            >
              <VIcon small>mdi-close</VIcon>
            </VBtn>
          ),
        }}
      >
        <span class="font-weight-bold">{notification.text}</span>
      </VSnackbar>
    );
  },
});

export default Notification;
