import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import getSectionEditableValues from "@/helpers/getSectionEditableValues";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { isRequired } from "@/helpers/validations";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VForm, VSwitch, VTextField } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";

const Fields = {
  invoicingName: "invoicingName",
  companyNumber: "companyNumber",
  vatNumber: "vatNumber",
  address: "address",
  isCompany: "isCompany",
};

const TenantOverviewEditForm = Vue.extend({
  data: () => ({
    fields: {},
    isValid: true,
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_TENANT]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.TENANT_OVERVIEW_FETCH]),

    isEditable(name: string): boolean {
      return this.INSPECTED_TENANT.basic.editableFields.includes(name);
    },

    async handleSubmit(): Promise<void> {
      const refs: any = this.$refs;
      const submitedFields = getSectionEditableValues(
        this.fields,
        this.INSPECTED_TENANT.basic
      );

      const { isFormSubmited } = await submitForm({
        actionId: Actions.TENANT_OVERVIEW_EDIT,
        ref: refs.form,
        values: submitedFields,
        url: ApiEndpoints.TENANT_BASIC,
        params: { idTenant: this.$route.params.idTenant },
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.TENANT_EDIT_BASIC, {});
        pushNotify(Notify.SUCCESS, "Flow edited successfully");

        this.TENANT_OVERVIEW_FETCH({
          idTenant: this.$route.params.idTenant,
        });
      }
    },
  },

  created() {
    this.fields = { ...this.INSPECTED_TENANT.basic.content };
  },

  render(): VNode {
    const fields: any = this.fields;

    return (
      <VForm vModel={this.isValid} ref="form">
        <VTextField
          vModel={fields[Fields.invoicingName]}
          label="Invoicing name"
          rules={[isRequired]}
          disabled={!this.isEditable(Fields.invoicingName)}
        />
        <VTextField
          vModel={fields[Fields.companyNumber]}
          label="Company number"
          disabled={!this.isEditable(Fields.companyNumber)}
        />
        <VTextField
          vModel={fields[Fields.vatNumber]}
          label="Vat number"
          disabled={!this.isEditable(Fields.vatNumber)}
        />
        <VTextField
          vModel={fields[Fields.address]}
          label="Address"
          disabled={!this.isEditable(Fields.address)}
        />
        <VSwitch
          vModel={fields[Fields.isCompany]}
          label="Tenant is a company"
          disabled={!this.isEditable(Fields.isCompany)}
        />
      </VForm>
    );
  },
});

export default TenantOverviewEditForm;
