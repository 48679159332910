import Vue from "vue";
import { MutationTree } from "vuex";
import { Mutations } from "@/models/store";
import ApiCallsState from "@/models/store/ApiCallsState";

const mutations: MutationTree<ApiCallsState> = {
  [Mutations.SET_API_CALL_STATE]: (
    state,
    { action, actionState, id, meta = {} }
  ) => {
    if (!state[action]) {
      return null;
    }

    if (id) {
      const actionId = state[action].findIndex(
        (currentAction) => currentAction.id === id
      );

      actionId !== -1
        ? Vue.set(state[action], actionId, { id, state: actionState, meta })
        : state[action].push({
            id,
            state: actionState,
          });
    } else {
      Vue.set(state[action], 0, { id: 0, state: actionState, meta });
    }
  },
};

export default mutations;
