import Dialogs from "@/constants/dialogs";
import toggleDialog from "@/helpers/toggleDialog";
import LanguageActionsParams from "@/models/actions/LanguageActionsParams";
import ActionHandler from "@/models/actions/ActionHandler";
import { Namespaces, Permissions } from "@/constants/permissions";
import hasUserPermissions from "@/helpers/hasUserPermissions";

const useLanguageActions = ({
  idLanguage,
  name,
}: LanguageActionsParams): ActionHandler[] => [
  {
    icon: "mdi-delete",
    title: "Remove",
    action: () => toggleDialog(Dialogs.LANGUAGE_REMOVE, { idLanguage, name }),
    isAllowed: hasUserPermissions(Namespaces.LANGUAGE, [Permissions.DELETE]),
  },
];

export default useLanguageActions;
