import ApiCallsState from "@/models/store/ApiCallsState";
import { Actions } from "@/models/store";

const state: ApiCallsState = {
  [Actions.MODULES_DATAGRID_FETCH]: [],
  [Actions.LINES_DATAGRID_FETCH]: [],
  [Actions.DIAL_LOGS_DATAGRID_FETCH]: [],
  [Actions.LINE_QUEUE_DATAGRID_FETCH]: [],
  [Actions.MODULE_OVERVIEW_FETCH]: [],
  [Actions.MODULE_CALLBOT_CONFIG_FETCH]: [],
  [Actions.AUDIO_RECORD_FETCH]: [],
  [Actions.LINE_OVERVIEW_FETCH]: [],
  [Actions.CALLSCRIPTS_DATAGRID_FETCH]: [],
  [Actions.MODULE_REMOVE]: [],
  [Actions.GENERATE_CSV_REPORTS]: [],
  [Actions.LINE_SET_MODULE]: [],
  [Actions.FLOW_OVERVIEW_FETCH]: [],
  [Actions.FLOW_VERSION_HISTORY_DATAGRID_FETCH]: [],
  [Actions.DIAL_LOG_FETCH]: [],
  [Actions.LINE_REMOVE_CALL]: [],
  [Actions.FLOW_SWITCH_VERSION]: [],
  [Actions.FLOW_VERSION_DIFF]: [],
  [Actions.FLOW_DUPLICATE]: [],
  [Actions.FLOW_REMOVE]: [],
  [Actions.FLOW_OVERVIEW_EDIT]: [],
  [Actions.MODULE_OVERVIEW_EDIT]: [],
  [Actions.MODULE_CALLBOT_SETTINGS_EDIT]: [],
  [Actions.LINE_OVERVIEW_EDIT]: [],
  [Actions.FLOW_CREATE]: [],
  [Actions.LANGUAGES_FETCH]: [],
  [Actions.LINE_TOGGLE]: [],
  [Actions.LINE_CREATE]: [],
  [Actions.LINE_REMOVE]: [],
  [Actions.LINE_TARIFF_EDIT]: [],
  [Actions.LINE_CLEANING_SETTINGS_EDIT]: [],
  [Actions.MODULE_SET_DEFAULT_LINE]: [],
  [Actions.CAMPAIGNS_DATAGRID_FETCH]: [],
  [Actions.GRAMMARS_DATAGRID_FETCH]: [],
  [Actions.GRAMMAR_OVERVIEW_FETCH]: [],
  [Actions.TTS_GENERATOR]: [],
  [Actions.SET_FAVORITE_ITEM]: [],
  [Actions.USERS_DATAGRID_FETCH]: [],
  [Actions.TENANTS_DATAGRID_FETCH]: [],
  [Actions.TENANT_TARRIFS_DATAGRID_FETCH]: [],
  [Actions.TENANT_CREATE]: [],
  [Actions.TENANT_FORCE_TWO_FACTOR]: [],
  [Actions.USER_TWO_FACTOR_RESET]: [],
  [Actions.GRAMMAR_CREATE]: [],
  [Actions.GRAMMAR_DUPLICATE]: [],
  [Actions.GRAMMAR_REMOVE]: [],
  [Actions.GRAMMAR_OVERVIEW_EDIT]: [],
  [Actions.TENANT_OVERVIEW_FETCH]: [],
  [Actions.USER_OVERVIEW_FETCH]: [],
  [Actions.TENANT_USER_CREATE]: [],
  [Actions.USER_ROLES_FETCH]: [],
  [Actions.TENANT_CREATE_NOTIFICATION_GROUP]: [],
  [Actions.AUDIT_DATAGRID_FETCH]: [],
  [Actions.USER_REMOVE]: [],
  [Actions.TENANT_REMOVE]: [],
  [Actions.FETCH_USER_INFO]: [],
  [Actions.USER_ACCOUNTS_FETCH]: [],
  [Actions.USER_TWO_FACTOR_FETCH]: [],
  [Actions.USER_TWO_FACTOR_CHANGE]: [],
  [Actions.LINE_CALLBOT_CONFIG_EDIT]: [],
  [Actions.LINE_CALLBOT_CONFIG_FETCH]: [],
  [Actions.PERMISSION_GROUPS_DATAGRID_FETCH]: [],
  [Actions.USER_PASSWORD_CHANGE]: [],
  [Actions.PERMISSION_GROUP_CREATE]: [],
  [Actions.PERMISSION_GROUP_OVERVIEW_FETCH]: [],
  [Actions.PERMISSION_GROUP_REMOVE]: [],
  [Actions.PERMISSIONS_FETCH]: [],
  [Actions.PERMISSIONS_GROUPS_FETCH]: [],
  [Actions.PERMISSION_GROUP_OVERVIEW_EDIT]: [],
  [Actions.USER_API_SECRET_FETCH]: [],
  [Actions.USER_API_SECRET_GENERATE]: [],
  [Actions.USER_API_SECRET_REMOVE]: [],
  [Actions.PERMISSION_GROUP_PERMISSIONS_EDIT]: [],
  [Actions.LINE_MONITORING_EDIT]: [],
  [Actions.LINE_MONITORING_FETCH]: [],
  [Actions.PACKAGE_CREATE]: [],
  [Actions.PACKAGE_INSTALL]: [],
  [Actions.TENANT_OVERVIEW_EDIT]: [],
  [Actions.TENANT_PARAMS_EDIT]: [],
  [Actions.USER_OVERVIEW_EDIT]: [],
  [Actions.TENANT_STATISTICS_FETCH]: [],
  [Actions.TENANT_BILLING_FETCH]: [],
  [Actions.LINE_STATISTICS_FETCH]: [],
  [Actions.CAMPAIGN_OVERVIEW_FETCH]: [],
  [Actions.CAMPAIGN_CREATE]: [],
  [Actions.CAMPAIGN_IMPORT_CONTACTS]: [],
  [Actions.CAMPAIGN_SCHEDULE_EDIT]: [],
  [Actions.CAMPAIGN_EDIT]: [],
  [Actions.CAMPAIGN_REMOVE]: [],
  [Actions.CAMPAIGN_STATE_CHANGE]: [],
  [Actions.CAMPAIGN_PREPARE]: [],
  [Actions.BOTDEPLOY_MODULES_DATAGRID_FETCH]: [],
  [Actions.BOTDEPLOY_MODULE_OVERVIEW_FETCH]: [],
  [Actions.BOTDEPLOY_MODULE_OVERVIEW_EDIT]: [],
  [Actions.BOTDEPLOY_SERVERS_DATAGRID_FETCH]: [],
  [Actions.BOTDEPLOY_SERVER_OVERVIEW_FETCH]: [],
  [Actions.BOTDEPLOY_CONFIGS_DATAGRID_FETCH]: [],
  [Actions.BOTDEPLOY_CONFIG_OVERVIEW_FETCH]: [],
  [Actions.LANGUAGES_DATAGRID_FETCH]: [],
  [Actions.LANGUAGE_CREATE]: [],
  [Actions.LANGUAGE_OVERVIEW_FETCH]: [],
  [Actions.LANGUAGE_OVERVIEW_EDIT]: [],
  [Actions.LANGUAGE_REMOVE]: [],
  [Actions.LINE_CLEANING_SETTINGS_FETCH]: [],
};

export default state;
