import ExternalLinks from "@/constants/externalLinks";
import { Namespaces, Permissions } from "@/constants/permissions";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import {
  VCard,
  VCardText,
  VCol,
  VIcon,
  VImg,
  VRow,
  VSkeletonLoader,
} from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import SectionToolbar from "../SectionToolbar";

const UserTwoFactorAuthentication = Vue.extend({
  props: {
    idUser: String,
    isLoggedUser: Boolean,
  },

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_USER]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),

    twoFactorData(): any {
      return this.INSPECTED_USER.twoFactor;
    },

    areActionsHidden(): boolean {
      return (
        this.IS_ACTION_FETCHING(Actions.USER_TWO_FACTOR_CHANGE) ||
        this.IS_ACTION_FETCHING(Actions.USER_TWO_FACTOR_FETCH) ||
        this.IS_ACTION_FETCHING(Actions.USER_TWO_FACTOR_RESET) ||
        (this.$route.params.idUser &&
          !hasUserPermissions(Namespaces.ACCESS_MANAGEMENT, [
            Permissions.EDIT_ACCOUNT,
          ]))
      );
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [
      Actions.USER_TWO_FACTOR_FETCH,
      Actions.USER_TWO_FACTOR_CHANGE,
      Actions.USER_TWO_FACTOR_RESET,
    ]),
  },

  created() {
    this.USER_TWO_FACTOR_FETCH({ idUser: this.idUser });
  },

  render(): VNode {
    return (
      <div>
        <SectionToolbar
          title="Two-factor authentication"
          actions={[
            (this.twoFactorData?.enable || this.twoFactorData?.requested) &&
            !this.isLoggedUser
              ? {
                  icon: "mdi-restart",
                  action: () =>
                    this.USER_TWO_FACTOR_RESET({
                      idUser: this.idUser,
                    }),
                  disabled: this.areActionsHidden,
                }
              : null,
            {
              color:
                this.twoFactorData?.enable || this.twoFactorData?.requested
                  ? "error"
                  : "tertiary",
              icon:
                this.twoFactorData?.enable || this.twoFactorData?.requested
                  ? "mdi-delete"
                  : "mdi-power",
              action: () =>
                this.USER_TWO_FACTOR_CHANGE({
                  idUser: this.idUser,
                  enable: !(
                    this.twoFactorData?.enable || this.twoFactorData?.requested
                  ),
                  isLoggedUser: this.isLoggedUser,
                }),
              disabled: this.areActionsHidden,
            },
          ]}
        />

        <VCard flat>
          <VCardText class="pt-3">
            {this.IS_ACTION_FETCHING(Actions.USER_TWO_FACTOR_FETCH) ||
            this.IS_ACTION_FETCHING(Actions.USER_TWO_FACTOR_CHANGE) ? (
              <VSkeletonLoader type="list-item" />
            ) : (
              <div>
                {this.twoFactorData?.enable || this.twoFactorData?.requested ? (
                  <div>
                    <VRow>
                      <VCol cols={8}>
                        Two factor authentication is{" "}
                        <span class="d-inline-block success--text font-weight-bold mb-3">
                          {this.twoFactorData?.enable ? "enabled" : "requested"}
                        </span>
                        .
                        {!this.$route.params.idUser && (
                          <ul>
                            <li class="pb-1">
                              It is necessary to download Google authenticator
                              app (<VIcon>mdi-google</VIcon>
                              <a
                                href={ExternalLinks.AUTHENTICATOR_GOOGLE_PLAY}
                                target="_blank"
                                class="tertiary--text"
                              >
                                Google play
                              </a>{" "}
                              or <VIcon>mdi-apple</VIcon>
                              <a
                                href={ExternalLinks.AUTHENTICATOR_APPLE_STORE}
                                target="_blank"
                                class="tertiary--text"
                              >
                                Apple store
                              </a>
                              ) and scan QR code from which appears on the
                              screen.
                            </li>
                            <li>
                              After finishing this setting you will always log
                              in to the administration like usual. Second step
                              will be entering a one-time code from your phone.
                              This code is one-time and is valid for 30 seconds.
                            </li>
                          </ul>
                        )}
                      </VCol>
                      {!this.$route.params.idUser && (
                        <VCol cols={4}>
                          <VImg
                            width={200}
                            src={this.twoFactorData?.qrUrl}
                            class="mx-auto"
                          />
                        </VCol>
                      )}
                    </VRow>
                  </div>
                ) : (
                  <span>
                    Two factor authentication is{" "}
                    <strong class="warning--text">disabled</strong>.
                  </span>
                )}
              </div>
            )}
          </VCardText>
        </VCard>
      </div>
    );
  },
});

export default UserTwoFactorAuthentication;
