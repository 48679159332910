import Vue from "vue";
import VueRouter from "vue-router";
import { setPageTitle, checkAccessPermissions } from "./navigationGuards";
import routesConfiguration from "./routesConfiguration";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: routesConfiguration,
});

router.beforeEach((to, _from, next) => {
  checkAccessPermissions(to, next);
});

router.afterEach((to) => {
  setPageTitle(to);
});

export default router;
