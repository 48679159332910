import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VDivider } from "vuetify/lib";
import { mapGetters } from "vuex";
import EditableOverviewSection from "../EditableOverviewSection";
import PermissionGroupOverviewEditForm from "../PermissionGroupOverviewEditForm";
import PermissionGroupPermissions from "../PermissionGroupPermissions";

const PermissionGroupOverview = Vue.extend({
  data: () => ({
    basic: {
      title: "Overview",
      columns: [{ name: "name", title: "Name" }],
    },
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_PERMISSION_GROUP]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  render(): VNode {
    return (
      <div>
        <EditableOverviewSection
          configuration={this.basic}
          data={this.INSPECTED_PERMISSION_GROUP?.basic}
          editDialog={Dialogs.PERMISSION_GROUP_EDIT_BASIC}
          action={Actions.PERMISSION_GROUP_OVERVIEW_EDIT}
          scopedSlots={{
            form: () => <PermissionGroupOverviewEditForm ref="form" />,
          }}
          isDataFetching={this.IS_ACTION_FETCHING(
            Actions.PERMISSION_GROUP_OVERVIEW_FETCH
          )}
        />
        <VDivider class="mb-5" />
        <PermissionGroupPermissions />
      </div>
    );
  },
});

export default PermissionGroupOverview;
