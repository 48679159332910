import { Module } from "vuex";

import DropdownsState from "@/models/store/DropdownsState";
import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

const dropdowns: Module<DropdownsState, []> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default dropdowns;
