import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";

const CampaignChangeStateDialog = Vue.extend({
  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    instanceData(): any {
      return this.GET_DIALOG_DATA(Dialogs.CAMPAIGN_STATE_CHANGE);
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.CAMPAIGN_STATE_CHANGE]),
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.CAMPAIGN_STATE_CHANGE}
        title="Confirmation"
        closeOnEsc
        actions={[
          {
            title: "Apply",
            icon: "mdi-check",
            id: Actions.CAMPAIGN_STATE_CHANGE,
            action: () =>
              this.CAMPAIGN_STATE_CHANGE({
                idCampaign: this.instanceData?.idCampaign,
                desiredStatus: this.instanceData?.desiredStatus,
              }),
          },
        ]}
      >
        {this.instanceData?.text}
      </Dialog>
    );
  },
});

export default CampaignChangeStateDialog;
