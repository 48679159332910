import Dialogs from "@/constants/dialogs";
import { Actions } from "@/models/store";
import Vue, { VNode } from "vue";
import Dialog from "../Dialog";
import GrammarCreateForm from "../GrammarCreateForm";

const GrammarCreateDialog = Vue.extend({
  render(): VNode {
    return (
      <Dialog
        id={Dialogs.GRAMMAR_CREATE}
        title="New grammar"
        actions={[
          {
            title: "Create",
            icon: "mdi-plus",
            action: () => {
              const refs: any = this.$refs;
              refs.form.handleSubmit();
            },
            id: Actions.GRAMMAR_CREATE,
          },
        ]}
      >
        <GrammarCreateForm ref="form" />
      </Dialog>
    );
  },
});

export default GrammarCreateDialog;
