import { ActionTree } from "vuex";
import { Actions, Getters, Mutations } from "@/models/store";
import UserState from "@/models/store/UserState";
import createRequest from "@/helpers/createRequest";
import ApiEndpoints from "@/constants/apiEndpoints";
import parseResponse from "@/helpers/parseResponse";
import { login } from "@vocalls/sso-auth-service";
import router from "@/router";
import Routes from "@/constants/routes";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import { logout } from "@vocalls/sso-auth-service";
import { isBrowserSafari } from "@/helpers/browserDetections";

const actions: ActionTree<UserState, []> = {
  [Actions.FETCH_API_KEY]: async ({ dispatch }) => {
    const fetchedUser = await login({
      redirect_uri: `${process.env.VUE_APP_SSO_CALLBACK_URL}/auth-callback`,
      silent_redirect_uri: `${process.env.VUE_APP_SSO_CALLBACK_URL}/auth-refresh`,
      authority: process.env.VUE_APP_SSO_URL,
      client_id: process.env.VUE_APP_SSO_CLIENT_ID,
    });

    if (fetchedUser) {
      dispatch(Actions.USER_LOG_IN, fetchedUser.access_token);
    }
  },

  [Actions.FETCH_USER_INFO]: async ({ commit, getters }) => {
    const userInfoResponse = await createRequest({
      action: Actions.FETCH_USER_INFO,
      method: "GET",
      url: ApiEndpoints.USER_INFO,
    });

    const { data, errors } = parseResponse(userInfoResponse);

    if (errors.length === 0) {
      commit(Mutations.SET_USER_INFO, data);
      return data;
    } else {
      // Safari redirect hell fix
      if (isBrowserSafari()) {
        setTimeout(
          () => (getters[Getters.IS_USER_SIGNED_IN] ? null : logout()),
          3000
        );
      } else {
        logout();
        return null;
      }
    }
  },

  [Actions.USER_ACCOUNTS_FETCH]: async ({ commit }) => {
    const userAccountsResponse = await createRequest({
      action: Actions.USER_ACCOUNTS_FETCH,
      method: "GET",
      url: ApiEndpoints.USER_ACCOUNTS,
    });

    const { data, errors } = parseResponse(userAccountsResponse);

    if (errors.length === 0) {
      commit(Mutations.SET_USER_ACCOUNTS, data);
    }
  },

  [Actions.USER_LOG_IN]: async ({ commit, dispatch }, token) => {
    const isUserAuthorized = await dispatch(Actions.FETCH_USER_INFO);

    if (isUserAuthorized) {
      commit(Mutations.SET_USER_SIGNED_IN);
      commit(Mutations.SET_USER_TOKEN, token);

      const routePermissions = router.currentRoute.meta?.permissions || [
        null,
        null,
      ];

      hasUserPermissions(routePermissions[0], routePermissions[1])
        ? null
        : router.push({ name: Routes.NOT_AUTHORIZED });
    }
  },
};

export default actions;
