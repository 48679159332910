import Vue, { VNode } from "vue";
import { VCard, VCardText } from "vuetify/lib";
import TenantBilling from "../TenantBilling/TenantBilling";

const TenantBillingAll = Vue.extend({
  render(): VNode {
    return (
      <div>
        <h2 class="text-h4 mb-5 font-weight-bold primary--text">
          Billing overview
        </h2>
        <VCard>
          <VCardText>
            <TenantBilling withTenantSelection />
          </VCardText>
        </VCard>
      </div>
    );
  },
});

export default TenantBillingAll;
