import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";

const UserApiSecretRemoveDialog = Vue.extend({
  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    idUser(): string {
      return this.GET_DIALOG_DATA(Dialogs.USER_API_SECRET_REMOVE)?.idUser;
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.USER_API_SECRET_REMOVE]),
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.USER_API_SECRET_REMOVE}
        title="Confirmation"
        actions={[
          {
            title: "Remove",
            icon: "mdi-delete",
            action: () => this.USER_API_SECRET_REMOVE({ idUser: this.idUser }),
            id: Actions.USER_API_SECRET_REMOVE,
          },
        ]}
      >
        Client secret will be removed permanently. Do you want to continue?
      </Dialog>
    );
  },
});

export default UserApiSecretRemoveDialog;
