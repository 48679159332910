import Dialogs from "@/constants/dialogs";
import { isRequired } from "@/helpers/validations";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";
import ModulesSelect from "../ModulesSelect";

const ChangeModuleDialog = Vue.extend({
  data: () => ({
    moduleId: "",
    idDialModuleLine: "",
  }),

  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    dialogState(): any {
      return this.GET_DIALOG_DATA(Dialogs.LINE_CHANGE_MODULE);
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.LINE_SET_MODULE]),

    initDialog(): void {
      this.moduleId = this.dialogState?.idDialModule;
      this.idDialModuleLine = this.dialogState?.idDialModuleLine;
    },
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.LINE_CHANGE_MODULE}
        title="Change module"
        closeOnEsc
        actions={[
          {
            action: () =>
              this.LINE_SET_MODULE({
                idDialModule: this.moduleId,
                idDialModuleLine: this.idDialModuleLine,
              }),
            id: Actions.LINE_SET_MODULE,
            title: "Confirm",
            icon: "mdi-check",
          },
        ]}
        toggleHandler={this.initDialog}
      >
        <ModulesSelect vModel={this.moduleId} rules={[isRequired]} />
      </Dialog>
    );
  },
});

export default ChangeModuleDialog;
