const CAMPAIGN_STATUS = {
  0: {
    icon: "mdi-application-cog",
    color: "gray",
    title: "Demo",
  },
  1: {
    icon: "mdi-plus",
    color: "gray",
    title: "Created",
  },
  2: {
    icon: "mdi-credit-card-clock",
    color: "gray",
    title: "Waiting for payment",
  },
  3: {
    icon: "mdi-account-clock",
    color: "gray",
    title: "Waiting for approval",
  },
  4: {
    icon: "mdi-tools",
    color: "warning",
    title: "To repair",
  },
  5: {
    icon: "mdi-clipboard-text-clock",
    color: "accent",
    title: "Planned",
  },
  6: {
    icon: "mdi-cancel",
    color: "error",
    title: "Denied",
  },
  7: {
    icon: "mdi-pause-circle",
    color: "warning",
    title: "Stopped",
  },
  8: {
    icon: "mdi-progress-check",
    color: "success",
    title: "In progress",
  },
  9: {
    icon: "mdi-check-all",
    color: "gray",
    title: "Finished",
  },
};

export default CAMPAIGN_STATUS;
