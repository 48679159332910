import Dialogs from "@/constants/dialogs";
import Routes from "@/constants/routes";
import CAMPAIGN_STATUS from "@/constants/campaignStatus";
import { formatDate } from "@/helpers/dateAndTimeUtils";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import ApiEndpoints from "@/constants/apiEndpoints";
import Vue, { VNode } from "vue";
import {
  VBtn,
  VCard,
  VCardText,
  VCardTitle,
  VIcon,
  VList,
  VSkeletonLoader,
  VSpacer,
} from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import CampaignChangeStateDialog from "../CampaignChangeStateDialog";
import CampaignEditDialog from "../CampaignEditDialog";
import CampaignManageContacts from "../CampaignManageContacts";
import CampaignRemoveDialog from "../CampaignRemoveDialog";
import DialLogDataGrid from "../DialLogDataGrid";
import DoughnutChart from "../DoughnutChart";
import CampaignLink from "./CampaignLink";
import CampaignValidationDialog from "../CampaignValidationDialog";
import CampaignDataCard from "./CampaignDataCard";
import CampaignOverviewListItem from "./CampaignOverviewListItem";
import CampaignErrorContactsDialog from "../CampaignErrorContactsDialog";
import CampaignTargetCard from "./CampaignTargetCard";
import CsvExportDialog from "../CsvExportDialog";

const CampaignDashboard = Vue.extend({
  data: () => ({
    campaignActionsConfig: {},
    statusWebSocket: {} as any,
    chartData: {
      labels: ["January", "February", "March"],
      datasets: [{ data: [40, 20, 12] }],
    },
    chartOptions: {
      responsive: true,
    },
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_CAMPAIGN]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),

    dashboardData(): any {
      return this.INSPECTED_CAMPAIGN?.basic;
    },

    campaignActions(): any {
      const {
        edit,
        remove,
        pause,
        finish,
        run,
        importContacts,
        validation,
      }: any = this.campaignActionsConfig;

      switch (this.INSPECTED_CAMPAIGN?.basic?.status) {
        case 1:
          return {
            primary: run,
            secondary: [validation, remove, importContacts, edit],
          };
        case 5:
        case 8: {
          return { primary: pause };
        }
        case 7: {
          return {
            primary: run,
            secondary: [validation, remove, importContacts, edit, finish],
          };
        }
        case 9: {
          return { secondary: [remove] };
        }
        default:
          return { primary: null, secondary: null };
      }
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [
      Actions.CAMPAIGN_OVERVIEW_FETCH,
      Actions.CAMPAING_STATUS_UPDATE,
    ]),

    initWebSocket() {
      this.statusWebSocket = new WebSocket(
        `${ApiEndpoints.CAMPAIGN_DETAIL_WS}?idCampaign=${this.$route.params.idCampaign}`
      );

      this.statusWebSocket.onmessage = ({ data }: any) => {
        const recievedStatus = JSON.parse(data)?.status;

        if (!recievedStatus) {
          return null;
        }

        this.CAMPAING_STATUS_UPDATE(recievedStatus);
      };
    },
  },

  created() {
    this.CAMPAIGN_OVERVIEW_FETCH({ idCampaign: this.$route.params.idCampaign });

    try {
      this.initWebSocket();
    } catch (e) {
      console.error(e);
    }

    this.campaignActionsConfig = {
      edit: {
        title: "Edit",
        icon: "mdi-pencil",
        action: () => toggleDialog(Dialogs.CAMPAIGN_EDIT, {}),
      },
      remove: {
        title: "Remove",
        icon: "mdi-delete",
        action: () =>
          toggleDialog(Dialogs.CAMPAIGN_REMOVE, {
            idCampaign: this.$route.params.idCampaign,
            isDetailActive: true,
          }),
      },
      pause: {
        title: "Pause",
        icon: "mdi-pause",
        action: () =>
          toggleDialog(Dialogs.CAMPAIGN_STATE_CHANGE, {
            idCampaign: this.$route.params.idCampaign,
            text: "Do you really want to pause the campaign?",
            desiredStatus: 7,
          }),
      },
      run: {
        title: "Run",
        icon: "mdi-play",
        action: () =>
          toggleDialog(Dialogs.CAMPAIGN_STATE_CHANGE, {
            idCampaign: this.$route.params.idCampaign,
            text: "Do you really want to run the campaign?",
            desiredStatus: 5,
          }),
      },
      finish: {
        title: "Finish",
        icon: "mdi-flag-checkered",
        action: () =>
          toggleDialog(Dialogs.CAMPAIGN_STATE_CHANGE, {
            idCampaign: this.$route.params.idCampaign,
            text: "Do you really want to finish campaign? Editation will be no longer possible.",
            desiredStatus: 9,
          }),
      },
      importContacts: {
        title: "Import contacts",
        icon: "mdi-import",
        action: () =>
          toggleDialog(Dialogs.CAMPAIGN_MANAGE_CONTACTS, {
            idCampaign: this.$route.params.idCampaign,
          }),
      },
      validation: {
        title: "Validation",
        icon: "mdi-phone",
        action: () => toggleDialog(Dialogs.CAMPAIGN_VALIDATION_DIALOG),
      },
    };
  },

  beforeDestroy() {
    this.statusWebSocket.close();
  },

  render(): VNode {
    return (
      <div class="campaign-dashboard">
        <div class="d-flex align-center">
          <h2 class="text-h4 font-weight-bold primary--text mb-5">
            {this.dashboardData?.name || "-"}
          </h2>
        </div>
        <div class="d-flex">
          <VBtn
            rounded
            class="secondary primary--text"
            onClick={() => this.$router.push({ name: Routes.CAMPAIGN })}
          >
            <VIcon left color="primary">
              mdi-arrow-left
            </VIcon>
            Back
          </VBtn>
          <VSpacer />
          {this.campaignActions?.secondary &&
            this.campaignActions?.secondary.map(
              ({ title, icon, action }: any) => (
                <VBtn
                  onClick={action}
                  rounded
                  class="secondary primary--text ml-3"
                >
                  <VIcon left color="primary">
                    {icon}
                  </VIcon>
                  {title}
                </VBtn>
              )
            )}
          {this.campaignActions?.primary && (
            <VBtn
              rounded
              class="tertiary white--text ml-3"
              onClick={this.campaignActions.primary.action}
            >
              <VIcon left color="white">
                {this.campaignActions.primary.icon}
              </VIcon>
              {this.campaignActions.primary.title}
            </VBtn>
          )}
        </div>
        {this.IS_ACTION_FETCHING(Actions.CAMPAIGN_OVERVIEW_FETCH) ? (
          <VSkeletonLoader class="mt-5 mb-5" type="table" />
        ) : (
          <div class="d-flex mt-5 mb-5">
            <VCard class="mr-5" style="flex: 1">
              <VCardTitle class="d-flex align-center primary--text font-weight-bold text-h5">
                Campaign parameters
              </VCardTitle>
              <VCardText>
                <VList>
                  <CampaignOverviewListItem title="Flow">
                    <CampaignLink
                      title={this.dashboardData?.callscript}
                      routeName={Routes.FLOW_DETAIL_BASIC}
                      routeParamName="idCallScript"
                      routeParamValue={this.dashboardData?.idCallscript}
                    />
                  </CampaignOverviewListItem>

                  <CampaignOverviewListItem title="Line">
                    <CampaignLink
                      title={this.dashboardData?.line}
                      routeName={Routes.LINE_DETAIL_BASIC}
                      routeParamName="idDialModuleLine"
                      routeParamValue={this.dashboardData?.idLine}
                    />
                  </CampaignOverviewListItem>

                  <CampaignOverviewListItem title="Tenant">
                    <CampaignLink
                      title={this.dashboardData?.tenant}
                      routeName={Routes.TENANT_DETAIL_BASIC}
                      routeParamName="idTenant"
                      routeParamValue={this.dashboardData?.idTenant}
                    />
                  </CampaignOverviewListItem>

                  <CampaignOverviewListItem title="Ongoing">
                    <VIcon
                      color={this.dashboardData?.ongoing ? "success" : "error"}
                    >
                      {this.dashboardData?.ongoing ? "mdi-check" : "mdi-close"}
                    </VIcon>
                  </CampaignOverviewListItem>

                  <CampaignOverviewListItem title="Start">
                    {this.dashboardData?.start
                      ? formatDate(this.dashboardData?.start)
                      : "Immediately"}
                  </CampaignOverviewListItem>

                  {this.dashboardData?.end && (
                    <CampaignOverviewListItem
                      title="End"
                      valueTooltip={
                        this.dashboardData?.ongoing
                          ? null
                          : "Date and time of immediate termination of the campaign"
                      }
                    >
                      {formatDate(this.dashboardData?.end)}
                    </CampaignOverviewListItem>
                  )}

                  <CampaignOverviewListItem title="Time range">
                    {this.dashboardData?.callFromDayTime}
                    &nbsp;-&nbsp;
                    {this.dashboardData?.callToDayTime}
                  </CampaignOverviewListItem>

                  <CampaignOverviewListItem title="Number of call attemps">
                    {this.dashboardData?.numberOfCallAttemps || ""}
                  </CampaignOverviewListItem>

                  <CampaignOverviewListItem title="Retry call after">
                    {isNaN(this.dashboardData?.retryCallAfterSeconds)
                      ? "N/A"
                      : `${this.dashboardData?.retryCallAfterSeconds / 60} min`}
                  </CampaignOverviewListItem>
                </VList>
              </VCardText>
            </VCard>
            <div style="flex: 2">
              <div class="d-flex">
                <CampaignDataCard
                  title="Status"
                  value={this.dashboardData?.statusName || "N/A"}
                  valueColor={
                    CAMPAIGN_STATUS[this.dashboardData?.status]?.color ||
                    "primary"
                  }
                  icon={CAMPAIGN_STATUS[this.dashboardData?.status]?.icon}
                  iconColor={CAMPAIGN_STATUS[this.dashboardData?.status]?.color}
                />
                <CampaignDataCard
                  title="Contacts input"
                  value={this.dashboardData?.contacts || "-"}
                  icon="mdi-account-box"
                />
                <CampaignDataCard
                  title="Calls input"
                  value={this.dashboardData?.calls || "-"}
                  icon="mdi-phone"
                  noRightMargin
                />
              </div>
              <div class="d-flex mt-5">
                <VCard class="mr-5" style="flex: 1">
                  <VCardText>
                    <DoughnutChart
                      centerValue={
                        this.dashboardData?.pickedUp?.toLocaleString("cs") || 0
                      }
                      centerValuePerex="Calls picked up"
                      chartData={{
                        labels: ["Picked", "Not Picked"],
                        datasets: [
                          {
                            backgroundColor: ["#17c57c", "#f0545f"],
                            data: [
                              this.dashboardData?.pickedUp || 0,
                              this.dashboardData?.notPickedUp || 0,
                            ],
                          },
                        ],
                      }}
                    />
                  </VCardText>
                </VCard>
                <VCard style="flex: 1">
                  <VCardText>
                    <DoughnutChart
                      centerValue={
                        this.dashboardData?.processed?.toLocaleString("cs") || 0
                      }
                      centerValuePerex="Calls processed"
                      chartData={{
                        labels: ["Called", "To call"],
                        datasets: [
                          {
                            backgroundColor: ["#17c57c", "#f0545f"],
                            data: [
                              this.dashboardData?.processed || 0,
                              this.dashboardData?.notProcessed || 0,
                            ],
                          },
                        ],
                      }}
                    />
                  </VCardText>
                </VCard>
              </div>
              <div class="d-flex mt-5">
                <CampaignTargetCard
                  title="Target 1"
                  value={this.dashboardData?.target1}
                  valueAvg={this.dashboardData?.target1avg?.toFixed(2)}
                />
                <CampaignTargetCard
                  title="Target 2"
                  value={this.dashboardData?.target2}
                  valueAvg={this.dashboardData?.target2avg?.toFixed(2)}
                />
                <CampaignTargetCard
                  title="Target 3"
                  value={this.dashboardData?.target3}
                  valueAvg={this.dashboardData?.target3avg?.toFixed(2)}
                  noRightMargin
                />
              </div>
            </div>
          </div>
        )}
        <div class="d-flex justify-end mt-15">
          <VBtn
            class="secondary primary--text spacing-playground mr-4 mb-4"
            onClick={() => {
              toggleDialog(Dialogs.CAMPAIGN_CSV_EXPORT, {
                customFileName: `Campaign_AllData_${this.$route.params.idCampaign}`,
                fetchParams: {
                  idCampaign: parseInt(this.$route.params.idCampaign),
                  aggregated: false,
                },
              });
            }}
            rounded
          >
            <VIcon left>mdi-file-download</VIcon>
            All data CSV{" "}
          </VBtn>
          <VBtn
            class="tertiary white--text spacing-playground mb-4"
            onClick={() => {
              toggleDialog(Dialogs.CAMPAIGN_CSV_EXPORT, {
                customFileName: `Campaign_Results_${this.$route.params.idCampaign}`,
                fetchParams: {
                  idCampaign: parseInt(this.$route.params.idCampaign),
                  aggregated: true,
                },
              });
            }}
            rounded
          >
            <VIcon left>mdi-microsoft-excel</VIcon>
            Results report CSV{" "}
          </VBtn>
        </div>
        <DialLogDataGrid
          params={{
            idCampaign: this.$route.params.idCampaign,
          }}
          withElevation
        />
        <CampaignEditDialog />
        <CampaignRemoveDialog />
        <CampaignChangeStateDialog />
        <CampaignManageContacts />
        <CampaignValidationDialog idCampaign={this.$route.params.idCampaign} />
        <CampaignErrorContactsDialog />
        <CsvExportDialog
          name={Dialogs.CAMPAIGN_CSV_EXPORT}
          apiEndpoint={ApiEndpoints.CSV_CAMPAIGN_STATISTICS}
          withoutRange
        />
      </div>
    );
  },
});

export default CampaignDashboard;
