import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import Routes from "@/constants/routes";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { isRequired } from "@/helpers/validations";
import { Actions } from "@/models/store";
import Vue, { VNode } from "vue";
import { VBtn, VForm, VTextField } from "vuetify/lib";
const BotDeployServerCreateForm = Vue.extend({
  data: () => ({
    fields: {
      name: "",
      system: "",
      note: "",
    },
    isFormValid: true,
  }),

  methods: {
    async handleSubmit() {
      const { isFormSubmited } = await submitForm({
        ref: this.$refs.form,
        url: ApiEndpoints.BOTDEPLOY_SERVER_BASIC,
        values: this.fields,
        actionId: Actions.BOTDEPLOY_SERVER_CREATE,
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.BOTDEPLOY_SERVER_CREATE, {});

        this.$router.push({
          name: Routes.BOTDEPLOY_SERVER_DETAIL_BASIC,
          params: {
            name: this.fields.name,
          },
        });

        pushNotify(Notify.SUCCESS, "Server created successfully");
      }
    },
  },

  render(): VNode {
    return (
      <VForm
        vModel={this.isFormValid}
        ref="form"
        onSubmit={(e: Event) => {
          e.preventDefault();
          this.handleSubmit();
        }}
      >
        <VTextField
          vModel={this.fields.name}
          rules={[isRequired]}
          label="Name"
        />
        <VTextField vModel={this.fields.system} label="System" />
        <VTextField vModel={this.fields.note} label="Note" />
        <VBtn type="submit" class="d-none"></VBtn>
      </VForm>
    );
  },
});

export default BotDeployServerCreateForm;
