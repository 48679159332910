import DAYS_IN_WEEK from "@/constants/daysInWeek";

const getDaysOfWeekFromValues = (
  values: number[],
  isShortcuts = false
): string[] => {
  const parsedDays: string[] = [];

  values.map((val) => {
    const dayConfig: any = DAYS_IN_WEEK.find((day) => day.value === val);

    if (!dayConfig) {
      return;
    }

    const day = isShortcuts ? dayConfig.text.slice(0, 3) : dayConfig.text;
    parsedDays.push(day);
  });

  return parsedDays;
};

export default getDaysOfWeekFromValues;
