const DAYS_IN_WEEK = [
  { text: "Monday", value: 1 },
  { text: "Tuesday", value: 2 },
  { text: "Wednesday", value: 3 },
  { text: "Thursday", value: 4 },
  { text: "Friday", value: 5 },
  { text: "Saturday", value: 6 },
  { text: "Sunday", value: 0 },
];

export default DAYS_IN_WEEK;
