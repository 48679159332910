import ApiCallsState from "@/models/store/ApiCallsState";
import ApiRequestId from "@/models/api/ApiRequestId";

interface ApiCallStateParams {
  state: ApiCallsState;
  action: string;
  actionState: string;
  id: ApiRequestId;
}

const getApiCallState = ({
  state,
  action,
  actionState,
  id,
}: ApiCallStateParams): boolean => {
  if (!state[action]) {
    return false;
  }

  if (id) {
    const actionId = state[action].findIndex(
      (currentAction) => currentAction.id === id
    );

    if (actionId !== -1) {
      return state[action][actionId].state === actionState;
    }

    return false;
  } else {
    if (state[action].length === 0) {
      return false;
    }

    if (
      state[action].filter((actionId) => actionId.state === actionState)
        .length === 0
    ) {
      return false;
    }

    return true;
  }
};

export default getApiCallState;
