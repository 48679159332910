import { ActionTree } from "vuex";
import { Actions, Mutations } from "@/models/store";
import NotificationState from "@/models/store/NotificationState";

const actions: ActionTree<NotificationState, []> = {
  [Actions.PUSH_NOTIFICATION]: ({ commit }, { type, text }) => {
    commit(Mutations.SET_NOTIFICATION, { type, text });
    commit(Mutations.TOGGLE_NOTIFICATION);
  },
};

export default actions;
