import { ActionTree } from "vuex";
import { Actions, Mutations } from "@/models/store";
import InstancesState from "@/models/store/InstancesState";
import ApiEndpoints from "@/constants/apiEndpoints";
import parseJsonOrNull from "@/helpers/parseJsonOrNull";
import Routes from "@/constants/routes";
import DataGrids from "@/constants/dataGrids";
import Dialogs from "@/constants/dialogs";
import createRequest from "@/helpers/createRequest";
import parseResponse from "@/helpers/parseResponse";
import pushNotify from "@/helpers/pushNotify";
import Notify from "@/constants/notifications";
import toggleDialog from "@/helpers/toggleDialog";

const moduleActions: ActionTree<InstancesState, []> = {
  [Actions.MODULE_OVERVIEW_FETCH]: (
    { commit, dispatch, state },
    { idDialModule }
  ) => {
    dispatch(Actions.SECTION_DETAIL_FETCH, {
      url: ApiEndpoints.MODULE_BASIC,
      action: Actions.MODULE_OVERVIEW_FETCH,
      params: { idDialModule },
      withTitleChange: true,
      modify: (data: any) =>
        commit(Mutations.SET_INSPECTED_MODULE, {
          ...state.inspectedModule,
          basic: data,
        }),
      error: () => commit(Mutations.SET_INSPECTED_MODULE, {}),
    });
  },

  [Actions.MODULE_CALLBOT_CONFIG_FETCH]: (
    { commit, dispatch, state },
    { idDialModule }
  ) => {
    dispatch(Actions.SECTION_DETAIL_FETCH, {
      url: ApiEndpoints.MODULE_CALLBOT_SETTINGS,
      action: Actions.MODULE_CALLBOT_CONFIG_FETCH,
      params: { idDialModule },
      modify: ({ editableFields, content }: any) =>
        commit(Mutations.SET_INSPECTED_MODULE, {
          ...state.inspectedModule,
          callbotSettings: {
            editableFields,
            content: parseJsonOrNull(content),
          },
        }),
      error: () => commit(Mutations.SET_INSPECTED_MODULE, {}),
    });
  },

  [Actions.MODULE_REMOVE]: ({ dispatch }, { idDialModule, isDetailActive }) =>
    dispatch(Actions.INSTANCE_REMOVE, {
      params: { idDialModule },
      isDetailActive,
      endpoint: ApiEndpoints.MODULE_REMOVE,
      action: Actions.MODULE_REMOVE,
      redirectRoute: Routes.MODULE_OVERVIEW,
      dataGrid: DataGrids.DIAL_MODULES,
      dataGridFetch: Actions.MODULES_DATAGRID_FETCH,
      dialog: Dialogs.MODULE_REMOVE,
      prefix: "Module",
    }),

  [Actions.MODULE_SET_DEFAULT_LINE]: async (
    _,
    { idDialModule, idDialModuleLine }
  ) => {
    const request = createRequest({
      action: Actions.MODULE_SET_DEFAULT_LINE,
      method: "POST",
      url: ApiEndpoints.MODULE_DEFAULT_LINE,
      params: { idDialModule },
      data: { idDialModuleLine },
    });

    const { errors } = parseResponse(request);

    if (errors.length > 0) {
      return;
    }

    pushNotify(Notify.SUCCESS, "Default line changed successfully");
    toggleDialog(Dialogs.MODULE_SET_DEFAULT_LINE);
  },
};

export default moduleActions;
