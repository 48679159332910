import { MutationTree } from "vuex";
import { Mutations } from "@/models/store";
import DialogsState from "@/models/store/DialogsState";

const mutations: MutationTree<DialogsState> = {
  [Mutations.TOGGLE_DIALOG]: (state, { name, data }) => {
    state[name].isActive = !state[name].isActive;
    data ? (state[name].data = data) : null;
  },
};

export default mutations;
