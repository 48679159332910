import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import Routes from "@/constants/routes";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { isRequired } from "@/helpers/validations";
import { Actions } from "@/models/store";
import Vue, { VNode } from "vue";
import { VForm, VSwitch, VTextField } from "vuetify/lib";

const TenantCreateForm = Vue.extend({
  data: () => ({
    fields: {
      invoicingName: "",
      companyNumber: "",
      vatNumber: "",
      address: "",
      isCompany: true,
    },
    isFormValid: true,
  }),

  methods: {
    getLanguageConfig() {
      const refs: any = this.$refs;
      return refs.languageSelect.getLanguageConfig();
    },

    async handleSubmit() {
      const { isFormSubmited, data } = await submitForm({
        ref: this.$refs.form,
        url: ApiEndpoints.TENANT_CREATE,
        values: this.fields,
        actionId: Actions.TENANT_CREATE,
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.TENANT_CREATE, {});
        pushNotify(Notify.SUCCESS, "Tenant created successfully");

        this.$router.push({
          name: Routes.TENANT_DETAIL_BASIC,
          params: { idTenant: data.id },
        });
      }
    },
  },

  render(): VNode {
    return (
      <VForm vModel={this.isFormValid} ref="form">
        <VTextField
          vModel={this.fields.invoicingName}
          label="Invoicing name"
          rules={[isRequired]}
        />
        <VTextField vModel={this.fields.companyNumber} label="Company number" />
        <VTextField vModel={this.fields.vatNumber} label="Vat number" />
        <VTextField vModel={this.fields.address} label="Address" />
        <VSwitch vModel={this.fields.isCompany} label="Tenant is a company" />
      </VForm>
    );
  },
});

export default TenantCreateForm;
