import InstancesState from "@/models/store/InstancesState";

const state: InstancesState = {
  inspectedModule: {
    basic: null,
    callbotSettings: null,
  },

  inspectedLine: {
    basic: null,
    callbotSettings: null,
    statistics: null,
    monitoring: null,
    cleaningSettings: null,
  },

  inspectedFlow: {
    basic: null,
  },

  inspectedGrammar: {
    basic: null,
  },

  inspectedTenant: {
    basic: null,
    notifyGroups: null,
    statistics: null,
    billing: null,
    parameters: null,
  },

  inspectedUser: {
    basic: null,
    roles: null,
    apiSecret: "",
    twoFactor: null,
  },

  inspectedPermissionGroup: {
    basic: null,
    permissions: null,
  },

  inspectedCampaign: {
    basic: null,
  },

  inspectedBotDeployModule: {
    basic: null,
  },

  inspectedBotDeployServer: {
    basic: null,
  },

  inspectedBotDeployConfig: {
    basic: null,
  },

  inspectedLanguage: {
    basic: null,
  },
};

export default state;
