import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import Routes from "@/constants/routes";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { isRequired } from "@/helpers/validations";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VForm, VSelect, VTextField } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import LanguageSelect from "../LanguageSelect";
import LanguageWithVoiceSelect from "../LanguageWithVoiceSelect";
import { FLOW_TYPE_ICONS } from "@/constants/valueIcons";

const FlowCreateForm = Vue.extend({
  data: () => ({
    fields: {
      name: "",
      type: 0,
      language: "cs",
    },
    isFormValid: true,
  }),

  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),
    ...mapGetters(Modules.RESOURCES, [Getters.LANGUAGES]),

    isComponent(): boolean {
      return this.GET_DIALOG_DATA(Dialogs.FLOW_CREATE).isComponent;
    },
  },

  methods: {
    ...mapActions(Modules.RESOURCES, [Actions.LANGUAGES_FETCH]),

    getLanguageConfig() {
      const refs: any = this.$refs;
      return refs.languageSelect.getLanguageConfig();
    },

    async handleSubmit() {
      const languageConfig =
        this.isComponent ||
        ((this.fields.type === 1 || this.fields.type === 2) &&
          !this.isComponent)
          ? {
              languageCode: this.fields.language,
              languageName: this.LANGUAGES.find(
                (language: any) => language.id === this.fields.language
              ).name,
            }
          : this.getLanguageConfig();

      const { isFormSubmited, data } = await submitForm({
        ref: this.$refs.form,
        url: ApiEndpoints.FLOW_CREATE,
        values: {
          ...languageConfig,
          name: this.fields.name,
          type: this.fields.type,
          isComponent: this.isComponent,
        },
        actionId: Actions.FLOW_CREATE,
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.FLOW_CREATE, {});

        this.$router.push({
          name: Routes.FLOW_DETAIL_BASIC,
          params: { idCallScript: data.id },
        });

        pushNotify(Notify.SUCCESS, "Flow created successfully");
      }
    },
  },

  created() {
    this.LANGUAGES.length === 0 && this.isComponent
      ? this.LANGUAGES_FETCH()
      : null;
  },

  render(): VNode {
    return (
      <VForm vModel={this.isFormValid} ref="form">
        <VTextField
          vModel={this.fields.name}
          label="Name"
          rules={[isRequired]}
        />
        {!this.isComponent && (
          <div>
            <VSelect
              vModel={this.fields.type}
              label="Type"
              appendIcon={`mdi-${FLOW_TYPE_ICONS[this.fields.type].icon}`}
              items={[
                { text: "Voice", value: 0 },
                { text: "Chat", value: 1 },
                { text: "RPA", value: 2 },
              ]}
            />
            {this.fields.type === 0 ? (
              <LanguageWithVoiceSelect
                value={this.fields.language}
                ref="languageSelect"
              />
            ) : (
              <LanguageSelect vModel={this.fields.language} />
            )}
          </div>
        )}
      </VForm>
    );
  },
});

export default FlowCreateForm;
