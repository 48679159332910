import Vue, { VNode } from "vue";
import DataGrid from "../DataGrid";
import DataGridConfig from "@/models/dataGrid/DataGridConfig";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { Actions, Getters, Modules, Mutations } from "@/models/store";
import DataGrids from "@/constants/dataGrids";
import toggleDialog from "@/helpers/toggleDialog";
import Dialogs from "@/constants/dialogs";
import Dialog from "../Dialog";
import LineTariffEditForm from "../LineTariffEditForm";
import { VIcon } from "vuetify/lib";
import Routes from "@/constants/routes";

const TenantTariffsDataGrid = Vue.extend({
  data: () => ({
    dataGridConfig: {},
  }),

  computed: {
    ...mapGetters(Modules.DATA_GRIDS, [Getters.GET_DATAGRID_DATA]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.DATA_GRIDS, [Actions.TENANT_TARRIFS_DATAGRID_FETCH]),
    ...mapMutations(Modules.DIALOGS, [Mutations.TOGGLE_DIALOG]),

    parseTarrif(item: any): any {
      return JSON.parse(item?.tariff?.content) || {};
    },

    getTariffUnitAndIcon(type: number, divider: string): any {
      if (type === 0) {
        return { unit: "call", icon: "counter", color: "success" };
      }

      return divider === "60"
        ? { unit: "s", icon: "clock-time-seven", color: "info" }
        : { unit: "min", icon: "clock-time-seven", color: "info" };
    },
  },

  created() {
    const dataGridConfig: DataGridConfig = {
      key: "idLine",
      title: "Tariffs",
      isHeaderOutside: true,
      rowActions: (row) => ({
        primary: {
          action: () =>
            toggleDialog(Dialogs.LINE_TARIFF_EDIT_DIALOG, { ...row }),
          icon: "mdi-pencil",
          title: "Edit",
        },
      }),
      headers: [
        { text: "Line", value: "line" },
        { text: "Call price", value: "callPrice" },
        { text: "Round interval / Min Duration", value: "callMeta" },
        { text: "SMS price", value: "smsPrice" },
      ],
      filters: [
        {
          name: "line",
        },
      ],
      buttons: [
        {
          title: "Back to overview",
          icon: "mdi-arrow-left",
          action: () =>
            this.$router.push({
              name: Routes.BILLING_OVERVIEW,
              query: { idTenant: this.$route.query.idTenant },
            }),
        },
      ],
      columnModificators: {
        "item.callPrice": ({ item }) => {
          const tariff = this.parseTarrif(item);
          const tariffMeta = this.getTariffUnitAndIcon(
            item?.tariff?.type,
            tariff?.divider
          );

          return (
            tariff?.price && (
              <span>
                <VIcon
                  class="mr-1"
                  small
                  color={tariffMeta?.color}
                >{`mdi-${tariffMeta?.icon}`}</VIcon>
                <strong>
                  {tariff?.price?.toLocaleString("cs")} {tariff.currency}
                </strong>{" "}
                / {tariffMeta?.unit}
              </span>
            )
          );
        },
        "item.callMeta": ({ item }) => {
          const tariff = this.parseTarrif(item);

          return item.tariff?.type === 0
            ? tariff?.minDuration
            : tariff?.roundInterval;
        },
        "item.divider": ({ item }) => this.parseTarrif(item).divider,
        "item.smsPrice": ({ item }) => {
          const tariff = this.parseTarrif(item);

          return (
            tariff.smsPrice && (
              <strong>
                {tariff.smsPrice} {tariff.currency}
              </strong>
            )
          );
        },
      },
    };

    this.dataGridConfig = dataGridConfig;
  },

  render(): VNode {
    return (
      <div>
        <DataGrid
          fetchData={this.TENANT_TARRIFS_DATAGRID_FETCH}
          fetchProps={{
            idTenant: this.$route.params.idTenant,
          }}
          data={this.GET_DATAGRID_DATA(DataGrids.TENANT_TARIFFS)}
          configuration={this.dataGridConfig}
          isFetching={this.IS_ACTION_FETCHING(
            Actions.TENANT_TARRIFS_DATAGRID_FETCH
          )}
        />
        <Dialog
          id={Dialogs.LINE_TARIFF_EDIT_DIALOG}
          title="Editation"
          actions={[
            {
              action: () => {
                const refs: any = this.$refs;
                refs.form.handleSubmit();
              },
              title: "Apply",
              icon: "mdi-check",
              id: Actions.LINE_TARIFF_EDIT,
            },
          ]}
        >
          <LineTariffEditForm ref="form" />
        </Dialog>
      </div>
    );
  },
});

export default TenantTariffsDataGrid;
