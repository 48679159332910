import Dialogs from "@/constants/dialogs";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import {
  VBtn,
  VCard,
  VCardText,
  VCol,
  VIcon,
  VList,
  VListItem,
  VListItemContent,
  VListItemIcon,
  VRow,
  VSkeletonLoader,
  VSpacer,
  VToolbar,
  VToolbarTitle,
} from "vuetify/lib";
import { mapGetters } from "vuex";
import UserRolesDialog from "../UserRolesDialog";

const UserRolesOverview = Vue.extend({
  props: {
    data: Array,
  },

  computed: {
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  render(): VNode {
    return (
      <div>
        <VCard flat>
          <VToolbar flat>
            <VToolbarTitle
              class="primary--text font-weight-bold text-h5"
              inset
              vertical
            >
              Permission groups
            </VToolbarTitle>
            <VSpacer />
            <VBtn
              small
              fab
              color="primary"
              onClick={() => toggleDialog(Dialogs.USER_ROLES, {})}
              class="ml-2 tertiary"
            >
              <VIcon>mdi-pencil</VIcon>
            </VBtn>
          </VToolbar>
          <VCardText>
            {this.IS_ACTION_FETCHING(Actions.USER_ROLES_FETCH) ? (
              <VSkeletonLoader type="chip" />
            ) : (
              <div>
                {this.data?.length > 0 ? (
                  <VList dense>
                    <VRow>
                      {this.data.map(({ name }: any) => (
                        <VCol col={12} md={6} class="py-0">
                          <VListItem>
                            <VListItemIcon>
                              <VIcon color="success">mdi-check-circle</VIcon>
                            </VListItemIcon>
                            <VListItemContent>{name}</VListItemContent>
                          </VListItem>
                        </VCol>
                      ))}
                    </VRow>
                  </VList>
                ) : (
                  <span>There are no connected roles to the user.</span>
                )}
              </div>
            )}
          </VCardText>
        </VCard>

        <UserRolesDialog />
      </div>
    );
  },
});

export default UserRolesOverview;
