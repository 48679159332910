import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";

const PermissionGroupRemoveDialog = Vue.extend({
  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    instanceData(): any {
      return this.GET_DIALOG_DATA(Dialogs.PERMISSION_GROUP_REMOVE);
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.PERMISSION_GROUP_REMOVE]),
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.PERMISSION_GROUP_REMOVE}
        title="Confirmation"
        closeOnEsc
        actions={[
          {
            title: "Remove",
            icon: "mdi-delete",
            id: Actions.PERMISSION_GROUP_REMOVE,
            action: () =>
              this.PERMISSION_GROUP_REMOVE({
                idRole: this.instanceData.id,
                isDetailActive: !!this.$route.params.idRole,
              }),
          },
        ]}
      >
        Do you really want to remove "
        <strong class="primary--text">{this.instanceData.name}</strong>" group?
      </Dialog>
    );
  },
});

export default PermissionGroupRemoveDialog;
