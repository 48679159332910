import Vue, { VNode } from "vue";
import Root from "@/components/Root";

const App = Vue.extend({
  render(): VNode {
    return <Root />;
  },
});

export default App;
