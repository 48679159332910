import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import VueJsonEditor from "vue-json-editor";
import {
  VCol,
  VDivider,
  VForm,
  VRow,
  VSwitch,
  VTab,
  VTabItem,
  VTabs,
  VTabsItems,
  VTextField,
} from "vuetify/lib";
import { isRequired, minValue } from "@/helpers/validations";

const Fields = {
  azure: "azure",
  oldInDays: "oldInDays",
  dialogs: "dialogs",
  phone: "phone",
  speakflows: "speakflows",
  unrecognizedReactions: "unrecognizedReactions",
  variables: "variables",
};

const LineCleaningEditForm = Vue.extend({
  data: () => ({
    fields: { oldInDays: "", diallogs: false, toggles: {} },
    activeTab: 0,
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_LINE]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.LINE_CLEANING_SETTINGS_FETCH]),

    async handleSubmit(): Promise<void> {
      const { oldInDays, diallogs, toggles } = this.fields;

      const { isFormSubmited } = await submitForm({
        actionId: Actions.LINE_CLEANING_SETTINGS_EDIT,
        values: { oldInDays, diallogs, ...toggles },
        url: ApiEndpoints.LINE_CLEANING_SETTINGS,
        params: { idDialModuleLine: this.$route.params.idDialModuleLine },
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.LINE_CLEANING, {});
        pushNotify(
          Notify.SUCCESS,
          "Line cleaning settings edited successfully"
        );

        this.LINE_CLEANING_SETTINGS_FETCH({
          idDialModuleLine: this.$route.params.idDialModuleLine,
        });
      }
    },

    toggleClearAll(isActive: boolean): void {
      Object.keys(this.fields.toggles).map((key: string) => {
        this.fields.toggles[key] = isActive;
      });
    },
  },

  created() {
    const { oldInDays, diallogs, ...fields } =
      this.INSPECTED_LINE?.cleaningSettings?.content;

    this.fields = { oldInDays, diallogs, toggles: { ...fields } };
  },

  watch: {
    "fields.toggles": {
      handler() {
        Object.values(this.fields.toggles).every((val: any) => val)
          ? (this.fields.diallogs = true)
          : (this.fields.diallogs = false);
      },
      deep: true,
    },
  },

  render(): VNode {
    return (
      <div>
        <VTabs grow vModel={this.activeTab}>
          <VTab>Form</VTab>
          <VTab>Source</VTab>
        </VTabs>

        <VTabsItems vModel={this.activeTab}>
          <VTabItem>
            <VForm class="pt-5">
              <VRow>
                <VCol cols={6}>
                  <VTextField
                    vModel={this.fields[Fields.oldInDays]}
                    label="Record older than (days)"
                    rules={[isRequired, (val: string) => minValue(val, 1)]}
                    type="number"
                  />
                </VCol>
              </VRow>
              <VSwitch
                vModel={this.fields.diallogs}
                label="Clear all"
                onChange={(val: boolean) => this.toggleClearAll(val)}
              />
              <VDivider class="mb-8" />
              <VSwitch
                vModel={this.fields.toggles[Fields.azure]}
                label="Azure content"
              />
              <VSwitch
                vModel={this.fields.toggles[Fields.phone]}
                label="Phone"
              />
              <VSwitch
                vModel={this.fields.toggles[Fields.speakflows]}
                label="Speak flows"
              />
              <VSwitch
                vModel={this.fields.toggles[Fields.unrecognizedReactions]}
                label="Unrecognized reactions"
              />
              <VSwitch
                vModel={this.fields.toggles[Fields.variables]}
                label="Variables"
              />
            </VForm>
          </VTabItem>
          <VTabItem>
            <VueJsonEditor vModel={this.fields} mode="code" />
          </VTabItem>
        </VTabsItems>
      </div>
    );
  },
});

export default LineCleaningEditForm;
