const TARIFF_DIVIDER_TYPES = [
  {
    text: "Minutes",
    altText: "min",
    value: "1",
  },
  {
    text: "Seconds",
    altText: "s",
    value: "60",
  },
];

export default TARIFF_DIVIDER_TYPES;
