import Dialogs from "@/constants/dialogs";
import { Actions } from "@/models/store";
import Vue, { VNode } from "vue";
import Dialog from "../Dialog";
import LineMonitoringForm from "../LineMonitoringForm";

const LineMonitoringDialog = Vue.extend({
  data: () => ({
    isFormFinalStep: false,
  }),

  methods: {
    checkFinalStep() {
      const refs: any = this.$refs;
      this.isFormFinalStep = refs.form?.isFinalStep();
    },
  },

  render(): VNode {
    return (
      <Dialog
        title="Monitoring"
        id={Dialogs.LINE_MONITORING}
        width={625}
        actions={[
          {
            title: this.isFormFinalStep ? "Save" : "Next",
            icon: this.isFormFinalStep
              ? "mdi-content-save"
              : "mdi-chevron-right",
            action: () => {
              const refs: any = this.$refs;
              refs.form?.handleSubmit();
              this.checkFinalStep();
            },
            id: Actions.LINE_MONITORING_EDIT,
          },
        ]}
      >
        <LineMonitoringForm
          ref="form"
          onStepChange={() => this.checkFinalStep()}
        />
      </Dialog>
    );
  },
});

export default LineMonitoringDialog;
