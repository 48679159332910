import Vue from "vue";
import { Route, RouteMeta } from "vue-router";
import store from "@/store";
import { Getters, Modules } from "@/models/store";
import Routes from "@/constants/routes";
import hasUserPermissions from "@/helpers/hasUserPermissions";

export const setPageTitle = (to: Route): void => {
  Vue.nextTick(() => {
    to.meta?.title ? (document.title = to.meta.title) : null;
  });
};

export const checkAccessPermissions = (
  to: Route,
  next: (routeConfig?: RouteMeta) => void
): void => {
  const isUserSignedIn =
    store.getters[`${Modules.USER}/${Getters.IS_USER_SIGNED_IN}`];
  const scopeRouteWithPermissions = to.matched.find(
    (route) => route.meta.permissions
  );
  const permissions = scopeRouteWithPermissions
    ? scopeRouteWithPermissions.meta.permissions
    : [null, null];

  if (isUserSignedIn) {
    hasUserPermissions(permissions[0], permissions[1])
      ? next()
      : next({ name: Routes.NOT_AUTHORIZED });
  } else {
    next();
  }
};
