import Vue, { VNode } from "vue";
import DataGrid from "../DataGrid";
import DataGridConfig from "@/models/dataGrid/DataGridConfig";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import DataGrids from "@/constants/dataGrids";
import { formatDate } from "@/helpers/dateAndTimeUtils";
import Routes from "@/constants/routes";
import LineDeleteCallsDialog from "../LineDeleteCallsDialog";
import toggleDialog from "@/helpers/toggleDialog";
import Dialogs from "@/constants/dialogs";

const LineQueueDataGrid = Vue.extend({
  props: {
    params: {},
  },

  data: () => ({
    dataGridConfig: {},
  }),

  computed: {
    ...mapGetters(Modules.DATA_GRIDS, [
      Getters.GET_DATAGRID_DATA,
      Getters.GET_DATAGRID_FILTER,
    ]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.DATA_GRIDS, [Actions.LINE_QUEUE_DATAGRID_FETCH]),
    ...mapActions(Modules.INSTANCES, [Actions.LINE_REMOVE_CALL]),
  },

  created() {
    const dataGridConfig: DataGridConfig = {
      title: "Queue",
      disableElevation: true,
      headers: [
        { text: "Contact", value: "contact" },
        { text: "Planned", value: "planned" },
        { text: "Dequeued", value: "dequeued" },
        { text: "Flow", value: "callscript" },
      ],
      rowActions: ({ id, idLine }) => ({
        primary: {
          action: () => {
            toggleDialog(Dialogs.LINE_DELETE_CALL, {
              params: {
                id,
                idDialModuleLine: idLine,
              },
            });
          },
          icon: "mdi-delete",
          title: "Remove",
        },
      }),
      buttons: [
        {
          action: () => {
            toggleDialog(Dialogs.LINE_DELETE_CALL, {
              params: this.params,
            });
          },
          icon: "mdi-delete",
          title: "Remove All",
          isIconButton: true,
        },
        {
          action: () => {
            const currentFilterParams = this.GET_DATAGRID_FILTER(
              DataGrids.LINE_QUEUE
            );

            this.LINE_QUEUE_DATAGRID_FETCH({
              params: this.params,
              filters: currentFilterParams,
            });
          },
          isFab: true,
          icon: "mdi-refresh",
        },
      ],
      columnModificators: {
        "item.planned": ({ item }) => formatDate(item.planned),
        "item.dequeued": ({ item }) => formatDate(item.dequeued),

        "item.callscript": ({ item }) => (
          <router-link
            class="tertiary--text"
            to={{
              name: Routes.FLOW_DETAIL_BASIC,
              params: { idCallScript: item.idCallscript },
            }}
          >
            {item.callscript}
          </router-link>
        ),
      },
      filters: [
        { name: "contact" },
        {
          name: "campaign",
        },
        {
          name: "planned",
          dateTimePickers: [
            { name: "from", label: "From" },
            { name: "to", label: "To" },
          ],
        },
      ],
    };

    this.dataGridConfig = dataGridConfig;
  },

  render(): VNode {
    return (
      <div>
        <DataGrid
          fetchData={this.LINE_QUEUE_DATAGRID_FETCH}
          fetchProps={this.params}
          data={this.GET_DATAGRID_DATA(DataGrids.LINE_QUEUE)}
          configuration={this.dataGridConfig}
          isFetching={
            this.IS_ACTION_FETCHING(Actions.LINE_QUEUE_DATAGRID_FETCH) ||
            this.IS_ACTION_FETCHING(Actions.LINE_REMOVE_CALL)
          }
        />
        <LineDeleteCallsDialog />
      </div>
    );
  },
});

export default LineQueueDataGrid;
