import Dialogs from "@/constants/dialogs";
import { Namespaces, Permissions } from "@/constants/permissions";
import Routes from "@/constants/routes";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import toggleDialog from "@/helpers/toggleDialog";
import ActionHandler from "@/models/actions/ActionHandler";
import LineActionsParams from "@/models/actions/LineActionsParams";

const { LINE } = Namespaces;

const useLineActions = ({
  id,
  enable,
  idDialModule,
  name,
}: LineActionsParams): ActionHandler[] => [
  {
    action: () => toggleDialog(Dialogs.LINE_ENABLE, { id, enable, name }),
    title: enable ? "Disable" : "Enable",
    icon: enable ? "mdi-toggle-switch-off-outline" : "mdi-toggle-switch",
    isAllowed: hasUserPermissions(LINE, [Permissions.EDIT]),
  },
  {
    action: () =>
      toggleDialog(Dialogs.LINE_CHANGE_MODULE, {
        idDialModule,
        idDialModuleLine: id,
      }),
    title: "Change module",
    icon: "mdi-swap-horizontal",
    isAllowed: hasUserPermissions(LINE, [Permissions.EDIT]),
  },
  {
    icon: "mdi-chart-bar",
    title: "Reporting",
    route: {
      name: Routes.REPORTING,
      query: { lineId: id.toString() },
    },
  },
  {
    action: () => toggleDialog(Dialogs.LINE_CSV_EXPORT, { id }),
    title: "CSV Reports",
    icon: "mdi-microsoft-excel",
    isAllowed: hasUserPermissions(LINE, [Permissions.VIEW]),
  },
  {
    action: () => toggleDialog(Dialogs.LINE_REMOVE, { id, name }),
    title: "Delete",
    icon: "mdi-delete",
    isAllowed: hasUserPermissions(LINE, [Permissions.DELETE]),
  },
];

export default useLineActions;
