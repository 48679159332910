import Vue, { VNode } from "vue";
import { VCard, VCardText, VIcon } from "vuetify/lib";

const CampaignTargetCard = Vue.extend({
  props: {
    title: String,
    value: [Number, String],
    valueAvg: [Number, String],
    noRightMargin: {
      default: false,
      type: Boolean,
    },
  },

  render(): VNode {
    return (
      <VCard class={this.noRightMargin ? "" : "mr-5"} style="flex: 1;">
        <VCardText>
          <div class="d-flex justify-space-between">
            <span class="font-weight-bold">
              <VIcon class="mr-1">mdi-bullseye-arrow</VIcon>
              {this.title}
            </span>
            <span class="primary--text font-weight-bold">
              {this.value} ({this.valueAvg}%)
            </span>
          </div>
        </VCardText>
      </VCard>
    );
  },
});

export default CampaignTargetCard;
