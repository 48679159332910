import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import { isRequired, isTime, minValue } from "@/helpers/validations";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import {
  VAutocomplete,
  VCol,
  VDivider,
  VFileInput,
  VForm,
  VMenu,
  VRow,
  VSelect,
  VStepper,
  VStepperContent,
  VStepperHeader,
  VStepperItems,
  VStepperStep,
  VSwitch,
  VTextField,
} from "vuetify/lib";
import FlowSelect from "../FlowSelect";
import LineSelect from "../LineSelect";
import DAYS_IN_WEEK from "@/constants/daysInWeek";
import pushNotify from "@/helpers/pushNotify";
import Notify from "@/constants/notifications";
import toggleDialog from "@/helpers/toggleDialog";
import Dialogs from "@/constants/dialogs";
import { mapActions, mapGetters } from "vuex";
import Routes from "@/constants/routes";
import TenantSelect from "../TenantSelect";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import { Namespaces, Permissions } from "@/constants/permissions";
import DateTimePicker from "../DateTimePicker";

const CampaignCreateForm = Vue.extend({
  data: () => ({
    campaignId: "",
    fields: {
      name: "",
      callScriptCode: "",
      idLine: "",
      idTenant: "",
      dow: [1, 2, 3, 4, 5],
      runAsap: true,
      numberOfCallAttemps: "2",
      retryCallAfterMinutes: "60",
      start: "",
      end: "",
      ongoing: true,
      callFromDayTime: "9:00",
      callToDayTime: "18:00",
      contactsFile: null,
      delimiter: ",",
    },
    menus: {
      campaignStart: false,
      campaignEnd: false,
    },
    steps: {
      activeStep: 1,
      isStep1Valid: false,
    },
  }),

  computed: {
    ...mapGetters(Modules.USER, [Getters.USER_INFO]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.CAMPAIGN_IMPORT_CONTACTS]),
    ...mapActions(Modules.DATA_GRIDS, [Actions.CAMPAIGNS_DATAGRID_FETCH]),

    stepChange() {
      if (this.steps.activeStep === 3) {
        this.$router.push({
          name: Routes.CAMPAIGN_DETAIL,
          params: { idCampaign: this.campaignId },
        });
      } else {
        this.steps.activeStep = this.steps.activeStep + 1;
        this.$emit("stepChange", this.steps.activeStep);
      }
    },

    async handleCreateCampaign(): Promise<void> {
      const refs: any = this.$refs;
      const { name, callScriptCode, idLine, idTenant } = this.fields;

      const { isFormSubmited, data } = await submitForm({
        actionId: Actions.CAMPAIGN_CREATE,
        ref: refs.step1,
        values: { name, callScriptCode, idLine, idTenant },
        url: ApiEndpoints.CAMPAIGN_CREATE,
      });

      if (isFormSubmited) {
        pushNotify(Notify.SUCCESS, "Campaign created successfully.");
        this.CAMPAIGNS_DATAGRID_FETCH();

        this.campaignId = data?.idCampaign;
        this.steps.activeStep = this.steps.activeStep + 1;
        this.$emit("stepChange", this.steps.activeStep);
      }
    },

    async handleCsvImport(): Promise<void> {
      const importData = await this.CAMPAIGN_IMPORT_CONTACTS({
        idCampaign: this.campaignId,
        contactsFile: this.fields.contactsFile,
        delimiter: this.fields.delimiter,
      });

      if (importData) {
        this.steps.activeStep = this.steps.activeStep + 1;
        this.$emit("stepChange", this.steps.activeStep);
      }
    },

    async handleScheduleSave(): Promise<void> {
      const refs: any = this.$refs;
      const {
        dow,
        runAsap,
        numberOfCallAttemps,
        retryCallAfterMinutes,
        ongoing,
        start,
        end,
        callFromDayTime,
        callToDayTime,
      } = this.fields;

      const { isFormSubmited } = await submitForm({
        actionId: Actions.CAMPAIGN_SCHEDULE_EDIT,
        ref: refs.step3,
        params: { idCampaign: this.campaignId },
        values: {
          dow,
          runAsap,
          numberOfCallAttemps,
          retryCallAfterSeconds: parseInt(retryCallAfterMinutes) * 60,
          ongoing,
          start: start ? new Date(start) : null,
          end: end ? new Date(end) : null,
          callFromDayTime,
          callToDayTime,
        },
        url: ApiEndpoints.CAMPAIGN_SCHEDULE,
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.CAMPAIGN_CREATE, {});
        pushNotify(Notify.SUCCESS, "Campaign saved succesfully.");

        this.$router.push({
          name: Routes.CAMPAIGN_DETAIL,
          params: { idCampaign: this.campaignId },
        });
      }
    },

    submitStep(): void {
      switch (this.steps.activeStep) {
        case 1: {
          this.handleCreateCampaign();
          break;
        }
        case 2: {
          this.handleCsvImport();
          break;
        }
        case 3: {
          this.handleScheduleSave();
          break;
        }
        default:
          return;
      }
    },
  },

  created() {
    this.fields.idTenant = this.USER_INFO?.idTenant;
  },

  render(): VNode {
    return (
      <VStepper
        vModel={this.steps.activeStep}
        flat
        onChange={(step: boolean) => this.$emit("stepChange", step)}
      >
        <VStepperHeader class="mb-2">
          <VStepperStep step={1}>General</VStepperStep>
          <VDivider />
          <VStepperStep step={2}>Contacts</VStepperStep>
          <VDivider />
          <VStepperStep step={3}>Schedule</VStepperStep>
        </VStepperHeader>
        <VStepperItems>
          <VStepperContent step={1}>
            <VForm ref="step1" vModel={this.steps.isStep1Valid}>
              <VTextField
                vModel={this.fields.name}
                label="Name"
                rules={[isRequired]}
              />
              <FlowSelect
                vModel={this.fields.callScriptCode}
                options={{ rules: [isRequired] }}
              />
              <LineSelect
                vModel={this.fields.idLine}
                options={{ rules: [isRequired] }}
              />
              {hasUserPermissions(Namespaces.ACCESS_MANAGEMENT, [
                Permissions.VIEW_ACCOUNT_ALL,
              ]) && <TenantSelect vModel={this.fields.idTenant} />}
            </VForm>
          </VStepperContent>
          <VStepperContent step={2}>
            <VForm ref="step2" vModel={this.steps.isStep1Valid}>
              <VFileInput
                label="Browse file (.csv, .xlsx, .txt)"
                vModel={this.fields.contactsFile}
                accept="text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain"
              />
              <VAutocomplete
                class="ml-2"
                vModel={this.fields.delimiter}
                items={[",", ";"]}
                label="Delimiter used inside the file:"
              />
            </VForm>
          </VStepperContent>
          <VStepperContent step={3}>
            <VForm ref="step3" vModel={this.steps.isStep1Valid}>
              <VSelect
                label="Days of week"
                vModel={this.fields.dow}
                multiple
                smallChips
                deletableChips
                items={DAYS_IN_WEEK}
                rules={[isRequired]}
              />
              <VRow>
                <VCol md={6}>
                  <VTextField
                    vModel={this.fields.callFromDayTime}
                    label="Day start time"
                    rules={[isRequired, isTime]}
                  />
                </VCol>
                <VCol md={6}>
                  <VTextField
                    vModel={this.fields.callToDayTime}
                    label="Day end time"
                    rules={[isRequired, isTime]}
                  />
                </VCol>
              </VRow>
              <VRow>
                <VCol md={6}>
                  <VSwitch
                    vModel={this.fields.runAsap}
                    label="Run ASAP after processing"
                    class="d-inline-block mt-6 mb-2"
                    hideDetails
                  />
                </VCol>
                <VCol md={6}>
                  <VSwitch
                    vModel={this.fields.ongoing}
                    label="Ongoing Campaign"
                    class="d-inline-block mt-6 mb-2"
                    hideDetails
                  />
                </VCol>
              </VRow>
              <VRow>
                {!this.fields.runAsap && (
                  <VCol md={6}>
                    <VMenu
                      vModel={this.menus.campaignStart}
                      scopedSlots={{
                        activator: ({ on, attrs }: never) => (
                          <DateTimePicker
                            vModel={this.fields.start}
                            label="Campaign starts at"
                            firstDayOfWeek={1}
                            {...{ on, attrs }}
                          />
                        ),
                      }}
                    >
                      <DateTimePicker
                        vModel={this.fields.start}
                        firstDayOfWeek={1}
                      />
                    </VMenu>
                  </VCol>
                )}
                <VCol md={6}>
                  <VMenu
                    vModel={this.menus.campaignEnd}
                    scopedSlots={{
                      activator: ({ on, attrs }: never) => (
                        <DateTimePicker
                          vModel={this.fields.end}
                          label="Campaign ends at"
                          firstDayOfWeek={1}
                          {...{ on, attrs }}
                        />
                      ),
                    }}
                  ></VMenu>
                </VCol>
              </VRow>
              <VRow class="mt-6">
                <VCol md={6}>
                  <VTextField
                    vModel={this.fields.numberOfCallAttemps}
                    label="Number of call attempts"
                    rules={[isRequired, (val: string) => minValue(val, 1)]}
                    type="number"
                  />
                </VCol>
                <VCol md={6}>
                  {parseInt(this.fields.numberOfCallAttemps) !== 1 && (
                    <VTextField
                      vModel={this.fields.retryCallAfterMinutes}
                      label="Retry call after (min)"
                      rules={[isRequired, (val: string) => minValue(val, 1)]}
                      type="number"
                    />
                  )}
                </VCol>
              </VRow>
            </VForm>
          </VStepperContent>
        </VStepperItems>
      </VStepper>
    );
  },
});

export default CampaignCreateForm;
