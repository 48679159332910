import ApiEndpoints from "@/constants/apiEndpoints";
import { Modules, Actions, Getters } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import StatisticsDashboard from "../StatisticsDashboard";

const TenantStatistics = Vue.extend({
  props: {
    withTenantSelection: Boolean,
  },

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_TENANT]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.TENANT_STATISTICS_FETCH]),
  },

  render(): VNode {
    return (
      <StatisticsDashboard
        data={this.INSPECTED_TENANT?.statistics ?? {}}
        fetchData={this.TENANT_STATISTICS_FETCH}
        fetchParams={{ idTenant: this.$route.params.idTenant }}
        fetchCsvDataRoute={ApiEndpoints.CSV_OVERVIEW_TENANT_STATISTICS}
        fetchCsvReportsRoute={ApiEndpoints.CSV_TENANT_STATISTICS}
        fetchAction={Actions.TENANT_STATISTICS_FETCH}
        withTenantSelection={this.withTenantSelection}
      />
    );
  },
});

export default TenantStatistics;
