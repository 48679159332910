import Dialogs from "@/constants/dialogs";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VBtn, VCard, VCardText, VIcon, VSimpleTable } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import SectionToolbar from "../SectionToolbar";
import TenantNotificationGroupDialog from "../TenantNotificationGroupDialog";
import TenantNotificationGroupRemoveDialog from "@/components/TenantNotificationGroupRemoveDialog";

interface notificationGroup {
  name: string;
  typeName: string;
  options: Array<string>;
}

const TenantNotificationGroups = Vue.extend({
  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_TENANT]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [
      Actions.TENANT_FETCH_NOTIFICATION_GROUPS,
    ]),

    async fetchData() {
      this.TENANT_FETCH_NOTIFICATION_GROUPS({
        idTenant: this.$route.params.idTenant,
      });
    },
  },

  created() {
    this.fetchData();
  },

  render(): VNode {
    return (
      <div>
        <SectionToolbar
          title="Notification groups"
          actions={[
            {
              action: () => toggleDialog(Dialogs.TENANT_NOTIFICATION_GROUP, {}),
              title: "Add group",
              icon: "mdi-plus",
            },
          ]}
        />

        <VCard flat>
          <VCardText>
            {this.INSPECTED_TENANT?.notifyGroups?.content.length > 0 ? (
              <VSimpleTable>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Contacts</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.INSPECTED_TENANT?.notifyGroups?.content.map(
                    (
                      { name, typeName, options }: notificationGroup,
                      index: number
                    ) => (
                      <tr>
                        <td>{name}</td>
                        <td>{typeName}</td>
                        <td style="white-space: pre-line;">
                          {options.map((contact: string) => {
                            return contact.length > 65
                              ? contact.slice(0, 62) + "...\n"
                              : contact + "\n";
                          })}
                        </td>
                        <td>
                          <VBtn
                            class="me-1 tertiary white--text"
                            fab
                            xSmall
                            elevation={2}
                            onClick={() =>
                              toggleDialog(Dialogs.TENANT_NOTIFICATION_GROUP, {
                                index,
                              })
                            }
                          >
                            <VIcon>mdi-pencil</VIcon>
                          </VBtn>
                          <VBtn
                            class="tertiary white--text"
                            fab
                            xSmall
                            elevation={2}
                            onClick={() =>
                              toggleDialog(
                                Dialogs.TENANT_NOTIFICATION_GROUP_REMOVE,
                                {
                                  name,
                                }
                              )
                            }
                          >
                            <VIcon>mdi-delete</VIcon>
                          </VBtn>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </VSimpleTable>
            ) : (
              <span>Tenant has no notification groups yet.</span>
            )}
          </VCardText>
        </VCard>
        <TenantNotificationGroupDialog />
        <TenantNotificationGroupRemoveDialog />
      </div>
    );
  },
});

export default TenantNotificationGroups;
