import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapGetters } from "vuex";
import EditableOverviewSection from "../EditableOverviewSection";
import { formatDate } from "@/helpers/dateAndTimeUtils";
import { VDivider } from "vuetify/lib";
import UserEditOverviewForm from "../UserEditOverviewForm";
import ChangePasswordDialog from "../ChangePasswordDialog";
import Routes from "@/constants/routes";
import UserConnectedAccounts from "../UserConnectedAccounts";

const UserOverview = Vue.extend({
  data: () => ({
    basic: {},
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_USER]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  created() {
    this.basic = {
      title: "Overview",
      modificators: {
        fullName: ({ firstname, lastname }: any) => `${firstname} ${lastname}`,
        created: ({ created }: any) => formatDate(created),
        lastLogin: ({ lastLogin }: any) => formatDate(lastLogin),
        tenant: ({ tenant, idTenant }: any) => (
          <router-link
            class="tertiary--text"
            to={{
              name: Routes.TENANT_DETAIL_BASIC,
              params: { idTenant },
            }}
          >
            {tenant}
          </router-link>
        ),
      },
      columns: [
        { name: "fullName", title: "Full name" },
        { name: "username", title: "User name" },
        { name: "tenant", title: "Tenant" },
        { name: "email", title: "Email" },
        { name: "created", title: "Created at" },
        { name: "lastLogin", title: "Last login at" },
      ],
    };
  },

  render(): VNode {
    return (
      <div>
        <EditableOverviewSection
          configuration={this.basic}
          data={this.INSPECTED_USER?.basic}
          editDialog={Dialogs.USER_EDIT_BASIC}
          action={Actions.USER_OVERVIEW_EDIT}
          scopedSlots={{ form: () => <UserEditOverviewForm ref="form" /> }}
          isDataFetching={this.IS_ACTION_FETCHING(Actions.USER_OVERVIEW_FETCH)}
        />
        {!this.$route.params.idUser && (
          <div>
            <VDivider class="mb-5" />
            <UserConnectedAccounts />
          </div>
        )}

        <ChangePasswordDialog />
      </div>
    );
  },
});

export default UserOverview;
