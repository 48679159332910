import { ActionTree } from "vuex";
import { Actions, Modules, Mutations } from "@/models/store";
import InstancesState from "@/models/store/InstancesState";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Routes from "@/constants/routes";
import DataGrids from "@/constants/dataGrids";
import createRequest from "@/helpers/createRequest";
import parseResponse from "@/helpers/parseResponse";
import toggleDialog from "@/helpers/toggleDialog";
import pushNotify from "@/helpers/pushNotify";
import Notify from "@/constants/notifications";
import generateErrorMsg from "@/helpers/generateErrorMsg";

const flowActions: ActionTree<InstancesState, []> = {
  [Actions.FLOW_OVERVIEW_FETCH]: ({ commit, dispatch }, { idCallScript }) => {
    dispatch(Actions.SECTION_DETAIL_FETCH, {
      url: ApiEndpoints.FLOW_BASIC,
      action: Actions.FLOW_OVERVIEW_FETCH,
      params: { idCallScript },
      withTitleChange: true,
      modify: (data: any) =>
        commit(Mutations.SET_INSPECTED_FLOW, {
          basic: data,
        }),
      error: () => commit(Mutations.SET_INSPECTED_FLOW, {}),
    });
  },

  [Actions.FLOW_DUPLICATE]: ({ dispatch }, idCallScript) =>
    dispatch(Actions.INSTANCE_DUPLICATE, {
      action: Actions.FLOW_DUPLICATE,
      param: { id: "idCallScript", value: idCallScript },
      prefix: "Flow",
      dialog: Dialogs.FLOW_DUPLICATE,
      redirectRoute: Routes.FLOW_DETAIL_BASIC,
      endpoint: ApiEndpoints.FLOW_DUPLICATE,
    }),

  [Actions.FLOW_REMOVE]: ({ dispatch }, { idCallScript, isDetailActive }) =>
    dispatch(Actions.INSTANCE_REMOVE, {
      params: { idCallScript },
      isDetailActive,
      endpoint: ApiEndpoints.FLOW_REMOVE,
      action: Actions.FLOW_REMOVE,
      redirectRoute: Routes.FLOW_OVERVIEW,
      dataGrid: DataGrids.CALL_SCRIPTS,
      dataGridFetch: Actions.CALLSCRIPTS_DATAGRID_FETCH,
      dialog: Dialogs.FLOW_REMOVE,
      prefix: "Flow",
    }),

  [Actions.FLOW_SWITCH_VERSION]: async (
    { dispatch },
    { idCallScript, idCallScriptHistory }
  ): Promise<void> => {
    const requestRes = await createRequest({
      action: Actions.FLOW_SWITCH_VERSION,
      method: "GET",
      url: ApiEndpoints.FLOW_SWITCH_VERSION,
      params: { idCallScript, idCallScriptHistory },
    });

    const { errors } = parseResponse(requestRes);

    if (errors.length === 0) {
      toggleDialog(Dialogs.FLOW_SWITCH_VERSION, {});
      pushNotify(Notify.SUCCESS, "Flow version switched successfully");
      dispatch(
        `${Modules.DATA_GRIDS}/${Actions.DATAGRID_FETCH_WITH_CURRENT_PARAMS}`,
        {
          dataGrid: DataGrids.FLOW_VERSION_HISTORY,
          fetchActionId: Actions.FLOW_VERSION_HISTORY_DATAGRID_FETCH,
        },
        { root: true }
      );
    }
  },

  [Actions.FLOW_VERSION_DIFF]: async (
    _,
    { idCallScript, idCallScriptHistory }
  ) => {
    const requestRes = await createRequest({
      action: Actions.FLOW_VERSION_DIFF,
      method: "GET",
      url: ApiEndpoints.FLOW_VERSION_DIFF,
      params: { idCallScript, idCallScriptHistory },
    });

    const { data, errors } = parseResponse(requestRes);

    if (errors.length === 0) {
      toggleDialog(Dialogs.FLOW_VERSION_DIFF, { ...data });
    } else {
      pushNotify(Notify.ERROR, generateErrorMsg(Actions.FLOW_VERSION_DIFF));
    }
  },
};

export default flowActions;
