import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";

const FlowSwitchVersionDialog = Vue.extend({
  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.FLOW_SWITCH_VERSION]),

    handleSwitchVersion() {
      const { idCallScript, idCallScriptHistory } = this.GET_DIALOG_DATA(
        Dialogs.FLOW_SWITCH_VERSION
      );

      this.FLOW_SWITCH_VERSION({ idCallScript, idCallScriptHistory });
    },
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.FLOW_SWITCH_VERSION}
        title="Switch version"
        closeOnEsc
        actions={[
          {
            title: "Apply",
            icon: "mdi-check",
            action: this.handleSwitchVersion,
            id: Actions.FLOW_SWITCH_VERSION,
          },
        ]}
      >
        Are you sure to revert to this version? Current call script state will
        be added to history.
      </Dialog>
    );
  },
});

export default FlowSwitchVersionDialog;
