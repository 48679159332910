const tariffCurrencies = [
  {
    text: "CZK",
    value: "CZK",
  },
  {
    text: "EUR",
    value: "EUR",
  },
  {
    text: "USD",
    value: "USD",
  },
  {
    text: "PLN",
    value: "PLN",
  },
];

export default tariffCurrencies;
