import Dialogs from "@/constants/dialogs";
import { Actions } from "@/models/store";
import Vue, { VNode } from "vue";
import Dialog from "../Dialog";
import LineCreateForm from "../LineCreateForm";

const LineCreateDialog = Vue.extend({
  props: {
    idDialModule: [String, Number],
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.LINE_CREATE}
        title="New Line"
        actions={[
          {
            title: "Create",
            icon: "mdi-plus",
            action: () => {
              const refs: any = this.$refs;
              refs.form.handleSubmit();
            },
            id: Actions.LINE_CREATE,
          },
        ]}
      >
        <LineCreateForm ref="form" idDialModule={this.idDialModule} />
      </Dialog>
    );
  },
});

export default LineCreateDialog;
