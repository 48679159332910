import Dialogs from "@/constants/dialogs";
import toggleDialog from "@/helpers/toggleDialog";
import { Modules, Getters, Actions } from "@/models/store";
import Vue, { VNode } from "vue";
import { VCard, VCardText, VCol, VRow, VSkeletonLoader } from "vuetify/lib";
import { mapGetters, mapActions } from "vuex";
import SectionToolbar from "../SectionToolbar";
import UserAccountCard from "../UserAccountCard";
import UserAccountsDialog from "../UserAccountsDialog";

const UserConnectedAccounts = Vue.extend({
  computed: {
    ...mapGetters(Modules.USER, [Getters.USER_ACCOUNTS]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),

    connectedAccounts(): any[] {
      return this.USER_ACCOUNTS.filter(({ connected }: any) => connected);
    },

    notConnectedAccounts(): any[] {
      return this.USER_ACCOUNTS.filter(({ connected }: any) => !connected);
    },
  },

  methods: {
    ...mapActions(Modules.USER, [Actions.USER_ACCOUNTS_FETCH]),
  },

  created() {
    this.USER_ACCOUNTS_FETCH();
  },

  render(): VNode {
    return (
      <div>
        <SectionToolbar
          title="Connected accounts"
          actions={
            this.notConnectedAccounts.length > 0
              ? [
                  {
                    icon: "mdi-plus",
                    action: () =>
                      toggleDialog(Dialogs.USER_CONNECTED_ACCOUNTS, {
                        accounts: this.notConnectedAccounts,
                      }),
                  },
                ]
              : null
          }
        />
        <VCard flat>
          <VCardText class="pt-0">
            {this.IS_ACTION_FETCHING(Actions.USER_ACCOUNTS_FETCH) ? (
              <VSkeletonLoader type="heading" />
            ) : (
              <VRow>
                <VCol col={12} md={6}>
                  {this.connectedAccounts.length > 0 ? (
                    <div>
                      {this.connectedAccounts.map((account) => (
                        <UserAccountCard isActive {...{ props: account }} />
                      ))}
                    </div>
                  ) : (
                    <span class="text-subtitle-1">
                      There are no connected accounts to this profile.
                    </span>
                  )}
                </VCol>
              </VRow>
            )}
          </VCardText>
        </VCard>
        <UserAccountsDialog />
      </div>
    );
  },
});

export default UserConnectedAccounts;
