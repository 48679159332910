import { getStorageItem } from "@/helpers/userLocalStorage";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VDatePicker, VMenu, VSelect, VTextField } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";

const CsvExportDialog = Vue.extend({
  data: () => ({
    dates: [] as string[],
    isDatepickerActive: false,
    charsetEncoding: getStorageItem("csvEncoding") || "utf-8",
    encodingOptions: [
      { text: "UTF-8", value: "utf-8" },
      { text: "CP-1250", value: "cp-1250" },
    ],
  }),

  props: {
    name: String,
    prefix: String,
    apiEndpoint: String,
    withoutRange: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    dateTextRange(): string {
      return this.dates.join(" ~ ");
    },

    instanceId(): string {
      return this.GET_DIALOG_DATA(this.name)?.id;
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.GENERATE_CSV_REPORTS]),

    setDefaultDateRange(): void {
      const dialogData = this.GET_DIALOG_DATA(this.name);
      dialogData?.range ? (this.dates = dialogData.range) : null;
    },
  },

  render(): VNode {
    return (
      <Dialog
        id={this.name}
        title="Generate CSV reports"
        toggleHandler={this.setDefaultDateRange}
        closeOnEsc
        actions={[
          {
            action: () =>
              this.GENERATE_CSV_REPORTS({
                id: this.instanceId,
                idProperty: this.GET_DIALOG_DATA(this.name)?.idProperty ?? null,
                url: this.apiEndpoint,
                from: this.dates[0],
                to: this.dates[1],
                prefix: this.prefix,
                dialog: this.name,
                encoding: this.charsetEncoding,
                fetchParams: this.GET_DIALOG_DATA(this.name)?.fetchParams ?? {},
                withoutRange: this.withoutRange,
                customFileName:
                  this.GET_DIALOG_DATA(this.name)?.customFileName ?? null,
              }),
            id: Actions.GENERATE_CSV_REPORTS,
            title: "Download",
            icon: "mdi-download",
          },
        ]}
      >
        {!this.withoutRange && (
          <VMenu
            vModel={this.isDatepickerActive}
            offsetY
            transition="scale-transition"
            closeOnContentClick={false}
            maxWidth="290"
            scopedSlots={{
              activator: ({ on, attrs }: any) => (
                <VTextField
                  vModel={this.dateTextRange}
                  label="Select range"
                  readonly
                  prependIcon="mdi-calendar"
                  {...{ on, attrs }}
                />
              ),
            }}
          >
            <VDatePicker
              vModel={this.dates}
              onInput={(val: string) =>
                val.length === 2 ? (this.isDatepickerActive = false) : null
              }
              range
              firstDayOfWeek={1}
            />
          </VMenu>
        )}

        <VSelect
          vModel={this.charsetEncoding}
          label="Encoding"
          prependIcon="mdi-file-code"
          items={this.encodingOptions}
        />
      </Dialog>
    );
  },
});

export default CsvExportDialog;
