import Vue, { VNode } from "vue";
import {
  VBtn,
  VCard,
  VCardText,
  VIcon,
  VSpacer,
  VTimeline,
  VTimelineItem,
} from "vuetify/lib";
import { DIAL_LOG_STATUS_TYPE } from "@/constants/valueIcons";
import VuetifyAudio from "vuetify-audio";

const DialLogTimeline = Vue.extend({
  props: {
    data: Array,
    startDate: String,
    record: { type: Object, required: false },
  },

  data: () => ({
    showAsChat: false,
  }),

  methods: {
    getAudioTime(timestamp: string): string {
      const timeDifference =
        new Date(timestamp).getTime() - new Date(this.startDate).getTime();
      const timeInSeconds = Math.round(timeDifference / 1000);

      return this.formatTime(timeInSeconds);
    },

    formatTime(seconds: number): string {
      let formattedSeconds = 0;
      let formattedMinutes = 0;

      if (seconds < 60) {
        formattedSeconds = seconds;
      } else {
        formattedMinutes = Math.trunc(seconds / 60);
        formattedSeconds = seconds % 60;
      }

      return `${
        formattedMinutes > 9 ? formattedMinutes : `0${formattedMinutes}`
      }:${formattedSeconds > 9 ? formattedSeconds : `0${formattedSeconds}`}`;
    },
  },

  render(): VNode {
    return (
      <div>
        <div class="d-flex align-center">
          <h3 class="text-h6 primary--text">Conversation flow</h3>
          <VSpacer />
          <VBtn
            onClick={() => (this.showAsChat = !this.showAsChat)}
            class="secondary primary--text"
            rounded
          >
            <VIcon left>
              {this.showAsChat
                ? "mdi-format-list-bulleted"
                : "mdi-forum-outline"}
            </VIcon>
            Display as {this.showAsChat ? "list" : "chat"}
          </VBtn>
        </div>

        {this.record && (
          <VuetifyAudio
            class="mt-1"
            flat
            file={this.record.audio}
            color="tertiary"
          />
        )}

        <VTimeline alignTop dense={!this.showAsChat} class="px-1">
          {this.data?.map(({ timeStamp, type, activity }: any) =>
            this.showAsChat ? (
              <VTimelineItem
                small
                fillDot
                icon={DIAL_LOG_STATUS_TYPE[type]?.icon}
                color={DIAL_LOG_STATUS_TYPE[type]?.color}
                right={type === 5}
                left={type !== 5}
              >
                <VCard>
                  <VCardText class="pa-3">
                    <div class="font-weight-normal">
                      <strong
                        class={`${DIAL_LOG_STATUS_TYPE[type]?.color}--text`}
                      >
                        {DIAL_LOG_STATUS_TYPE[type]?.name}
                      </strong>
                      &nbsp;|&nbsp;
                      <strong>{this.getAudioTime(timeStamp)}</strong>
                      &nbsp;-&nbsp;
                      {new Date(timeStamp).toLocaleTimeString()}
                    </div>
                    {activity && <div>{activity}</div>}
                  </VCardText>
                </VCard>
              </VTimelineItem>
            ) : (
              <VTimelineItem
                small
                fillDot
                icon={DIAL_LOG_STATUS_TYPE[type]?.icon}
                color={DIAL_LOG_STATUS_TYPE[type]?.color}
              >
                <div>
                  <div class="font-weight-normal">
                    <strong
                      class={`${DIAL_LOG_STATUS_TYPE[type]?.color}--text`}
                    >
                      {DIAL_LOG_STATUS_TYPE[type]?.name}
                    </strong>
                    &nbsp;|&nbsp;
                    <strong>{this.getAudioTime(timeStamp)}</strong>
                    &nbsp;-&nbsp;
                    {new Date(timeStamp).toLocaleTimeString()}
                  </div>
                  {activity && <div>{activity}</div>}
                </div>
              </VTimelineItem>
            )
          )}
        </VTimeline>
      </div>
    );
  },
});

export default DialLogTimeline;
