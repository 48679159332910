import Dialogs from "@/constants/dialogs";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VFileInput, VForm, VAutocomplete } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";

const CampaignManageContacts = Vue.extend({
  data: () => ({
    contactsFile: null,
    isFormValid: false,
    delimiter: ",",
  }),

  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    instanceData(): any {
      return this.GET_DIALOG_DATA(Dialogs.CAMPAIGN_MANAGE_CONTACTS);
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [
      Actions.CAMPAIGN_IMPORT_CONTACTS,
      Actions.CAMPAIGN_OVERVIEW_FETCH,
    ]),

    async handleCsvImport(): Promise<void> {
      const importData = await this.CAMPAIGN_IMPORT_CONTACTS({
        idCampaign: this.instanceData?.idCampaign,
        contactsFile: this.contactsFile,
        delimiter: this.delimiter,
      });

      if (importData) {
        toggleDialog(Dialogs.CAMPAIGN_MANAGE_CONTACTS);
        if (importData.errors.length > 0)
          toggleDialog(Dialogs.CAMPAIGN_ERROR_CONTACTS, {
            errors: importData.errors,
          });
        this.CAMPAIGN_OVERVIEW_FETCH({
          idCampaign: this.instanceData?.idCampaign,
        });
      }
    },
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.CAMPAIGN_MANAGE_CONTACTS}
        title="Add contacts"
        closeOnEsc
        actions={[
          {
            title: "Import",
            icon: "mdi-import",
            id: Actions.CAMPAIGN_IMPORT_CONTACTS,
            action: () => this.handleCsvImport(),
          },
        ]}
      >
        <VForm ref="form" vModel={this.isFormValid}>
          <VFileInput
            label="Browse file (.csv, .xlsx, .txt)"
            vModel={this.contactsFile}
            accept="text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain"
          />
          <VAutocomplete
            class="ml-2"
            vModel={this.delimiter}
            items={[",", ";"]}
            label="Delimiter used inside the file:"
          />
        </VForm>
      </Dialog>
    );
  },
});

export default CampaignManageContacts;
