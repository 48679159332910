import Dialogs from "@/constants/dialogs";
import toggleDialog from "@/helpers/toggleDialog";
import ActionHandler from "@/models/actions/ActionHandler";
import BotDeployConfigActionsParams from "@/models/actions/BotDeployConfigActionsParams";

const useBotDeployConfigActions = ({
  name,
}: BotDeployConfigActionsParams): ActionHandler[] => [
  {
    icon: "mdi-delete",
    title: "Remove",
    action: () => toggleDialog(Dialogs.BOTDEPLOY_CONFIG_REMOVE, { name }),
  },
];

export default useBotDeployConfigActions;
