export const DEFAULT_TITLE = "Vocalls";

const Routes = {
  FLOW_OVERVIEW: "FLOW_OVERVIEW",
  ADMINISTRATION: "ADMINISTRATION",

  CAMPAIGN: "CAMPAIGN",
  CAMPAIGN_DETAIL: "CAMPAIGN_DETAIL",

  MODULE_OVERVIEW: "MODULE_OVERVIEW",
  MODULE_DETAIL_BASIC: "MODULE_DETAIL_BASIC",
  MODULE_DETAIL_LINES: "MODULE_DETAIL_LINES",
  MODULE_DETAIL_DIAL_LOGS: "MODULE_DETAIL_DIAL_LOGS",

  LINES_OVERVIEW: "LINES_OVERVIEW",
  LINE_DETAIL_BASIC: "LINE_DETAIL_BASIC",
  LINE_DETAIL_MONITORING: "LINE_DETAIL_MONITORING",
  LINE_DETAIL_STATISTICS: "LINE_DETAIL_STATISTICS",
  LINE_DETAIL_DIAL_LOGS: "LINE_DETAIL_DIAL_LOGS",
  LINE_DETAIL_LINE_QUEUE: "LINE_DETAIL_LINE_QUEUE",

  GRAMMARS_OVERVIEW: "GRAMMARS_OVERVIEW",
  GRAMMARS_DETAIL_BASIC: "GRAMMARS_DETAIL_BASIC",

  TTS_GENERATOR: "TTS_GENERATOR",
  PACKAGE_MANAGER: "PACKAGE_MANAGER",
  AUDIT_LOG: "AUDIT_LOG",

  USER_PROFILE_BASIC: "USER_PROFILE_BASIC",
  USER_PROFILE_PERMISSIONS: "USER_PROFILE_PERMISSIONS",
  USER_PROFILE_SECURITY: "USER_PROFILE_SECURITY",

  USERS_OVERVIEW: "USERS_OVERVIEW",
  USER_DETAIL_BASIC: "USER_DETAIL_BASIC",
  USER_DETAIL_PERMISSIONS: "USER_DETAIL_PERMISSIONS",
  USER_DETAIL_SECURITY: "USER_DETAIL_SECURITY",

  PACKAGE_MANAGER_INSTALL: "PACKAGE_MANAGER_INSTALL",
  PACKAGE_MANAGER_CREATE: "PACKAGE_MANAGER_CREATE",

  LANGUAGES_OVERVIEW: "LANGUAGES_OVERVIEW",
  LANGUAGE_DETAIL_BASIC: "LANGUAGE_DETAIL_BASIC",

  TENANTS_OVERVIEW: "TENANTS_OVERVIEW",
  TENANT_DETAIL_BASIC: "TENANT_DETAIL_BASIC",
  TENANT_DETAIL_USERS: "TENANT_DETAIL_USERS",
  TENANT_DETAIL_STATISTICS: "TENANT_DETAIL_STATISTICS",
  TENANT_DETAIL_BILLING: "TENANT_DETAIL_BILLING",
  TENANT_DETAIL_NOTIFICATIONS: "TENANT_DETAIL_NOTIFICATIONS",
  TENANT_DETAIL_TARIFFS: "TENANT_DETAIL_TARIFFS",

  COMPONENTS: "COMPONENTS",

  REPORTING: "REPORTING",

  TENANT_STATISTICS_ALL: "TENANT_STATISTICS_ALL",

  BILLING_OVERVIEW: "BILLING_OVERVIEW",
  BILLING_TARIFFS: "BILLING_TARIFFS",

  PERMISSION_GROUPS_OVERVIEW: "PERMISSION_GROUPS_OVERVIEW",
  PERMISSION_GROUP_DETAIL_BASIC: "PERMISSION_GROUP_DETAIL_BASIC",

  FLOW_DETAIL_BASIC: "FLOW_DETAIL_BASIC",
  FLOW_DETAIL_VERSION_HISTORY: "FLOW_DETAIL_VERSION_HISTORY",
  FLOW_DETAIL_DIAL_LOGS: "FLOW_DETAIL_DIAL_LOGS",

  CONVERSATION_DETAILS: "CONVERSATION_DETAILS",

  BOTDEPLOY_MODULES: "BOTDEPLOY_MODULES",
  BOTDEPLOY_MODULE_OVERVIEW: "BOTDEPLOY_MODULE_OVERVIEW",
  BOTDEPLOY_MODULE_DETAIL_BASIC: "BOTDEPLOY_MODULE_DETAIL_BASIC",

  BOTDEPLOY_SERVERS: "BOTDEPLOY_SERVERS",
  BOTDEPLOY_SERVER_OVERVIEW: "BOTDEPLOY_SERVER_OVERVIEW",
  BOTDEPLOY_SERVER_DETAIL_BASIC: "BOTDEPLOY_SERVER_DETAIL_BASIC",

  BOTDEPLOY_CONFIGS: "BOTDEPLOY_CONFIGS",
  BOTDEPLOY_CONFIG_OVERVIEW: "BOTDEPLOY_CONFIG_OVERVIEW",
  BOTDEPLOY_CONFIG_DETAIL_BASIC: "BOTDEPLOY_CONFIG_DETAIL_BASIC",

  NOT_AUTHORIZED: "NOT_AUTHORIZED",
};

export default Routes;
