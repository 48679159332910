import Dialogs from "@/constants/dialogs";
import { isEqualTo } from "@/helpers/validations";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VForm, VTextField } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";

const LineRemoveDialog = Vue.extend({
  data: () => ({
    isFormValid: true,
    lineName: "",
  }),

  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    instanceData(): any {
      return this.GET_DIALOG_DATA(Dialogs.LINE_REMOVE);
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.LINE_REMOVE]),

    handleConfirmation(): void {
      const refs: any = this.$refs;
      const isFormValid = refs.form.validate();

      isFormValid
        ? this.LINE_REMOVE({
            idDialModuleLine: this.instanceData.id,
            isDetailActive: !!this.$route.params.idDialModuleLine,
          })
        : null;
    },
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.LINE_REMOVE}
        title="Confirmation"
        closeOnEsc
        actions={[
          {
            action: () => this.handleConfirmation(),
            id: Actions.LINE_REMOVE,
            title: "Remove",
            icon: "mdi-delete",
          },
        ]}
        toggleHandler={(isActive: boolean) =>
          isActive ? (this.lineName = "") : null
        }
      >
        Are you sure to remove "
        <strong class="primary--text">{this.instanceData.name}</strong>" line?
        By removing the line, all its calls will be deleted permanently. If you
        want to continue, write the line name "
        <strong class="primary--text">{this.instanceData.name}</strong>" to a
        field below and press "<strong class="primary--text">Remove</strong>"
        button.
        <VForm ref="form" class="mt-3" vModel={this.isFormValid}>
          <VTextField
            ref="nameField"
            vModel={this.lineName}
            label="Line name"
            rules={[(val: string) => isEqualTo(val, this.instanceData.name)]}
          />
        </VForm>
      </Dialog>
    );
  },
});

export default LineRemoveDialog;
