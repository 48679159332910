const parseJsonOrNull = (dataString: string): any => {
  let parsedJson = {};

  try {
    parsedJson = JSON.parse(dataString);
  } catch {
    return null;
  }

  return parsedJson;
};

export default parseJsonOrNull;
