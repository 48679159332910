enum Modules {
  API_CALLS = "apiCalls",
  DATA_GRIDS = "dataGrids",
  USER = "user",
  DIALOGS = "dialogs",
  INSTANCES = "instances",
  NOTIFICATION = "notification",
  DROPDOWNS = "dropdowns",
  RESOURCES = "resources",
}

export default Modules;
