import Routes from "@/constants/routes";
import Vue, { VNode } from "vue";
import { VCard, VCardText, VIcon } from "vuetify/lib";

const StatisticsCards = Vue.extend({
  data: () => ({
    items: [
      {
        id: "totalCampaignCount",
        title: "Campaigns count",
        icon: "mdi-bullhorn-outline",
        value: "",
        valuePerex: { title: "(show)", route: Routes.CAMPAIGN },
      },
      {
        id: "activeCampaignsCount",
        title: "Active campaigns",
        icon: "mdi-bullhorn",
        value: "",
      },
      {
        id: "totalRecognition15sTimeSlots",
        title: "Recognition (15s slot)",
        icon: "mdi-ear-hearing",
        value: "",
      },
      {
        id: "totalSMS",
        title: "SMS count",
        icon: "mdi-message-processing",
        value: "",
      },
    ],
  }),

  methods: {
    fetchCardData(data: any) {
      for (const [key, val] of Object.entries(data)) {
        const cardItemIndex = this.items.findIndex(({ id }) => id === key);
        const cardValue: any = val;

        cardItemIndex >= 0
          ? (this.items[cardItemIndex].value = cardValue.toLocaleString("cs"))
          : null;
      }
    },
  },

  render(): VNode {
    return (
      <div class="d-flex">
        {this.items?.map(({ title, value, icon, valuePerex }: any) => (
          <VCard class="my-5 mx-2" style="flex: 1">
            <VCardText class="d-flex align-center justify-space-between">
              <div>
                <div class="text-h5 primary--text font-weight-bold">
                  {value ?? 0}{" "}
                  {valuePerex && (
                    <router-link
                      class="tertiary--text text-subtitle-2"
                      to={{
                        name: valuePerex.route,
                        query: { idTenant: this.$route.params.idTenant },
                      }}
                    >
                      {valuePerex.title}
                    </router-link>
                  )}
                </div>
                <div class="font-weight-bold">{title}</div>
              </div>
              <VIcon class="mr-2" color="primary" xLarge>
                {icon}
              </VIcon>
            </VCardText>
          </VCard>
        ))}
      </div>
    );
  },
});

export default StatisticsCards;
