import { Module } from "vuex";

import NotificationState from "@/models/store/NotificationState";
import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

const notification: Module<NotificationState, []> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default notification;
