import { Modules, Getters } from "@/models/store";
import Vue, { VNode } from "vue";
import { VMain, VContainer, VProgressCircular } from "vuetify/lib";
import { mapGetters } from "vuex";
import Notification from "../Notification";

const Content = Vue.extend({
  computed: {
    ...mapGetters(Modules.USER, [Getters.IS_USER_SIGNED_IN]),
  },

  render(): VNode {
    return (
      <VMain class="light mt-5 pb-5">
        <Notification />
        <VContainer style="max-width: 1200px">
          {this.IS_USER_SIGNED_IN ? (
            <Router-view />
          ) : (
            <VProgressCircular
              indeterminate
              color="primary"
              class="absolute-center"
              size={125}
              width={10}
            />
          )}
        </VContainer>
      </VMain>
    );
  },
});

export default Content;
