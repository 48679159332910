import ApiEndpoints from "@/constants/apiEndpoints";
import Dropdowns from "@/constants/dropdowns";
import Vue, { VNode } from "vue";
import DynamicSelect from "../DynamicSelect";

const FlowSelect = Vue.extend({
  props: {
    value: [String],
    options: Object,
    canSelectAll: {
      type: Boolean,
      default: false,
    },
    overview: {
      type: Boolean,
      default: false,
    },
  },

  render(): VNode {
    return (
      <DynamicSelect
        name={this.overview ? Dropdowns.FLOW_OVERVIEW : Dropdowns.FLOW}
        label="Flow"
        fetchUrl={
          this.overview
            ? ApiEndpoints.FLOW_LIST_OVERVIEW
            : ApiEndpoints.FLOW_LIST
        }
        onInput={(val: string) => this.$emit("input", val)}
        options={this.options}
        value={this.value}
        canSelectAll={this.canSelectAll}
      />
    );
  },
});

export default FlowSelect;
