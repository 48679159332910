const downloadFile = (data: Blob, name: string, format: string): void => {
  const fileURL = window.URL.createObjectURL(new Blob([data]));
  const fileLink = document.createElement("a");

  fileLink.href = fileURL;
  fileLink.setAttribute("download", `${name}.${format}`);
  document.body.appendChild(fileLink);

  fileLink.click();
  fileLink.remove();
};

export default downloadFile;
