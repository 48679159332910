import Dialogs from "@/constants/dialogs";
import ActionHandler from "@/models/actions/ActionHandler";
import toggleDialog from "@/helpers/toggleDialog";
import { Namespaces, Permissions } from "@/constants/permissions";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import ModuleActionsParams from "@/models/actions/ModuleActionsParams";

const { MODULE } = Namespaces;

const useModuleActions = ({
  name,
  id,
}: ModuleActionsParams): ActionHandler[] => [
  {
    title: "CSV Reports",
    icon: "mdi-microsoft-excel",
    action: () => toggleDialog(Dialogs.MODULE_CSV_EXPORT, { id }),
    isAllowed: hasUserPermissions(MODULE, [Permissions.VIEW]),
  },
  {
    icon: "mdi-delete",
    title: "Remove",
    action: () => toggleDialog(Dialogs.MODULE_REMOVE, { name, id }),
    isAllowed: hasUserPermissions(MODULE, [Permissions.DELETE]),
  },
];

export default useModuleActions;
