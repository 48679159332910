import Dialogs from "@/constants/dialogs";
import { Actions } from "@/models/store";
import Vue, { VNode } from "vue";
import Dialog from "../Dialog";
import PermissionGroupCreateForm from "../PermissionGroupCreateForm";

const PermissionGroupCreateDialog = Vue.extend({
  render(): VNode {
    const refs: any = this.$refs;

    return (
      <Dialog
        id={Dialogs.PERMISSION_GROUP_CREATE}
        title="Create permission group"
        actions={[
          {
            title: "Create",
            icon: "mdi-plus",
            id: Actions.PERMISSION_GROUP_CREATE,
            action: () => refs.form?.handleSubmit(),
          },
        ]}
      >
        <PermissionGroupCreateForm ref="form" />
      </Dialog>
    );
  },
});

export default PermissionGroupCreateDialog;
