import Vue, { VNode } from "vue";
import Dialog from "@/components/Dialog";
import Dialogs from "@/constants/dialogs";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";

const ModuleRemoveDialog = Vue.extend({
  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    moduleData(): any {
      const { name, id } = this.GET_DIALOG_DATA(Dialogs.MODULE_REMOVE);
      const moduleDetailId = this.$route.params.idDialModule;

      return { name, id, moduleDetailId };
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.MODULE_REMOVE]),
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.MODULE_REMOVE}
        title="Confirmation"
        closeOnEsc
        actions={[
          {
            title: "Delete",
            icon: "mdi-delete",
            action: () =>
              this.MODULE_REMOVE({
                idDialModule: this.moduleData.id,
                isDetailActive: !!this.moduleData.moduleDetailId,
              }),
            id: Actions.MODULE_REMOVE,
          },
        ]}
      >
        Do you really want to remove{" "}
        <strong class="primary--text">
          {this.moduleData.name ? `"${this.moduleData.name}"` : "this"}
        </strong>{" "}
        module?
      </Dialog>
    );
  },
});

export default ModuleRemoveDialog;
