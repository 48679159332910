import { Module } from "vuex";

import UserState from "@/models/store/UserState";
import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

const user: Module<UserState, []> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default user;
