import ApiEndpoints from "@/constants/apiEndpoints";
import CAMPAIGN_STATUS from "@/constants/campaignStatus";
import DataGrids from "@/constants/dataGrids";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import Routes from "@/constants/routes";
import createRequest from "@/helpers/createRequest";
import downloadFile from "@/helpers/downloadFile";
import parseResponse from "@/helpers/parseResponse";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Mutations } from "@/models/store";
import InstancesState from "@/models/store/InstancesState";
import { ActionTree } from "vuex";

const campaignActions: ActionTree<InstancesState, []> = {
  [Actions.CAMPAIGN_OVERVIEW_FETCH]: async ({ commit }, { idCampaign }) => {
    const campaignRes = await createRequest({
      action: Actions.CAMPAIGN_OVERVIEW_FETCH,
      method: "GET",
      url: ApiEndpoints.CAMPAIGN_OVERVIEW,
      params: { idCampaign },
    });

    const { data, errors } = parseResponse(campaignRes);

    if (errors.length === 0) {
      commit(Mutations.SET_INSPECTED_CAMPAIGN, { basic: data });
    }
  },

  [Actions.CAMPAIGN_IMPORT_CONTACTS]: async (
    _,
    { idCampaign, contactsFile, delimiter }
  ) => {
    const formData = new FormData();
    formData.append("file", contactsFile);

    const csvRes = await createRequest({
      action: Actions.CAMPAIGN_IMPORT_CONTACTS,
      method: "POST",
      url: ApiEndpoints.CAMPAIGN_IMPORT_CONTACTS,
      headers: {
        "Content-type": "text/csv",
      },
      params: { idCampaign, fileExtension: contactsFile?.type, delimiter },
      data: formData,
    });

    const { data, errors } = parseResponse(csvRes);

    if (errors.length === 0) {
      pushNotify(Notify.SUCCESS, `${data.uploaded} contacts imported`);
      return data;
    }

    return null;
  },

  [Actions.CAMPAIGN_SCHEDULE_EDIT]: async (_, { idCampaign, data }) => {
    const scheduleRes = await createRequest({
      action: Actions.CAMPAIGN_SCHEDULE_EDIT,
      method: "POST",
      url: ApiEndpoints.CAMPAIGN_SCHEDULE,
      params: { idCampaign },
      data,
    });

    const { errors } = parseResponse(scheduleRes);

    if (errors.length === 0) {
      return true;
    }

    return false;
  },

  [Actions.CAMPAIGN_BASIC_EDIT]: async (_, { idCampaign, data }) => {
    const scheduleRes = await createRequest({
      action: Actions.CAMPAIGN_BASIC_EDIT,
      method: "POST",
      url: ApiEndpoints.CAMPAIGN_BASIC,
      params: { idCampaign },
      data,
    });

    const { errors } = parseResponse(scheduleRes);

    if (errors.length === 0) {
      return true;
    }

    return false;
  },

  [Actions.CAMPAIGN_REMOVE]: ({ dispatch }, { idCampaign, isDetailActive }) => {
    dispatch(Actions.INSTANCE_REMOVE, {
      params: { idCampaign },
      isDetailActive,
      endpoint: ApiEndpoints.CAMPAIGN_REMOVE,
      action: Actions.CAMPAIGN_REMOVE,
      redirectRoute: Routes.CAMPAIGN,
      dataGrid: DataGrids.CAMPAIGNS,
      dataGridFetch: Actions.CAMPAIGNS_DATAGRID_FETCH,
      dialog: Dialogs.CAMPAIGN_REMOVE,
      prefix: "Campaign",
    });
  },

  [Actions.CAMPAIGN_STATE_CHANGE]: async (
    { dispatch },
    { idCampaign, desiredStatus }
  ) => {
    const statusRes = await createRequest({
      action: Actions.CAMPAIGN_STATE_CHANGE,
      method: "POST",
      url: ApiEndpoints.CAMPAIGN_STATE_CHANGE,
      params: { idCampaign, status: desiredStatus },
    });

    const { errors } = parseResponse(statusRes);

    if (errors.length === 0) {
      toggleDialog(Dialogs.CAMPAIGN_STATE_CHANGE);
      pushNotify(Notify.SUCCESS, "Campaign status change succesfully");
      dispatch(Actions.CAMPAIGN_OVERVIEW_FETCH, { idCampaign });
    }
  },

  [Actions.CAMPAIGN_VALIDATION_FETCH]: async (_, { idCampaign }) => {
    const statusRes = await createRequest({
      action: Actions.CAMPAIGN_VALIDATION_FETCH,
      method: "GET",
      url: ApiEndpoints.CAMPAIGN_VALIDATION,
      params: { idCampaign },
    });

    const { data, errors } = parseResponse(statusRes);

    if (errors.length !== 0) {
      pushNotify(Notify.ERROR, "No Validation data!!!");
      return false;
    }

    return data;
  },

  [Actions.CAMPAING_STATUS_UPDATE]: ({ getters, commit }, status) => {
    const currentCampaign = getters[Getters.INSPECTED_CAMPAIGN];

    commit(Mutations.SET_INSPECTED_CAMPAIGN, {
      ...currentCampaign,
      basic: {
        ...currentCampaign.basic,
        status,
        statusName:
          CAMPAIGN_STATUS[status]?.title || currentCampaign.basic?.statusName,
      },
    });
  },
};

export default campaignActions;
