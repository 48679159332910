import { MutationTree } from "vuex";
import { Mutations } from "@/models/store";
import UserState from "@/models/store/UserState";

const mutations: MutationTree<UserState> = {
  [Mutations.SET_USER_INFO]: (state, payload) => (state.properties = payload),

  [Mutations.SET_USER_ACCOUNTS]: (state, payload) =>
    (state.connectedAcounts = payload),

  [Mutations.SET_USER_TOKEN]: (state, token) => (state.token = token),

  [Mutations.SET_USER_SIGNED_IN]: (state, isUserSignedIn = true) =>
    (state.isUserSignedIn = isUserSignedIn),
};

export default mutations;
