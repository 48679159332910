enum Mutations {
  // Api calls
  SET_API_CALL_STATE = "SET_API_CALL_STATE",

  // DataGrids
  SET_DATAGRID_DATA = "SET_DATAGRID_DATA",
  TOGGLE_FAVORITE_FLOWS = "TOGGLE_FAVORITE_FLOWS",

  // User
  SET_USER_INFO = "SET_USER_INFO",
  SET_USER_ACCOUNTS = "SET_USER_ACCOUNTS",
  SET_USER_SIGNED_IN = "SET_USER_SIGNED_IN",
  SET_USER_TOKEN = "SET_USER_TOKEN",

  // Instances
  SET_INSPECTED_MODULE = "SET_INSPECTED_MODULE",
  SET_INSPECTED_LINE = "SET_INSPECTED_LINE",
  SET_INSPECTED_FLOW = "SET_INSPECTED_FLOW",
  SET_INSPECTED_GRAMMAR = "SET_INSPECTED_GRAMMAR",
  SET_INSPECTED_TENANT = "SET_INSPECTED_TENANT",
  SET_INSPECTED_USER = "SET_INSPECTED_USER",
  SET_INSPECTED_PERMISSION_GROUP = "SET_INSPECTED_PERMISSION_GROUP",
  SET_INSPECTED_CAMPAIGN = "SET_INSPECTED_CAMPAIGN",
  SET_INSPECTED_BOTDEPLOY_MODULE = "SET_INSPECTED_BOTDEPLOY_MODULE",
  SET_INSPECTED_BOTDEPLOY_SERVER = "SET_INSPECTED_BOTDEPLOY_SERVER",
  SET_INSPECTED_BOTDEPLOY_CONFIG = "SET_INSPECTED_BOTDEPLOY_CONFIG",
  SET_INSPECTED_LANGUAGE = "SET_INSPECTED_LANGUAGE",

  // Dialogs
  TOGGLE_DIALOG = "TOGGLE_DIALOG",

  // Notification
  SET_NOTIFICATION = "SET_NOTIFICATION",
  TOGGLE_NOTIFICATION = "TOGGLE_NOTIFICATION",

  // Dropdowns
  SET_DROPDOWN_DATA = "SET_DROPDOWN_DATA",

  // Resources
  SET_LANGUAGES = "SET_LANGUAGES",
}

export default Mutations;
