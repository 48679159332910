import Vue, { VNode } from "vue";

const NotFound = Vue.extend({
  render(): VNode {
    return (
      <div class="not-found">
        <h2 class="text-h4 font-weight-bold primary--text mb-2">
          404 - Page not found
        </h2>
        <img src="https://vocalls.cz/downloads/frantaheroin.jpg" />
      </div>
    );
  },
});

export default NotFound;
