import Vue, { VNode } from "vue";
import { VAutocomplete } from "vuetify/lib";

const GrammarTypeSelect = Vue.extend({
  props: {
    value: Number,
  },

  data: () => ({
    items: [
      { value: 1, text: "Keywords" },
      { value: 2, text: "Rules" },
      { value: 3, text: "Sentences" },
      { value: 4, text: "Lexicon" },
      { value: 5, text: "Synonyms" },
      { value: 100, text: "System" },
    ],
  }),
  render(): VNode {
    return (
      <VAutocomplete
        label="Type"
        items={this.items}
        onInput={(val: string | number) => this.$emit("input", val)}
        value={this.value}
      />
    );
  },
});

export default GrammarTypeSelect;
