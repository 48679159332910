import Vue, { VNode } from "vue";
import installReportingWidget from "@/helpers/installReportingWidget";

const Reporting = Vue.extend({
  mounted() {
    installReportingWidget(this.$refs.reportingContainer);
  },

  render(): VNode {
    return <div class="mt-5" ref="reportingContainer" />;
  },
});

export default Reporting;
