import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";

const TenantForceTwoFactorDialog = Vue.extend({
  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    instanceData(): any {
      return this.GET_DIALOG_DATA(Dialogs.TENANT_FORCE_TWO_FACTOR);
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.TENANT_FORCE_TWO_FACTOR]),
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.TENANT_FORCE_TWO_FACTOR}
        title="Confirmation"
        closeOnEsc
        actions={[
          {
            title: "Apply",
            icon: "mdi-check",
            id: Actions.TENANT_FORCE_TWO_FACTOR,
            action: () =>
              this.TENANT_FORCE_TWO_FACTOR({ idTenant: this.instanceData.id }),
          },
        ]}
      >
        Do you really want to enable two factor authentication for all users in
        "
        <strong class="primary--text">
          {this.instanceData?.basic?.invoicingName}
        </strong>
        " tenant? This action cannot be undone.
      </Dialog>
    );
  },
});

export default TenantForceTwoFactorDialog;
