import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { isRequired } from "@/helpers/validations";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VBtn, VForm, VSelect, VTextField } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import TARIFF_TYPES from "@/constants/tariffTypes";
import tariffCurrencies from "@/constants/tariffCurrencies";
import TARIFF_DIVIDER_TYPES from "@/constants/tariffDividerTypes";

const LineTariffEditForm = Vue.extend({
  data: () => ({
    fields: {
      type: 0,
      currency: "CZK",
      price: "",
      smsPrice: "",
      divider: "1",
      roundInterval: "60",
      minDuration: "",
    },
  }),

  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    instanceData(): any {
      return this.GET_DIALOG_DATA(Dialogs.LINE_TARIFF_EDIT_DIALOG);
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.LINE_TARIFF_FETCH]),
    ...mapActions(Modules.DATA_GRIDS, [Actions.TENANT_TARRIFS_DATAGRID_FETCH]),

    async handleSubmit() {
      const { type, ...content } = this.fields;

      const { isFormSubmited } = await submitForm({
        ref: this.$refs.form,
        url: ApiEndpoints.LINE_TARIFF,
        values: { type, content: JSON.stringify(content) },
        params: {
          idDialModuleLine: this.instanceData.idLine,
        },
        actionId: Actions.LINE_TARIFF_EDIT,
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.LINE_TARIFF_EDIT_DIALOG, {});
        pushNotify(Notify.SUCCESS, "Tariff edited successfully");
        this.TENANT_TARRIFS_DATAGRID_FETCH({
          params: {
            idTenant: this.$route.query.idTenant,
          },
        });
      } else {
        pushNotify(Notify.WARNING, "Tariff editation failed");
      }
    },
  },

  created() {
    this.fields = {
      type: this.instanceData?.tariff?.type || 0,
      ...JSON.parse(this.instanceData?.tariff?.content),
    };
  },

  render(): VNode {
    return (
      <VForm
        ref="form"
        onSubmit={(e: Event) => {
          e.preventDefault();
          this.handleSubmit();
        }}
      >
        <VSelect
          vModel={this.fields.currency}
          items={tariffCurrencies}
          label="Currency"
        />
        <VTextField
          rules={[isRequired]}
          vModel={this.fields.price}
          label="Call Price"
        />
        <VSelect vModel={this.fields.type} items={TARIFF_TYPES} label="Type" />
        {this.fields.type == 1 && (
          <VSelect
            vModel={this.fields.divider}
            items={TARIFF_DIVIDER_TYPES}
            label="Divider"
          />
        )}
        {(this.fields.type == 0 && (
          <VTextField
            vModel={this.fields.minDuration}
            label="Minimal Duration"
          />
        )) || (
          <VTextField
            vModel={this.fields.roundInterval}
            label="Round Interval"
          />
        )}
        <VTextField vModel={this.fields.smsPrice} label="SMS Price" />
        <VBtn type="submit" class="d-none"></VBtn>
      </VForm>
    );
  },
});

export default LineTariffEditForm;
