import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VDivider, VSpacer, VSwitch } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";

const UserRolesDialog = Vue.extend({
  data: () => ({
    roles: [],
    fields: {},
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_USER]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.USER_ROLES_FETCH]),

    initRoles(): void {
      this.roles = this.INSPECTED_USER?.roles;

      this.INSPECTED_USER?.roles.map(
        ({ hasRole, name }: any) => (this.fields[name] = hasRole)
      );
    },

    async handleSubmit(): Promise<void> {
      const enabledRoles: string[] = [];
      Object.keys(this.fields).map((role) => {
        if (this.fields[role]) {
          const roleConfig = this.INSPECTED_USER?.roles.find(
            ({ name }: any) => name === role
          );
          enabledRoles.push(roleConfig?.id);
        }
      });

      const { isFormSubmited } = await submitForm({
        values: { roles: enabledRoles },
        url: ApiEndpoints.TENANT_USER_ROLES,
        params: { idUser: this.$route.params.idUser },
        actionId: Actions.USER_ROLES_FETCH,
      });

      if (isFormSubmited) {
        pushNotify(Notify.SUCCESS, "User roles changed successfully");
        toggleDialog(Dialogs.USER_ROLES);
        this.USER_ROLES_FETCH({ idUser: this.$route.params.idUser });
      }
    },
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.USER_ROLES}
        title="Edit user roles"
        actions={[
          {
            title: "Save",
            icon: "mdi-content-save",
            id: Actions.USER_ROLES_FETCH,
            action: () => this.handleSubmit(),
          },
        ]}
        toggleHandler={(isActive: boolean) =>
          isActive ? this.initRoles() : null
        }
      >
        {this.roles?.map(({ name }, index) => (
          <div>
            <div class="d-flex align-center">
              {name}
              <VSpacer />
              <VSwitch vModel={this.fields[name]} />
            </div>
            {index + 1 !== this.roles.length && <VDivider />}
          </div>
        ))}
      </Dialog>
    );
  },
});

export default UserRolesDialog;
