import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import Routes from "@/constants/routes";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import {
  containsSmallLettersOnly,
  hasExactLength,
  isRequired,
} from "@/helpers/validations";
import { Actions } from "@/models/store";
import Vue, { VNode } from "vue";
import { VBtn, VForm, VIcon, VTextField } from "vuetify/lib";
import LanguageVoiceGroupsForm from "../LanguageVoiceGroupsForm";

const LanguageCreateForm = Vue.extend({
  data: () => ({
    fields: {
      name: "",
      language: "",
    },
    isFormValid: true,
    step: 1,
  }),

  methods: {
    async handleSubmit() {
      const refs: any = this.$refs;

      if (this.step === 1) {
        if (refs.langSettings.validate()) {
          this.step = 2;
          this.$emit("stepChange", 2);
        }

        return;
      }

      const voiceGroups = refs.voiceGroups.getValues();

      if (voiceGroups.length === 0) {
        pushNotify(
          Notify.ERROR,
          "Language must contains at least one voice group"
        );
        return;
      }

      if (!refs.voiceGroups.validate()) {
        return;
      }

      const { isFormSubmited, data } = await submitForm({
        url: ApiEndpoints.LANGUAGE,
        values: { ...this.fields, settings: voiceGroups },
        actionId: Actions.LANGUAGE_CREATE,
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.LANGUAGE_CREATE, {});
        pushNotify(Notify.SUCCESS, "Language created successfully");

        this.$router.push({
          name: Routes.LANGUAGE_DETAIL_BASIC,
          params: { idLanguage: data.id },
        });
      }
    },
  },

  render(): VNode {
    return (
      <div>
        {this.step === 1 ? (
          <VForm vModel={this.isFormValid} ref="langSettings">
            <VTextField
              vModel={this.fields.name}
              rules={[isRequired]}
              label="Name"
            />
            <VTextField
              vModel={this.fields.language}
              rules={[
                isRequired,
                containsSmallLettersOnly,
                (val: string) => hasExactLength(val, 2),
              ]}
              label="Language identificator"
            />
          </VForm>
        ) : (
          <LanguageVoiceGroupsForm ref="voiceGroups" />
        )}
      </div>
    );
  },
});

export default LanguageCreateForm;
