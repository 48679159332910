import { ActionTree } from "vuex";
import { Actions, Mutations } from "@/models/store";
import ResourcesState from "@/models/store/ResourcesState";
import createRequest from "@/helpers/createRequest";
import parseResponse from "@/helpers/parseResponse";
import ApiEndpoints from "@/constants/apiEndpoints";

const actions: ActionTree<ResourcesState, []> = {
  [Actions.LANGUAGES_FETCH]: async ({ commit }) => {
    const reqResponse = await createRequest({
      action: Actions.LANGUAGES_FETCH,
      method: "GET",
      url: `${ApiEndpoints.LANGUAGES_STRUCTURE}`,
    });

    const { data, errors } = parseResponse(reqResponse);

    if (errors.length === 0) {
      commit(Mutations.SET_LANGUAGES, data);
      return true;
    } else {
      return false;
    }
  },
};

export default actions;
