import Routes from "@/constants/routes";
import Vue, { VNode } from "vue";
import {
  VBtn,
  VCard,
  VCardText,
  VCardTitle,
  VDataTable,
  VIcon,
} from "vuetify/lib";

const BillingLineDataGrid = Vue.extend({
  props: {
    moduleName: String,
    lines: Array,
  },

  render(): VNode {
    return (
      <VCard class="mx-2 mb-5">
        <VCardTitle class="text-h6">{this.moduleName}</VCardTitle>
        <VCardText>
          {this.lines && (
            <div>
              <VDataTable
                headers={[
                  {
                    text: "Name",
                    value: "line",
                  },
                  {
                    text: "Calls duration (min)",
                    value: "durationInMinutes",
                  },
                  {
                    text: "Calls",
                    value: "calls",
                  },
                  {
                    text: "SMS",
                    value: "smsCount",
                  },
                  {
                    text: "Price",
                    value: "price",
                  },
                  {
                    text: "Actions",
                    value: "actions",
                  },
                ]}
                items={this.lines}
                items-per-page={-1}
                hideDefaultFooter
                scopedSlots={{
                  "item.durationInMinutes": ({ item }: any) =>
                    item.durationInMinutes?.toLocaleString("cs"),
                  "item.calls": ({ item }: any) => (
                    <span>
                      <VIcon color="success" small class="mr-2">
                        mdi-phone
                      </VIcon>
                      {item.calls?.toLocaleString("cs")}
                    </span>
                  ),

                  "item.smsCount": ({ item }: any) => (
                    <span>
                      <VIcon color="blue" small class="mr-2">
                        mdi-message
                      </VIcon>
                      {item.smsCount?.toLocaleString("cs")}
                    </span>
                  ),

                  "item.actions": ({ item }: any) => (
                    <VBtn
                      class="tertiary white--text"
                      fab
                      xSmall
                      disabled={!item.idLine}
                      to={{
                        name: Routes.LINE_DETAIL_STATISTICS,
                        params: { idDialModuleLine: item.idLine },
                      }}
                    >
                      <VIcon>mdi-eye</VIcon>
                    </VBtn>
                  ),
                  "item.price": ({ item }: any) => (
                    <span class="font-weight-bold text-right">
                      {item.price?.toLocaleString("cs")}{" "}
                      {item.currency ? (
                        item.currency
                      ) : (
                        <span class="grey--text">N/A</span>
                      )}
                    </span>
                  ),
                }}
              />
            </div>
          )}
        </VCardText>
      </VCard>
    );
  },
});

export default BillingLineDataGrid;
