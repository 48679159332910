import { ActionTree } from "vuex";
import { Actions, Mutations } from "@/models/store";
import InstancesState from "@/models/store/InstancesState";
import ApiEndpoints from "@/constants/apiEndpoints";
import Routes from "@/constants/routes";
import DataGrids from "@/constants/dataGrids";
import Dialogs from "@/constants/dialogs";

const languageActions: ActionTree<InstancesState, []> = {
  [Actions.LANGUAGE_OVERVIEW_FETCH]: ({ commit, dispatch }, { idLanguage }) => {
    dispatch(Actions.SECTION_DETAIL_FETCH, {
      url: ApiEndpoints.LANGUAGE_OVERVIEW,
      action: Actions.LANGUAGE_OVERVIEW_FETCH,
      params: { idLanguage },
      withTitleChange: true,
      modify: (data: any) =>
        commit(Mutations.SET_INSPECTED_LANGUAGE, {
          basic: data,
        }),
      error: () => commit(Mutations.SET_INSPECTED_LANGUAGE, {}),
    });
  },

  [Actions.LANGUAGE_REMOVE]: ({ dispatch }, { idLanguage, isDetailActive }) =>
    dispatch(Actions.INSTANCE_REMOVE, {
      params: { idLanguage },
      isDetailActive,
      endpoint: ApiEndpoints.LANGUAGE,
      action: Actions.LANGUAGE_REMOVE,
      redirectRoute: Routes.LANGUAGES_OVERVIEW,
      dataGrid: DataGrids.LANGUAGES,
      dataGridFetch: Actions.LANGUAGES_DATAGRID_FETCH,
      dialog: Dialogs.LANGUAGE_REMOVE,
      prefix: "Language",
    }),
};

export default languageActions;
