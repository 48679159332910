import Dialogs from "@/constants/dialogs";
import { Actions } from "@/models/store";
import Vue, { VNode } from "vue";
import Dialog from "../Dialog";
import UserCreateForm from "../UserCreateForm";

const UserCreateDialog = Vue.extend({
  props: {
    idTenant: String,
  },

  render(): VNode {
    const refs: any = this.$refs;

    return (
      <Dialog
        title="Add user"
        id={Dialogs.USER_CREATE}
        actions={[
          {
            title: "Create",
            icon: "mdi-plus",
            action: () => refs.form?.handleSubmit(),
            id: Actions.TENANT_USER_CREATE,
          },
        ]}
      >
        <UserCreateForm ref="form" idTenant={this.idTenant} />
      </Dialog>
    );
  },
});

export default UserCreateDialog;
