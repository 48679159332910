const Dropdowns = {
  MODULES: "MODULES",
  FLOW: "FLOW",
  FLOW_OVERVIEW: "FLOW_OVERVIEW",
  TENANTS: "TENANTS",
  LANGUAGES: "LANGUAGES",
  NOTIFY_GROUPS: "NOTIFY_GROUPS",
  GRAMMARS: "GRAMMARS",
  COMPONENTS: "COMPONENTS",
  LINES: "LINES",
  BOTDEPLOY_SERVERS: "BOTDEPLOY_SERVERS",
  BOTDEPLOY_CONFIGS: "BOTDEPLOY_CONFIGS",
};

export default Dropdowns;
