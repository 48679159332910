import Vue, { VNode } from "vue";
import { VCard, VCardText, VCol, VRow } from "vuetify/lib";
import "./DiffCompareEditor.scss";

const DiffCompareEditor = Vue.extend({
  props: {
    content: Object,
  },

  render(): VNode {
    return (
      <VRow>
        <VCol md={6}>
          <h3 class="text-h6 primary--text">Current version</h3>
          <VCard class="diff-editor">
            <VCardText domPropsInnerHTML={this.content?.current}></VCardText>
          </VCard>
        </VCol>
        <VCol md={6}>
          <h3 class="text-h6 primary--text">Inspected version</h3>
          <VCard class="diff-editor">
            <VCardText domPropsInnerHTML={this.content?.compared}></VCardText>
          </VCard>
        </VCol>
      </VRow>
    );
  },
});

export default DiffCompareEditor;
