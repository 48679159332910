import Dialogs from "@/constants/dialogs";
import VueJsonEditor from "vue-json-editor";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VIcon, VSpacer, VTab, VTabItem, VTabs, VTabsItems } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";
import DialLogTimeline from "../DialLogTimeline";
import DialLogOverview from "../DialLogDetail/DialLogOverview";
import DialLogAlertList from "../DialLogDetail/DialLogAlertList";

const DialLogDetailDialog = Vue.extend({
  data: () => ({
    dialLogData: {} as any,
    rawData: {},
    activeTab: 0,
  }),

  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    errorAndWarningsCount(): number {
      const dialLogData: any = this.dialLogData;
      const errorCount = dialLogData.detail?.errors?.length || 0;
      const warningCount = dialLogData.detail?.warnings?.length || 0;

      return errorCount + warningCount;
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.AUDIO_RECORD_FETCH]),

    initDialogData(): void {
      const { duration, stateName, guid, rawData, idTab } =
        this.GET_DIALOG_DATA(Dialogs.DIAL_LOG_DETAIL);

      this.rawData = rawData;
      this.dialLogData = {
        guid,
        duration,
        stateName,
        ...rawData,
      };

      if (idTab) {
        this.activeTab = parseInt(idTab);
      } else {
        this.setRouteQueries(0);
        this.activeTab = 0;
      }
    },

    setRouteQueries(activeTab: number) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          guid: this.dialLogData?.guid,
          tab: activeTab.toString(),
        },
      });
    },
  },

  render(): VNode {
    const dialLogData: any = this.dialLogData;

    return (
      <Dialog
        id={Dialogs.DIAL_LOG_DETAIL}
        width="700"
        closeOnEsc
        persistent={false}
        toggleHandler={(isActive: boolean) => {
          if (isActive) {
            this.initDialogData();
          } else {
            const routeQuery = { ...this.$route.query };
            delete routeQuery.guid;
            delete routeQuery.tab;

            this.$router.replace({ query: routeQuery });
          }
        }}
        scopedSlots={{
          beforeContent: () => (
            <VTabs
              onChange={(activeTab: number) => this.setRouteQueries(activeTab)}
              vModel={this.activeTab}
              grow
              dark
              backgroundColor="primary"
              class="mb-3"
            >
              <VTab>Timeline</VTab>
              <VTab>Data</VTab>
              <VTab>Source</VTab>
              <VTab>
                Errors{" "}
                {this.errorAndWarningsCount > 0 && (
                  <span class="error white--text rounded-circle px-1 ml-1">
                    {this.errorAndWarningsCount}
                  </span>
                )}
              </VTab>
            </VTabs>
          ),
        }}
      >
        <VTabsItems vModel={this.activeTab}>
          <VTabItem>
            {dialLogData.detail?.speakFlow?.length > 0 ? (
              <DialLogTimeline
                data={dialLogData.detail.speakFlow}
                startDate={dialLogData.detail.speakFlow[0].timeStamp}
                record={dialLogData.record}
              />
            ) : (
              <div>
                <h3 class="text-h6 primary--text mb-2">Conversation flow</h3>
                <VIcon color="error" class="mr-2">
                  mdi-alert-octagon
                </VIcon>
                Timeline is not available.
              </div>
            )}
          </VTabItem>
          <VTabItem>
            <DialLogOverview dialLogData={this.dialLogData} />
          </VTabItem>
          <VTabItem>
            <div class="d-flex align-center mb-3">
              <h3 class="text-h6 primary--text">Conversation source</h3>
              <VSpacer />
              {/* <VBtn
                onClick={() => null}
                class="secondary primary--text"
                rounded
              >
                <VIcon left>mdi-link-variant-plus</VIcon>
                Open in new tab
              </VBtn> */}
            </div>
            <VueJsonEditor vModel={this.rawData} mode="code" />
          </VTabItem>
          <VTabItem>
            {this.errorAndWarningsCount > 0 ? (
              <div>
                {dialLogData.detail?.errors?.length > 0 && (
                  <DialLogAlertList
                    data={dialLogData.detail?.errors}
                    title="Errors"
                    titleColor="error"
                  />
                )}
                {dialLogData.detail?.warnings?.length > 0 && (
                  <DialLogAlertList
                    data={dialLogData.detail?.warnings}
                    title="Warnings"
                    titleColor="warning"
                  />
                )}
              </div>
            ) : (
              <div>
                <h3 class="text-h6 primary--text mb-2">Status</h3>
                <VIcon color="success" class="mr-2">
                  mdi-check-circle
                </VIcon>
                Conversation contains no errors or warnings.
              </div>
            )}
          </VTabItem>
        </VTabsItems>
      </Dialog>
    );
  },
});

export default DialLogDetailDialog;
