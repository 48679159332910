import Dialogs from "@/constants/dialogs";
import toggleDialog from "@/helpers/toggleDialog";
import { Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapGetters } from "vuex";
import LineMonitoringDialog from "../LineMonitoringDialog";
import LineMonitoringOverview from "../LineMonitoringOverview";
import SectionToolbar from "../SectionToolbar";

const LineMonitoring = Vue.extend({
  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_LINE]),
  },

  render(): VNode {
    return (
      <div>
        <SectionToolbar
          title="Monitoring"
          actions={
            this.INSPECTED_LINE?.monitoring?.editableFields?.length > 0
              ? [
                  {
                    action: () => toggleDialog(Dialogs.LINE_MONITORING, {}),
                    title: "Add monitoring",
                    icon: "mdi-plus",
                  },
                ]
              : null
          }
        />
        <LineMonitoringOverview />
        <LineMonitoringDialog />
      </div>
    );
  },
});

export default LineMonitoring;
