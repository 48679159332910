import ApiEndpoints from "@/constants/apiEndpoints";
import Dropdowns from "@/constants/dropdowns";
import Vue, { VNode } from "vue";
import DynamicSelect from "../DynamicSelect";

const BotDeployServerSelect = Vue.extend({
  props: {
    value: String,
  },

  render(): VNode {
    return (
      <DynamicSelect
        name={Dropdowns.BOTDEPLOY_SERVERS}
        label="Server"
        fetchUrl={ApiEndpoints.BOTDEPLOY_SERVERS_LIST}
        onInput={(val: string) => this.$emit("input", val)}
        value={this.value}
      />
    );
  },
});

export default BotDeployServerSelect;
