const ExternalLinks = {
  DESIGNER: process.env.VUE_APP_DESIGNER_URL,
  CAMPAIGN: process.env.VUE_APP_CAMPAIGN_URL,
  DESIGNER_MANUAL: "https://designer.vocalls.cz/manual/",
  AUTHENTICATOR_GOOGLE_PLAY:
    "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
  AUTHENTICATOR_APPLE_STORE:
    "https://apps.apple.com/us/app/google-authenticator/id388497605",
};

export default ExternalLinks;
