import Dialogs from "@/constants/dialogs";
import Vue, { VNode } from "vue";
import Dialog from "../Dialog";
import { mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import CampaignEditForm from "../CampaignEditForm";

const CampaignEditDialog = Vue.extend({
  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.IS_DIALOG_OPEN]),
  },

  render(): VNode {
    const refs: any = this.$refs;

    return (
      <Dialog
        id={Dialogs.CAMPAIGN_EDIT}
        title="Edit campaign"
        actions={[
          {
            id: Actions.CAMPAIGN_EDIT,
            title: "Save",
            icon: "mdi-content-save",
            action: () => refs.form?.handleSubmit(),
          },
        ]}
        width={625}
      >
        <CampaignEditForm ref="form" />
      </Dialog>
    );
  },
});

export default CampaignEditDialog;
