import Dialogs from "@/constants/dialogs";
import { Namespaces, Permissions } from "@/constants/permissions";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import toggleDialog from "@/helpers/toggleDialog";
import ActionHandler from "@/models/actions/ActionHandler";
import PermissionGroupActionsParams from "@/models/actions/PermissionGroupActionsParams";

const usePermissionGroupActions = ({
  id,
  name,
}: PermissionGroupActionsParams): ActionHandler[] => [
  {
    icon: "mdi-delete",
    title: "Remove",
    action: () => toggleDialog(Dialogs.PERMISSION_GROUP_REMOVE, { id, name }),
    isAllowed: hasUserPermissions(Namespaces.ACCESS_MANAGEMENT, [
      Permissions.DELETE_ROLE,
    ]),
  },
];

export default usePermissionGroupActions;
