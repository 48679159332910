import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import Routes from "@/constants/routes";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { isRequired } from "@/helpers/validations";
import { Actions } from "@/models/store";
import Vue, { VNode } from "vue";
import { VForm, VTextField } from "vuetify/lib";
import GrammarTypeSelect from "../GrammarTypeSelect";
import LanguageSelect from "../LanguageSelect";
import TenantSelect from "../TenantSelect";

const GrammarCreateForm = Vue.extend({
  data: () => ({
    fields: {
      name: "",
      language: "cs",
      idTenant: null,
      type: 1,
    },
    isFormValid: true,
  }),

  methods: {
    async handleSubmit() {
      const { isFormSubmited, data } = await submitForm({
        ref: this.$refs.form,
        url: ApiEndpoints.GRAMMAR_CREATE,
        values: this.fields,
        actionId: Actions.GRAMMAR_CREATE,
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.GRAMMAR_CREATE, {});

        this.$router.push({
          name: Routes.GRAMMARS_DETAIL_BASIC,
          params: { idGrammar: data.id },
        });

        pushNotify(Notify.SUCCESS, "Grammar created successfully");
      }
    },
  },

  render(): VNode {
    return (
      <VForm vModel={this.isFormValid} ref="form">
        <VTextField
          vModel={this.fields.name}
          rules={[isRequired]}
          label="Name"
        />
        <GrammarTypeSelect vModel={this.fields.type} />
        <LanguageSelect vModel={this.fields.language} />
        <TenantSelect vModel={this.fields.idTenant} />
      </VForm>
    );
  },
});

export default GrammarCreateForm;
