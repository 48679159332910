import Vue, { PropType, VNode } from "vue";
import { VIcon } from "vuetify/lib";
import DoughnutChart from "../DoughnutChart";

const StatisticsCallDirectionChart = Vue.extend({
  props: {
    data: Array as PropType<any>,
    highlightIndex: Number,
  },

  render(): VNode {
    return (
      <div>
        <div class="text-h6 font-weight-bold mb-3 d-flex align-center justify-center">
          <VIcon
            color={this.highlightIndex === 0 ? "success" : "warning"}
            class="mr-2"
          >
            {this.highlightIndex === 0
              ? "mdi-phone-incoming"
              : "mdi-phone-outgoing"}
          </VIcon>{" "}
          <span>{this.highlightIndex === 0 ? "Inbound" : "Outbound"}</span>
        </div>
        <DoughnutChart
          centerValue={(this.data[this.highlightIndex] ?? 0).toLocaleString(
            "cs"
          )}
          centerValuePerex="(min)"
          chartData={{
            labels: ["Inbound", "Outbound"],
            datasets: [
              {
                backgroundColor:
                  this.highlightIndex === 0
                    ? ["#17c57c", "#F0F0F0"]
                    : ["#F0F0F0", "#17c57c"],
                data: this.data,
              },
            ],
          }}
        />
      </div>
    );
  },
});

export default StatisticsCallDirectionChart;
