import VueJsonEditor from "vue-json-editor";
import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { isRequired } from "@/helpers/validations";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VBtn, VForm, VTextField } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";

const Fields = {
  name: "name",
  // note: "note",
  configuration: "configuration",
};

const BotDeployConfigEditForm = Vue.extend({
  data: () => ({
    fields: {},
    isFormValid: true,
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_BOTDEPLOY_CONFIG]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.BOTDEPLOY_CONFIG_OVERVIEW_FETCH]),

    isEditable(name: string): boolean {
      return this.INSPECTED_BOTDEPLOY_CONFIG.basic.editableFields.includes(
        name
      );
    },

    async handleSubmit(): Promise<void> {
      const refs: any = this.$refs;

      const { isFormSubmited } = await submitForm({
        actionId: Actions.BOTDEPLOY_CONFIG_OVERVIEW_EDIT,
        ref: refs.form,
        values: {
          ...this.fields,
          configuration: JSON.stringify(this.fields[Fields.configuration]),
        },
        url: ApiEndpoints.BOTDEPLOY_CONFIG_BASIC,
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.BOTDEPLOY_CONFIG_EDIT_BASIC, {});
        pushNotify(Notify.SUCCESS, "Configuration edited successfully");

        this.BOTDEPLOY_CONFIG_OVERVIEW_FETCH({
          name: this.$route.params.name,
        });
      }
    },
  },

  created() {
    this.fields = { ...this.INSPECTED_BOTDEPLOY_CONFIG.basic.content };
  },

  render(): VNode {
    return (
      <VForm
        vModel={this.isFormValid}
        ref="form"
        onSubmit={(e: Event) => {
          e.preventDefault();
          this.handleSubmit();
        }}
      >
        <VTextField
          vModel={this.fields[Fields.name]}
          rules={[isRequired]}
          label="Name"
          disabled
        />
        {/* <VTextField
          vModel={this.fields[Fields.note]}
          label="Note"
          disabled={!this.isEditable(Fields.note)}
        /> */}

        <VueJsonEditor vModel={this.fields[Fields.configuration]} mode="code" />
        <VBtn type="submit" class="d-none"></VBtn>
      </VForm>
    );
  },
});

export default BotDeployConfigEditForm;
