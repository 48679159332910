import Vue, { VNode } from "vue";
import { VListItem, VSpacer, VTooltip } from "vuetify/lib";

const CampaignOverviewListItem = Vue.extend({
  props: {
    title: String,
    valueTooltip: String,
  },

  render(): VNode {
    const valueComponent = (
      <div class="primary--text font-weight-bold">{this.$slots.default}</div>
    );

    return (
      <VListItem class="px-2">
        <div>{this.title}:</div>
        <VSpacer />
        {this.valueTooltip ? (
          <VTooltip
            top
            scopedSlots={{
              activator: ({ on, attrs }: any) => (
                <div {...{ on, attrs }}>{valueComponent}</div>
              ),
            }}
          >
            {this.valueTooltip}
          </VTooltip>
        ) : (
          valueComponent
        )}
      </VListItem>
    );
  },
});

export default CampaignOverviewListItem;
