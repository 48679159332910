import Vue, { VNode } from "vue";
import { VAppBar, VImg } from "vuetify/lib";
import Routes from "@/constants/routes";
import imgUrl from "@/assets/img/vocalls_logo.svg";
import MainMenu from "../MainMenu";
import UserProfileMenu from "../UserProfileMenu";

const Header = Vue.extend({
  props: {},
  data: () => ({}),
  methods: {},

  render(): VNode {
    return (
      <VAppBar app color="primary">
        <router-link to={{ name: Routes.FLOW_OVERVIEW }}>
          <VImg src={imgUrl} width="125" height="28" />
        </router-link>
        <MainMenu />
        <UserProfileMenu />
      </VAppBar>
    );
  },
});

export default Header;
