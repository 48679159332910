import ApiEndpoints from "@/constants/apiEndpoints";
import Dropdowns from "@/constants/dropdowns";
import Vue, { VNode } from "vue";
import DynamicSelect from "../DynamicSelect";

const LanguageSelect = Vue.extend({
  props: {
    value: [String, Array],
    options: Object,
    canSelectAll: {
      type: Boolean,
      default: false,
    },
    differentLanguageOptions: {
      type: Boolean,
      default: false,
    },
  },

  render(): VNode {
    return (
      <DynamicSelect
        name={Dropdowns.LANGUAGES}
        label="Language"
        fetchUrl={ApiEndpoints.LANGUAGES_LIST}
        onInput={(val: string) => this.$emit("input", val)}
        value={this.value}
        options={this.options}
        canSelectAll={this.canSelectAll}
        differentLanguageOptions={this.differentLanguageOptions}
      />
    );
  },
});

export default LanguageSelect;
