const lineIdentificatorTypes = [
  {
    text: "Request-Line-URI",
    value: 0,
  },
  {
    text: "Custom Header",
    value: 1,
  },
  {
    text: "To",
    value: 2,
  },
];

export default lineIdentificatorTypes;
