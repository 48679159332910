import Dialogs from "@/constants/dialogs";
import getCountryCode from "@/helpers/getCountryCode";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapGetters } from "vuex";
import EditableOverviewSection from "../EditableOverviewSection";
import LanguageVoiceGroupsForm from "../LanguageVoiceGroupsForm";
import CountryFlag from "vue-country-flag";

const LanguageOverview = Vue.extend({
  data: () => ({
    basic: {},
    configuration: {
      title: "Content",
    },
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_LANGUAGE]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  created() {
    this.basic = {
      title: "Overview",
      modificators: {
        name: ({ language, name }: any) => (
          <div class="d-flex align-center">
            <CountryFlag size="small" country={getCountryCode(language)} />
            <span class="pl-1">{name}</span>
          </div>
        ),
        voices: ({ settings }: any) =>
          settings.map((voiceGroup: any) => (
            <div class="mb-3">
              {settings.length > 1 && (
                <div class="font-italic font-weight-bold mb-1">
                  {voiceGroup.engine}
                </div>
              )}
              {voiceGroup.voices?.map((voice: any) => (
                <div>{voice?.tts}</div>
              ))}
            </div>
          )),
        engines: ({ settings }: any) => (
          <span>
            {settings.map((voiceGroup: any) => voiceGroup.engine).join(", ")}
          </span>
        ),
      },
      columns: [
        { name: "name", title: "Name" },
        { name: "language", title: "Identificator" },
        { name: "voices", title: "Voices" },
        { name: "engines", title: "Engines" },
      ],
    };
  },

  render(): VNode {
    return (
      <div>
        <EditableOverviewSection
          configuration={this.basic}
          data={this.INSPECTED_LANGUAGE?.basic}
          editDialog={Dialogs.LANGUAGE_EDIT_BASIC}
          editDialogWidth={600}
          action={Actions.LANGUAGE_OVERVIEW_EDIT}
          scopedSlots={{
            form: () => (
              <LanguageVoiceGroupsForm
                ref="form"
                data={this.INSPECTED_LANGUAGE?.basic?.content?.settings}
              />
            ),
          }}
          isDataFetching={this.IS_ACTION_FETCHING(
            Actions.LANGUAGE_OVERVIEW_FETCH
          )}
        />
      </div>
    );
  },
});

export default LanguageOverview;
