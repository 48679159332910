import Vue, { VNode } from "vue";
import { VCard, VCardText } from "vuetify/lib";
import TenantStatistics from "../TenantStatistics/TenantStatistics";

const TenantStatisticsAll = Vue.extend({
  render(): VNode {
    return (
      <div>
        <h2 class="text-h4 mb-5 font-weight-bold primary--text">
          Tenant statistics
        </h2>
        <VCard>
          <VCardText>
            <TenantStatistics withTenantSelection />
          </VCardText>
        </VCard>
      </div>
    );
  },
});

export default TenantStatisticsAll;
