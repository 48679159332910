import Vue, { VNode } from "vue";
import DetailWithTabs from "@/components/DetailWithTabs";
import Routes from "@/constants/routes";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import useBotDeployConfigActions from "@/hooks/useBotDeployConfigActions";
import BotDeployConfigRemoveDialog from "../BotDeployConfigRemoveDialog";

const BotDeployConfigDetail = Vue.extend({
  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_BOTDEPLOY_CONFIG]),

    instanceData(): any {
      const content = this.INSPECTED_BOTDEPLOY_CONFIG?.basic;
      const name = this.$route.params.name;
      return { name, ...content };
    },
  },
  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.BOTDEPLOY_CONFIG_OVERVIEW_FETCH]),
  },

  render(): VNode {
    return (
      <div>
        <DetailWithTabs
          title={`Configuration - ${this.instanceData.name || ""}`}
          defaultReturnRoute={Routes.BOTDEPLOY_CONFIGS}
          initDataFetch={() =>
            this.BOTDEPLOY_CONFIG_OVERVIEW_FETCH({
              name: this.$route.params.name,
            })
          }
          actions={useBotDeployConfigActions(this.instanceData)}
          tabs={[
            {
              title: "General",
              path: Routes.BOTDEPLOY_CONFIG_DETAIL_BASIC,
            },
          ]}
        />

        <BotDeployConfigRemoveDialog />
      </div>
    );
  },
});

export default BotDeployConfigDetail;
