import Routes from "@/constants/routes";
import Vue, { VNode } from "vue";
import DetailWithTabs from "../DetailWithTabs";

const PackageManager = Vue.extend({
  render(): VNode {
    return (
      <DetailWithTabs
        title="Package manager"
        defaultReturnRoute={Routes.ADMINISTRATION}
        tabs={[
          {
            title: "Install",
            path: Routes.PACKAGE_MANAGER_INSTALL,
          },
          {
            title: "Create",
            path: Routes.PACKAGE_MANAGER_CREATE,
          },
        ]}
      />
    );
  },
});

export default PackageManager;
