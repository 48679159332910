import Vue, { VNode } from "vue";
import DetailWithTabs from "@/components/DetailWithTabs";
import Routes from "@/constants/routes";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import UserRemoveDialog from "../UserRemoveDialog";
import useUserActions from "@/hooks/useUserActions";

const UserDetail = Vue.extend({
  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_USER]),

    instanceData(): any {
      const username = this.INSPECTED_USER?.basic?.content?.username;
      const id = this.$route.params.idUser;

      return { username, id };
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.USER_OVERVIEW_FETCH]),
  },

  render(): VNode {
    return (
      <div>
        <DetailWithTabs
          title={`User - ${this.instanceData.username || ""}`}
          defaultReturnRoute={Routes.USERS_OVERVIEW}
          initDataFetch={() =>
            this.USER_OVERVIEW_FETCH({
              idUser: this.instanceData.id,
            })
          }
          actions={useUserActions(this.instanceData)}
          tabs={[
            {
              title: "General",
              path: Routes.USER_DETAIL_BASIC,
            },
            {
              title: "Security",
              path: Routes.USER_DETAIL_SECURITY,
            },
          ]}
        />
        <UserRemoveDialog />
      </div>
    );
  },
});

export default UserDetail;
