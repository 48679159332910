const Dialogs = {
  // Flow
  FLOW_SWITCH_VERSION: "FLOW_SWITCH_VERSION",
  FLOW_CREATE: "FLOW_CREATE",
  FLOW_VERSION_DIFF: "FLOW_VERSION_DIFF",
  FLOW_DUPLICATE: "FLOW_DUPLICATE",
  FLOW_REMOVE: "FLOW_REMOVE",
  FLOW_EDIT_BASIC: "FLOW_EDIT_BASIC",
  FLOW_CSV_EXPORT: "FLOW_CSV_EXPORT",

  // Line
  LINE_EDIT_BASIC: "LINE_EDIT_BASIC",
  LINE_EDIT_CALLBOT_CONFIG: "LINE_EDIT_CALLBOT_CONFIG",
  LINE_ENABLE: "LINE_ENABLE",
  LINE_REMOVE: "LINE_REMOVE",
  LINE_DELETE_CALL: "LINE_DELETE_CALL",
  LINE_MONITORING: "LINE_MONITORING",
  LINE_CSV_EXPORT: "LINE_CSV_EXPORT",
  LINE_CREATE: "LINE_CREATE",
  LINE_CHANGE_MODULE: "LINE_CHANGE_MODULE",
  LINE_TARIFF_EDIT_DIALOG: "LINE_TARIFF_EDIT_DIALOG",
  LINE_CLEANING: "LINE_CLEANING",

  // Module
  MODULE_EDIT_BASIC: "MODULE_EDIT_BASIC",
  MODULE_CALLBOT_CONFIGURATION: "MODULE_CALLBOT_CONFIGURATION",
  MODULE_CSV_EXPORT: "MODULE_CSV_EXPORT",
  MODULE_REMOVE: "MODULE_REMOVE",
  MODULE_SET_DEFAULT_LINE: "MODULE_SET_DEFAULT_LINE",

  // Grammar
  GRAMMAR_CREATE: "GRAMMAR_CREATE",
  GRAMMAR_DUPLICATE: "GRAMMAR_DUPLICATE",
  GRAMMAR_REMOVE: "GRAMMAR_REMOVE",
  GRAMMAR_EDIT_BASIC: "GRAMMAR_EDIT_BASIC",
  GRAMMAR_EDIT_CONTENT: "GRAMMAR_EDIT_CONTENT",

  // User
  USER_CREATE: "USER_CREATE",
  USER_ROLES: "USER_ROLES",
  USER_EDIT_BASIC: "USER_EDIT_BASIC",
  USER_REMOVE: "USER_REMOVE",
  USER_API_SECRET_REMOVE: "USER_API_SECRET_REMOVE",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",

  // Tenant
  TENANT_EDIT_BASIC: "TENANT_EDIT_BASIC",
  TENANT_EDIT_PARAMS: "TENANT_EDIT_PARAMS",
  TENANT_CREATE: "TENANT_CREATE",
  TENANT_REMOVE: "TENANT_REMOVE",
  TENANT_NOTIFICATION_GROUP: "TENANT_NOTIFICATION_GROUP",
  TENANT_NOTIFICATION_GROUP_REMOVE: "TENANT_NOTIFICATION_GROUP_REMOVE",
  TENANT_FORCE_TWO_FACTOR: "TENANT_FORCE_TWO_FACTOR",
  TENANT_STATISTICS_REPORT_EXPORT: "TENANT_STATISTICS_REPORT_EXPORT",
  TENANT_STATISTICS_OVERVIEW_EXPORT: "TENANT_STATISTICS_OVERVIEW_EXPORT",

  // Permission groups
  PERMISSION_GROUP_CREATE: "PERMISSION_GROUP_CREATE",
  PERMISSION_GROUP_REMOVE: "PERMISSION_GROUP_REMOVE",
  PERMISSION_GROUP_EDIT_BASIC: "PERMISSION_GROUP_EDIT_BASIC",
  PERMISSION_GROUP_PERMISSIONS_EDIT: "PERMISSION_GROUP_PERMISSIONS_EDIT",

  // User profile
  USER_CONNECTED_ACCOUNTS: "USER_CONNECTED_ACCOUNTS",

  // Campaign
  CAMPAIGN_CREATE: "CAMPAIGN_CREATE",
  CAMPAIGN_EDIT: "CAMPAIGN_EDIT",
  CAMPAIGN_REMOVE: "CAMPAIGN_REMOVE",
  CAMPAIGN_STATE_CHANGE: "CAMPAIGN_STATE_CHANGE",
  CAMPAIGN_MANAGE_CONTACTS: "CAMPAIGN_MANAGE_CONTACTS",
  CAMPAIGN_VALIDATION_DIALOG: "CAMPAIGN_VALIDATION_DIALOG",
  CAMPAIGN_ERROR_CONTACTS: "CAMPAIGN_ERROR_CONTACTS",
  CAMPAIGN_CSV_EXPORT: "CAMPAIGN_CSV_EXPORT",

  // Bot Deploy
  BOTDEPLOY_MODULE_CREATE: "BOTDEPLOY_MODULE_CREATE",
  BOTDEPLOY_MODULE_REMOVE: "BOTDEPLOY_MODULE_REMOVE",
  BOTDEPLOY_MODULE_EDIT_BASIC: "BOTDEPLOY_MODULE_EDIT_BASIC",
  BOTDEPLOY_MODULE_TOGGLE: "BOTDEPLOY_MODULE_TOGGLE",
  BOTDEPLOY_MODULE_RESTART: "BOTDEPLOY_MODULE_RESTART",

  BOTDEPLOY_SERVER_CREATE: "BOTDEPLOY_SERVER_CREATE",
  BOTDEPLOY_SERVER_REMOVE: "BOTDEPLOY_SERVER_REMOVE",
  BOTDEPLOY_SERVER_EDIT_BASIC: "BOTDEPLOY_SERVER_EDIT_BASIC",

  BOTDEPLOY_CONFIG_CREATE: "BOTDEPLOY_CONFIG_CREATE",
  BOTDEPLOY_CONFIG_REMOVE: "BOTDEPLOY_CONFIG_REMOVE",
  BOTDEPLOY_CONFIG_EDIT_BASIC: "BOTDEPLOY_CONFIG_EDIT_BASIC",

  // Language
  LANGUAGE_CREATE: "LANGUAGE_CREATE",
  LANGUAGE_EDIT_BASIC: "LANGUAGE_EDIT_BASIC",
  LANGUAGE_REMOVE: "LANGUAGE_REMOVE",

  // Global
  DIAL_LOG_DETAIL: "DIAL_LOG_DETAIL",
  PACKAGE_INSTALL: "PACKAGE_INSTALL",
  DIAL_LOG_AUDIO: "DIAL_LOG_AUDIO",
};

export default Dialogs;
