import Dialogs from "@/constants/dialogs";
import toggleDialog from "@/helpers/toggleDialog";
import ActionHandler from "@/models/actions/ActionHandler";
import BotDeployModuleActionsParams from "@/models/actions/BotDeployModuleActionsParams";

const useBotDeployModuleActions = ({
  name,
  server,
  count,
}: BotDeployModuleActionsParams): ActionHandler[] => [
  {
    title: count === 0 ? "Enable" : "Disable",
    icon: count === 0 ? "mdi-toggle-switch" : "mdi-toggle-switch-off-outline",
    action: () =>
      toggleDialog(Dialogs.BOTDEPLOY_MODULE_TOGGLE, {
        name,
        server,
        count,
      }),
  },
  {
    icon: "mdi-restart",
    title: "Restart",
    action: () =>
      toggleDialog(Dialogs.BOTDEPLOY_MODULE_RESTART, {
        name,
        server,
      }),
  },
  {
    icon: "mdi-delete",
    title: "Remove",
    action: () =>
      toggleDialog(Dialogs.BOTDEPLOY_MODULE_REMOVE, {
        name,
        server,
      }),
    isAllowed: true,
  },
];

export default useBotDeployModuleActions;
