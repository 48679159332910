import Dialogs from "@/constants/dialogs";
import Vue, { VNode } from "vue";
import Dialog from "../Dialog";
import { mapGetters } from "vuex";
import { Getters, Modules } from "@/models/store";
import DiffCompareEditor from "../DiffCompareEditor";

const FlowVersionDiffDialog = Vue.extend({
  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    flowDiffData(): any {
      return this.GET_DIALOG_DATA(Dialogs.FLOW_VERSION_DIFF);
    },
  },

  render(): VNode {
    return (
      <Dialog
        title="Version diff"
        id={Dialogs.FLOW_VERSION_DIFF}
        width="2000"
        closeOnEsc
      >
        <DiffCompareEditor content={this.flowDiffData} />
      </Dialog>
    );
  },
});

export default FlowVersionDiffDialog;
