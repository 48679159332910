import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import {
  VCard,
  VCardText,
  VDivider,
  VSpacer,
  VToolbar,
  VToolbarTitle,
} from "vuetify/lib";
import { mapGetters } from "vuex";
import EditableOverviewSection from "../EditableOverviewSection";
import BotDeployModuleEditForm from "../BotDeployModuleEditForm";
import DataTextOverview from "../DataTextOverview";
import { toDaysHoursAndMinutes } from "@/helpers/dateAndTimeUtils";

const BotDeployModuleOverview = Vue.extend({
  data: () => ({
    basic: {
      title: "Overview",
      columns: [
        { name: "name", title: "Name" },
        { name: "server", title: "Server" },
        { name: "version", title: "Version" },
        { name: "configuration", title: "Configuration" },
        { name: "ipA", title: "IP A" },
        { name: "ipB", title: "IP B" },
      ],
    },
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_BOTDEPLOY_MODULE]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),

    status(): any {
      return this.INSPECTED_BOTDEPLOY_MODULE?.basic?.content?.status
        ? JSON.parse(this.INSPECTED_BOTDEPLOY_MODULE?.basic?.content?.status)
        : {};
    },
  },

  render(): VNode {
    return (
      <div>
        <EditableOverviewSection
          configuration={this.basic}
          data={this.INSPECTED_BOTDEPLOY_MODULE?.basic}
          editDialog={Dialogs.BOTDEPLOY_MODULE_EDIT_BASIC}
          action={Actions.BOTDEPLOY_MODULE_OVERVIEW_EDIT}
          scopedSlots={{ form: () => <BotDeployModuleEditForm ref="form" /> }}
          isDataFetching={this.IS_ACTION_FETCHING(
            Actions.BOTDEPLOY_MODULE_OVERVIEW_FETCH
          )}
        />

        {this.status.Status && (
          <div>
            <VDivider />
            <VCard flat>
              <VToolbar flat>
                <VToolbarTitle
                  class="primary--text font-weight-bold text-h5"
                  inset
                  vertical
                >
                  Healthcheck
                </VToolbarTitle>
                <VSpacer />
                Last update:{" "}
                {new Date(this.status.Timestamp).toLocaleString("cs")}
              </VToolbar>
              <VCardText class="pt-0">
                <DataTextOverview
                  items={[
                    {
                      title: "Status",
                      value: this.status.Status,
                    },
                    {
                      title: "Uptime",
                      value: toDaysHoursAndMinutes(this.status.UpTime),
                    },
                    {
                      title: "Inbound Calls",
                      value: this.status.InboundCallsCount,
                    },
                    {
                      title: "Outbound Calls",
                      value: this.status.OutboundCallsCount,
                    },
                    {
                      title: "Successful Registrations",
                      value: this.status.SuccessfullRegistrationsCurrent,
                    },
                    {
                      title: "Failed Registrations",
                      value: this.status.FailedRegistrationsCurrent,
                    },
                  ]}
                />
              </VCardText>
            </VCard>
          </div>
        )}
      </div>
    );
  },
});

export default BotDeployModuleOverview;
