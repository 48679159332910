import { Getters, Modules } from "@/models/store";
import store from "@/store";

const installReportingWidget = (containerEl: any): void => {
  const authToken = store.getters[`${Modules.USER}/${Getters.USER_TOKEN}`];
  localStorage.setItem("vc_reporting_token", authToken);

  containerEl.setAttribute("data-widget", "vocalls-reporting");

  const script = document.createElement("script");
  script.setAttribute(
    "src",
    `${process.env.VUE_APP_REPORTING_URL}/reporting.js?${Date.now()}`
  );

  document.body.appendChild(script);
};

export default installReportingWidget;
