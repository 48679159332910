import Dialogs from "@/constants/dialogs";
import getDaysOfWeekFromValues from "@/helpers/getDaysOfWeekFromValues";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VBtn, VChip, VDataTable, VIcon } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";

const LineMonitoringOverview = Vue.extend({
  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_LINE]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.LINE_MONITORING_FETCH]),
  },

  created() {
    this.LINE_MONITORING_FETCH({
      idDialModuleLine: this.$route.params.idDialModuleLine,
    });
  },

  render(): VNode {
    return (
      <VDataTable
        headers={[
          { text: "Name", value: "title" },
          { text: "Operating days", value: "dow" },
          { text: "Operating time", value: "time" },
          { text: "Interval (min)", value: "interval" },
          { text: "Notification groups", value: "notifications" },
          { text: "Status", value: "enable" },
          { text: "Actions", value: "actions" },
        ]}
        items={this.INSPECTED_LINE?.monitoring?.content}
        loading={this.IS_ACTION_FETCHING(Actions.LINE_MONITORING_FETCH)}
        hideDefaultFooter
        disableSort
        itemKey="name"
        scopedSlots={{
          "item.time": ({ item }: any) => `${item.timeStart} - ${item.timeEnd}`,
          "item.enable": ({ item }: any) => (
            <VChip color={item.enable ? "success" : "error"}>
              {item.enable ? "Enabled" : "Disabled"}
            </VChip>
          ),
          "item.dow": ({ item }: any) =>
            getDaysOfWeekFromValues(item.dow, true).join(", "),
          "item.actions": ({ index }: any) => (
            <span>
              <VBtn
                onClick={() => toggleDialog(Dialogs.LINE_MONITORING, { index })}
                fab
                xSmall
                class="tertiary white--text"
              >
                <VIcon>mdi-cog</VIcon>
              </VBtn>
            </span>
          ),
        }}
      />
    );
  },
});

export default LineMonitoringOverview;
