import { Module } from "vuex";

import InstancesState from "@/models/store/InstancesState";
import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

const instances: Module<InstancesState, []> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default instances;
