import Dropdowns from "@/constants/dropdowns";
import DropdownsState from "@/models/store/DropdownsState";

const state: DropdownsState = {
  [Dropdowns.MODULES]: [],
  [Dropdowns.FLOW]: [],
  [Dropdowns.FLOW_OVERVIEW]: [],
  [Dropdowns.TENANTS]: [],
  [Dropdowns.LANGUAGES]: [],
  [Dropdowns.NOTIFY_GROUPS]: [],
  [Dropdowns.GRAMMARS]: [],
  [Dropdowns.COMPONENTS]: [],
  [Dropdowns.LINES]: [],
  [Dropdowns.BOTDEPLOY_SERVERS]: [],
  [Dropdowns.BOTDEPLOY_CONFIGS]: [],
};

export default state;
