import Vue, { VNode } from "vue";
import DetailWithTabs from "@/components/DetailWithTabs";
import Routes from "@/constants/routes";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import useGrammarActions from "@/hooks/useGrammarActions";
import GrammarDuplicateDialog from "@/components/GrammarDuplicateDialog";
import GrammarRemoveDialog from "@/components/GrammarRemoveDialog";

const GrammarDetail = Vue.extend({
  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_GRAMMAR]),

    instanceData(): any {
      const content = this.INSPECTED_GRAMMAR?.basic?.content;
      const idGrammar = this.$route.params.idGrammar;

      return { idGrammar, ...content };
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.GRAMMAR_OVERVIEW_FETCH]),
  },

  render(): VNode {
    return (
      <div>
        <DetailWithTabs
          title={`Grammar - ${this.instanceData.name || ""}`}
          defaultReturnRoute={Routes.GRAMMARS_OVERVIEW}
          initDataFetch={() =>
            this.GRAMMAR_OVERVIEW_FETCH({
              idGrammar: this.instanceData.idGrammar,
            })
          }
          actions={useGrammarActions(this.instanceData)}
          tabs={[
            {
              title: "General",
              path: Routes.GRAMMARS_DETAIL_BASIC,
            },
          ]}
        />

        <GrammarDuplicateDialog />
        <GrammarRemoveDialog />
      </div>
    );
  },
});

export default GrammarDetail;
