import Dialogs from "@/constants/dialogs";
import { Actions } from "@/models/store";
import Vue, { VNode } from "vue";
import Dialog from "../Dialog";
import LanguageCreateForm from "../LanguageCreateForm";

const LanguageCreateDialog = Vue.extend({
  data: () => ({
    step: 1,
  }),

  computed: {
    stepConfig(): any {
      return this.step === 1
        ? {
            icon: "mdi-chevron-right",
            title: "Next",
            headingPostfix: "general",
          }
        : { icon: "mdi-plus", title: "Create", headingPostfix: "voices" };
    },
  },

  render(): VNode {
    const refs: any = this.$refs;

    return (
      <Dialog
        id={Dialogs.LANGUAGE_CREATE}
        title={`New Language - ${this.stepConfig?.headingPostfix}`}
        width={600}
        toggleHandler={() => (this.step = 1)}
        actions={[
          {
            action: () => refs?.form?.handleSubmit(),
            id: Actions.LANGUAGE_CREATE,
            ...this.stepConfig,
          },
        ]}
      >
        <LanguageCreateForm
          onStepChange={(step: number) => (this.step = step)}
          ref="form"
        />
      </Dialog>
    );
  },
});

export default LanguageCreateDialog;
