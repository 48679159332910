import ApiEndpoints from "@/constants/apiEndpoints";
import Notify from "@/constants/notifications";
import downloadFile from "@/helpers/downloadFile";
import generateErrorMsg from "@/helpers/generateErrorMsg";
import pushNotify from "@/helpers/pushNotify";
import submitForm from "@/helpers/submitForm";
import { isRequired } from "@/helpers/validations";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import {
  VBtn,
  VCard,
  VCardText,
  VCol,
  VForm,
  VIcon,
  VRow,
  VTextField,
} from "vuetify/lib";
import { mapGetters } from "vuex";
import ComponentSelect from "../ComponentSelect";
import FlowSelect from "../FlowSelect";
import GrammarSelect from "../GrammarSelect";
import LanguageSelect from "../LanguageSelect";
import SectionToolbar from "../SectionToolbar";

const selectOptions = {
  outlined: true,
  multiple: true,
  chips: true,
  deletableChips: true,
};

const PackageManagerCreate = Vue.extend({
  data: () => ({
    fields: {
      name: "",
      components: [],
      callScripts: [],
      grammars: [],
      languages: [],
    },
    isValid: true,
  }),

  computed: {
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    async handleSubmit(): Promise<void> {
      const refs: any = this.$refs;

      const { isFormSubmited, isFormValid, data } = await submitForm({
        actionId: Actions.PACKAGE_CREATE,
        ref: refs.form,
        values: this.fields,
        url: ApiEndpoints.PACKAGE_CREATE,
        responseType: "blob",
      });

      if (isFormSubmited) {
        pushNotify(Notify.SUCCESS, "Install package created successfully.");
        downloadFile(data, this.fields.name, "zip");
      }

      if (isFormValid && !isFormSubmited) {
        pushNotify(Notify.ERROR, generateErrorMsg(Actions.PACKAGE_CREATE));
      }
    },
  },

  render(): VNode {
    return (
      <div>
        <SectionToolbar title="Create package" />
        <VCard
          elevation="0"
          loading={this.IS_ACTION_FETCHING(Actions.PACKAGE_CREATE)}
        >
          <VCardText>
            <VForm ref="form" vModel={this.isValid}>
              <VRow>
                <VCol cols={12} md={6}>
                  <VTextField
                    vModel={this.fields.name}
                    label="Name"
                    rules={[isRequired]}
                    outlined
                  />
                </VCol>
              </VRow>
              <ComponentSelect
                vModel={this.fields.components}
                options={selectOptions}
                canSelectAll
              />
              <FlowSelect
                vModel={this.fields.callScripts}
                options={selectOptions}
                overview
              />
              <GrammarSelect
                vModel={this.fields.grammars}
                options={selectOptions}
              />
              <LanguageSelect
                vModel={this.fields.languages}
                options={selectOptions}
                differentLanguageOptions={true}
              />
            </VForm>
            <div class="d-flex justify-end">
              <VBtn
                onClick={() => this.handleSubmit()}
                disabled={this.IS_ACTION_FETCHING(Actions.PACKAGE_CREATE)}
                large
                rounded
                class="tertiary white--text"
              >
                <VIcon left>mdi-package-down</VIcon>Create
              </VBtn>
            </div>
          </VCardText>
        </VCard>
      </div>
    );
  },
});

export default PackageManagerCreate;
