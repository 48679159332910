import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import getSectionEditableValues from "@/helpers/getSectionEditableValues";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { isRequired } from "@/helpers/validations";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VForm, VTextField } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";

const Fields = {
  firstname: "firstname",
  lastname: "lastname",
};

const UserEditOverviewForm = Vue.extend({
  props: {
    idUser: String,
  },

  data: () => ({
    fields: {},
    isValid: true,
    isPasswordHidden: true,
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_USER]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.USER_OVERVIEW_FETCH]),

    isEditable(name: string): boolean {
      return this.INSPECTED_USER.basic.editableFields.includes(name);
    },

    async handleSubmit(): Promise<void> {
      const refs: any = this.$refs;
      const submitedFields = getSectionEditableValues(
        this.fields,
        this.INSPECTED_USER.basic
      );

      const { isFormSubmited } = await submitForm({
        actionId: Actions.USER_OVERVIEW_EDIT,
        ref: refs.form,
        values: submitedFields,
        url: ApiEndpoints.TENANT_USER_BASIC,
        params: { idUser: this.idUser || this.$route.params.idUser },
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.USER_EDIT_BASIC, {});
        pushNotify(Notify.SUCCESS, "User edited successfully");

        this.USER_OVERVIEW_FETCH({
          idUser: this.idUser || this.$route.params.idUser,
        });
      }
    },
  },

  created() {
    this.fields = { ...this.INSPECTED_USER.basic.content };
  },

  render(): VNode {
    const fields: any = this.fields;

    return (
      <VForm vModel={this.isValid} ref="form">
        <VTextField
          vModel={fields[Fields.firstname]}
          label="First name"
          disabled={!this.isEditable(Fields.firstname)}
          rules={[isRequired]}
        />
        <VTextField
          vModel={fields[Fields.lastname]}
          label="Last name"
          disabled={!this.isEditable(Fields.lastname)}
          rules={[isRequired]}
        />
      </VForm>
    );
  },
});

export default UserEditOverviewForm;
