import Vue, { VNode } from "vue";
import * as monaco from "monaco-editor";
import "./MonacoEditor.scss";

const MonacoEditor = Vue.extend({
  props: {
    value: String,
    language: String,
    height: {
      type: Number,
      default: 300,
    },
    config: Object,
  },

  data: () => ({
    editor: {} as any,
  }),

  methods: {
    initEditor(): void {
      const editorElement: HTMLElement | any = this.$refs.editor;

      if (editorElement) {
        this.editor = monaco.editor.create(editorElement, {
          value: this.value,
          language: this.language,
          lineNumbers: "off",
          roundedSelection: false,
          scrollBeyondLastLine: false,
          formatOnPaste: true,
          glyphMargin: false,
          folding: false,
          lineDecorationsWidth: 0,
          lineNumbersMinChars: 0,
          automaticLayout: true,
          tabSize: 2,
          minimap: {
            enabled: false,
          },
          ...this.config,
        });
      }

      this.editor.onDidChangeModelContent((e: Event) => {
        this.$emit("change", this.editor.getValue(), e);
      });
    },
  },

  mounted() {
    setTimeout(() => this.initEditor(), 200);
  },

  render(): VNode {
    return (
      <div ref="editor" class="editor" style={`height: ${this.height}px`} />
    );
  },
});

export default MonacoEditor;
