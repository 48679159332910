import Vue, { VNode } from "vue";
import { VTab, VTabs } from "vuetify/lib";
import Routes from "@/constants/routes";

const MainMenu = Vue.extend({
  data: () => ({
    items: [
      {
        route: Routes.FLOW_OVERVIEW,
        label: "Flow",
      },
      {
        route: Routes.CAMPAIGN,
        label: "Campaigns",
      },
      {
        route: Routes.ADMINISTRATION,
        label: "Administration",
      },
    ],
  }),

  render(): VNode {
    return (
      <VTabs dark centered>
        {this.items.map(({ route, label }: any) => (
          <VTab class="px-10" to={{ name: route }}>
            {label}
          </VTab>
        ))}
      </VTabs>
    );
  },
});

export default MainMenu;
