import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";

const GrammarDuplicateDialog = Vue.extend({
  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    instanceData(): any {
      return this.GET_DIALOG_DATA(Dialogs.GRAMMAR_DUPLICATE);
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.GRAMMAR_DUPLICATE]),
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.GRAMMAR_DUPLICATE}
        title="Confirmation"
        closeOnEsc
        actions={[
          {
            title: "Apply",
            icon: "mdi-check",
            action: () => this.GRAMMAR_DUPLICATE(this.instanceData.id),
            id: Actions.GRAMMAR_DUPLICATE,
          },
        ]}
      >
        Do you really want to duplicate "
        <strong class="primary--text">{this.instanceData.name}</strong>"
        grammar?
      </Dialog>
    );
  },
});

export default GrammarDuplicateDialog;
