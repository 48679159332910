import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";

const CampaignRemoveDialog = Vue.extend({
  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    instanceData(): any {
      return this.GET_DIALOG_DATA(Dialogs.CAMPAIGN_REMOVE);
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.CAMPAIGN_REMOVE]),
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.CAMPAIGN_REMOVE}
        title="Confirmation"
        closeOnEsc
        actions={[
          {
            title: "Remove",
            icon: "mdi-delete",
            id: Actions.CAMPAIGN_REMOVE,
            action: () =>
              this.CAMPAIGN_REMOVE({
                idCampaign: this.instanceData.idCampaign,
                isDetailActive: this.instanceData.isDetailActive,
              }),
          },
        ]}
      >
        Do you really want to remove this campaign? Data will be lost.
      </Dialog>
    );
  },
});

export default CampaignRemoveDialog;
