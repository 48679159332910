import { ActionTree } from "vuex";
import { Actions, Modules, Mutations } from "@/models/store";
import InstancesState from "@/models/store/InstancesState";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Routes from "@/constants/routes";
import DataGrids from "@/constants/dataGrids";
import parseResponse from "@/helpers/parseResponse";
import toggleDialog from "@/helpers/toggleDialog";
import pushNotify from "@/helpers/pushNotify";
import Notify from "@/constants/notifications";
import createRequest from "@/helpers/createRequest";

const botDeployActions: ActionTree<InstancesState, []> = {
  [Actions.BOTDEPLOY_MODULE_OVERVIEW_FETCH]: (
    { commit, dispatch },
    { name, server }
  ) => {
    dispatch(Actions.SECTION_DETAIL_FETCH, {
      url: ApiEndpoints.BOTDEPLOY_MODULE_BASIC,
      action: Actions.BOTDEPLOY_MODULE_OVERVIEW_FETCH,
      params: { name, server },
      withTitleChange: true,
      modify: (data: any) =>
        commit(Mutations.SET_INSPECTED_BOTDEPLOY_MODULE, {
          basic: data,
        }),
      error: () => commit(Mutations.SET_INSPECTED_BOTDEPLOY_MODULE, {}),
    });
  },

  [Actions.BOTDEPLOY_MODULE_REMOVE]: (
    { dispatch },
    { name, server, isDetailActive }
  ) =>
    dispatch(Actions.INSTANCE_REMOVE, {
      params: { name, server },
      isDetailActive,
      endpoint: ApiEndpoints.BOTDEPLOY_MODULE_BASIC,
      action: Actions.BOTDEPLOY_MODULE_REMOVE,
      redirectRoute: Routes.BOTDEPLOY_MODULES,
      dataGrid: DataGrids.BOTDEPLOY_MODULES,
      dataGridFetch: Actions.BOTDEPLOY_MODULES_DATAGRID_FETCH,
      dialog: Dialogs.BOTDEPLOY_MODULE_REMOVE,
      prefix: `Module ${name}`,
    }),

  [Actions.BOTDEPLOY_MODULE_TOGGLE]: async (
    { dispatch },
    { name, server, isEnabled, isDetailActive }
  ) => {
    const request = await createRequest({
      action: Actions.BOTDEPLOY_MODULE_TOGGLE,
      method: "POST",
      url: ApiEndpoints.BOTDEPLOY_MODULE_BASIC,
      params: { name, server },
      data: {
        name: name,
        server: server,
        count: isEnabled ? 0 : 1,
      },
    });

    const { errors } = parseResponse(request);
    if (errors.length > 0) {
      // return;
      pushNotify(
        Notify.ERROR,
        `Module failed to be ${isEnabled ? "disabled" : "enabled"}`
      );
      return;
    }

    toggleDialog(Dialogs.BOTDEPLOY_MODULE_TOGGLE, {});
    pushNotify(
      Notify.SUCCESS,
      `Module ${isEnabled ? "disabled" : "enabled"} successfully`
    );

    if (isDetailActive) {
      dispatch(Actions.BOTDEPLOY_MODULE_OVERVIEW_FETCH, { name, server });
    } else {
      dispatch(
        `${Modules.DATA_GRIDS}/${Actions.DATAGRID_FETCH_WITH_CURRENT_PARAMS}`,
        {
          dataGrid: DataGrids.BOTDEPLOY_MODULES,
          fetchActionId: Actions.BOTDEPLOY_MODULES_DATAGRID_FETCH,
        },
        { root: true }
      );
    }
  },

  [Actions.BOTDEPLOY_MODULE_RESTART]: async (
    { dispatch },
    { name, server, isDetailActive }
  ) => {
    const request = await createRequest({
      action: Actions.BOTDEPLOY_MODULE_RESTART,
      method: "POST",
      url: ApiEndpoints.BOTDEPLOY_MODULE_BASIC,
      params: { name, server },
      data: {
        name: name,
        server: server,
        restart: 1,
      },
    });

    const { errors } = parseResponse(request);
    if (errors.length > 0) {
      // return;
      pushNotify(Notify.ERROR, `Module ${name} failed to be restarted`);
      return;
    }

    toggleDialog(Dialogs.BOTDEPLOY_MODULE_RESTART, {});
    pushNotify(Notify.SUCCESS, `Module ${name} restarted successfully`);

    if (isDetailActive) {
      dispatch(Actions.BOTDEPLOY_MODULE_OVERVIEW_FETCH, { name, server });
    } else {
      dispatch(
        `${Modules.DATA_GRIDS}/${Actions.DATAGRID_FETCH_WITH_CURRENT_PARAMS}`,
        {
          dataGrid: DataGrids.BOTDEPLOY_MODULES,
          fetchActionId: Actions.BOTDEPLOY_MODULES_DATAGRID_FETCH,
        },
        { root: true }
      );
    }
  },

  // SERVERS
  [Actions.BOTDEPLOY_SERVER_OVERVIEW_FETCH]: (
    { commit, dispatch },
    { name }
  ) => {
    dispatch(Actions.SECTION_DETAIL_FETCH, {
      url: ApiEndpoints.BOTDEPLOY_SERVER_BASIC,
      action: Actions.BOTDEPLOY_SERVER_OVERVIEW_FETCH,
      params: { name },
      withTitleChange: true,
      modify: (data: any) =>
        commit(Mutations.SET_INSPECTED_BOTDEPLOY_SERVER, {
          basic: data,
        }),
      error: () => commit(Mutations.SET_INSPECTED_BOTDEPLOY_SERVER, {}),
    });
  },

  [Actions.BOTDEPLOY_SERVER_REMOVE]: ({ dispatch }, { name, isDetailActive }) =>
    dispatch(Actions.INSTANCE_REMOVE, {
      params: { name },
      isDetailActive,
      endpoint: ApiEndpoints.BOTDEPLOY_SERVER_BASIC,
      action: Actions.BOTDEPLOY_SERVER_REMOVE,
      redirectRoute: Routes.BOTDEPLOY_SERVERS,
      dataGrid: DataGrids.BOTDEPLOY_SERVERS,
      dataGridFetch: Actions.BOTDEPLOY_SERVERS_DATAGRID_FETCH,
      dialog: Dialogs.BOTDEPLOY_SERVER_REMOVE,
      prefix: `Server ${name}`,
    }),

  // CONFIGS
  [Actions.BOTDEPLOY_CONFIG_OVERVIEW_FETCH]: (
    { commit, dispatch },
    { name }
  ) => {
    dispatch(Actions.SECTION_DETAIL_FETCH, {
      url: ApiEndpoints.BOTDEPLOY_CONFIG_BASIC,
      action: Actions.BOTDEPLOY_CONFIG_OVERVIEW_FETCH,
      params: { name },
      withTitleChange: true,
      modify: (data: any) => {
        data.content.configuration = JSON.parse(data?.content?.configuration);
        commit(Mutations.SET_INSPECTED_BOTDEPLOY_CONFIG, {
          basic: data,
        });
      },

      error: () => commit(Mutations.SET_INSPECTED_BOTDEPLOY_CONFIG, {}),
    });
  },

  [Actions.BOTDEPLOY_CONFIG_REMOVE]: ({ dispatch }, { name, isDetailActive }) =>
    dispatch(Actions.INSTANCE_REMOVE, {
      params: { name },
      isDetailActive,
      endpoint: ApiEndpoints.BOTDEPLOY_CONFIG_BASIC,
      action: Actions.BOTDEPLOY_CONFIG_REMOVE,
      redirectRoute: Routes.BOTDEPLOY_CONFIGS,
      dataGrid: DataGrids.BOTDEPLOY_CONFIGS,
      dataGridFetch: Actions.BOTDEPLOY_CONFIGS_DATAGRID_FETCH,
      dialog: Dialogs.BOTDEPLOY_CONFIG_REMOVE,
      prefix: `Config ${name}`,
    }),
};

export default botDeployActions;
