import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";

const SetDefaultLineDialog = Vue.extend({
  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    instanceData(): any {
      return this.GET_DIALOG_DATA(Dialogs.MODULE_SET_DEFAULT_LINE);
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.MODULE_SET_DEFAULT_LINE]),
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.MODULE_SET_DEFAULT_LINE}
        title="Confirmation"
        actions={[
          {
            action: () =>
              this.MODULE_SET_DEFAULT_LINE({
                idDialModule: this.instanceData.idDialModule,
                idDialModuleLine: this.instanceData.id,
              }),
            title: "Confirm",
            icon: "mdi-check",
            id: Actions.MODULE_SET_DEFAULT_LINE,
          },
        ]}
      >
        Are you sure to set line "
        <strong class="text--primary">{this.instanceData.name}</strong>" as
        default of module "
        <strong class="text--primary">{this.instanceData.dialModule}</strong>"?
      </Dialog>
    );
  },
});

export default SetDefaultLineDialog;
