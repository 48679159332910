import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { isRequired } from "@/helpers/validations";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VForm, VTextField } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";

const Fields = {
  name: "name",
};

const PermissionGroupOverviewEditForm = Vue.extend({
  data: () => ({
    fields: {},
    isValid: true,
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_PERMISSION_GROUP]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.PERMISSION_GROUP_OVERVIEW_FETCH]),

    async handleSubmit(): Promise<void> {
      const refs: any = this.$refs;
      const fields: any = this.fields;

      const { isFormSubmited } = await submitForm({
        actionId: Actions.PERMISSION_GROUP_OVERVIEW_EDIT,
        ref: refs.form,
        values: { name: fields.name },
        url: ApiEndpoints.PERMISSION_GROUP_BASIC,
        params: { idRole: this.$route.params.idRole },
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.PERMISSION_GROUP_EDIT_BASIC, {});
        pushNotify(Notify.SUCCESS, "Group edited successfully");

        this.PERMISSION_GROUP_OVERVIEW_FETCH({
          idRole: this.$route.params.idRole,
        });
      }
    },
  },

  created() {
    this.fields = { ...this.INSPECTED_PERMISSION_GROUP.basic.content };
  },

  render(): VNode {
    const fields: any = this.fields;

    return (
      <VForm vModel={this.isValid} ref="form">
        <VTextField
          vModel={fields[Fields.name]}
          label="Name"
          rules={[isRequired]}
        />
      </VForm>
    );
  },
});

export default PermissionGroupOverviewEditForm;
