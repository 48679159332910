import createRequest from "@/helpers/createRequest";
import parseResponse from "@/helpers/parseResponse";

const submitForm = async ({
  ref,
  values,
  url,
  actionId,
  params,
  responseType,
  headers,
}: any): Promise<any> => {
  if (ref) {
    if (!ref.validate()) {
      return { isFormValid: false, isFormSubmited: false, data: {} };
    }
  }

  const apiRequest = await createRequest({
    method: "POST",
    url,
    action: actionId,
    data: values,
    ...(responseType && { responseType }),
    ...(params && { params }),
    ...(headers && { headers }),
  });

  const { errors, data } = parseResponse(apiRequest);

  if (errors.length > 0) {
    return { isFormValid: true, isFormSubmited: false, data: {} };
  }

  return { isFormValid: true, isFormSubmited: true, data };
};

export default submitForm;
