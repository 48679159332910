import Vue, { VNode } from "vue";
import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import Routes from "@/constants/routes";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { isRequired } from "@/helpers/validations";
import { Actions } from "@/models/store";
import { VForm, VTextField } from "vuetify/lib";

const PermissionGroupCreateForm = Vue.extend({
  data: () => ({
    fields: {
      name: "",
    },
    isFormValid: true,
  }),

  methods: {
    async handleSubmit(): Promise<void> {
      const refs: any = this.$refs;

      const { isFormSubmited, data } = await submitForm({
        actionId: Actions.PERMISSION_GROUP_CREATE,
        ref: refs.form,
        values: this.fields,
        url: ApiEndpoints.PERMISSION_GROUP_CREATE,
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.PERMISSION_GROUP_CREATE, {});

        this.$router.push({
          name: Routes.PERMISSION_GROUP_DETAIL_BASIC,
          params: { idRole: data.id },
        });

        pushNotify(Notify.SUCCESS, "Group created successfully");
      }
    },
  },

  render(): VNode {
    return (
      <VForm ref="form" vModel={this.isFormValid}>
        <VTextField
          vModel={this.fields.name}
          label="Name"
          rules={[isRequired]}
        />
      </VForm>
    );
  },
});

export default PermissionGroupCreateForm;
