import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";

import apiCalls from "./apiCalls";
import dataGrids from "./dataGrids";
import user from "./user";
import instances from "./instances";
import dialogs from "./dialogs";
import notification from "./notification";
import dropdowns from "./dropdowns";
import resources from "./resources";

Vue.use(Vuex);

const store: StoreOptions<[]> = {
  modules: {
    apiCalls,
    dataGrids,
    user,
    dialogs,
    instances,
    notification,
    dropdowns,
    resources,
  },
};

export default new Vuex.Store(store);
